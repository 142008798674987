import { createSlice } from '@reduxjs/toolkit'
import { listTheme,updateTheme,createTheme,delTheme } from '../actions/themeActions'

const initialState = {
  loading: false,
  theme : [],
  error: null,
  success: false,
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listTheme.pending, (state) => {
        state.loading = true
        state.error = null
        state.theme = []
      })
      .addCase(listTheme.fulfilled, (state, { payload }) => {
        state.loading = false
        state.theme = payload.data
      })
      .addCase(listTheme.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.theme = []
      })
      .addCase(updateTheme.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(updateTheme.fulfilled, (state, { payload }) => {
        state.loading = false
        state.theme = payload.data
      })
      .addCase(updateTheme.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(createTheme.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(createTheme.fulfilled, (state, { payload }) => {
        state.loading = false
        state.theme = payload.data
      })
      .addCase(createTheme.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(delTheme.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(delTheme.fulfilled, (state, { payload }) => {
        state.loading = false
        state.theme = payload.data
      })
      .addCase(delTheme.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  },
})

export default themeSlice.reducer