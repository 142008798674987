import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Box,
  Grid,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  [theme.breakpoints.down('sm')]: {
    height: 160,
  },
}));
const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListFilterTool.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDelete: PropTypes.func,
  onDateFromChange: PropTypes.func,
  onDateToChange: PropTypes.func,
  // onSubmitDate: PropTypes.func,
  displayType: PropTypes.string,
  filterType: PropTypes.string,
  onExportExcel: PropTypes.func,
};

export default function UserListFilterTool({
  numSelected,
  filterName,
  onFilterName,
  onDelete,
  onDateFromChange,
  onDateToChange,
  displayType,
  filterType,
  onExportExcel
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleDateFromChange = (date) => {
    if (onDateFromChange) {
      onDateFromChange(date);
    }
  };

  const handleDateEndChange = (date) => {
    if (onDateToChange) {
      onDateToChange(date);
    }
  };

  const handleExport = () => {
    if (onExportExcel) {
      onExportExcel();
    }
  };


  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          {' '}
          <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
            <Grid item xs={12} sm={6}>
              {filterType !== "daily" &&
                <StyledSearch
                  value={filterName}
                  onChange={onFilterName}
                  placeholder="ค้นหา..."
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  {filterType === 'daily' && (
                    <DateTimePicker inputFormat="DD/MM/YYYY hh:mm" format="DD/MM/YYYY  hh:mm" defaultValue={dayjs().startOf('month')} label={['เลือกวันที่เริ่มต้น']} onChange={handleDateFromChange} />
                  )}
                  {filterType === 'monthly' && (
                    <DatePicker label={['เลือกเดือน ปี']} views={['month', 'year']} onChange={handleDateFromChange} />
                  )}
                  {filterType === 'yearly' && (
                    <DatePicker label={['เลือกปี']} views={['year']} onChange={handleDateFromChange} />
                  )}
                  {/* --------------------------------------------------------------------------------- */}
                  <Box width={'0.5em'} />
                  <Typography> - </Typography>
                  <Box width={'0.5em'} />
                  {/* --------------------------------------------------------------------------------- */}
                  {filterType === 'daily' && (
                    <DateTimePicker inputFormat="DD/MM/YYYY hh:mm" format="DD/MM/YYYY  hh:mm" defaultValue={dayjs()} label={['เลือกวันที่สิ้นสุด']} onChange={handleDateEndChange} />
                  )}
                  {filterType === 'monthly' && (
                    <DatePicker label={['เลือกเดือน ปี']} views={['month', 'year']} onChange={handleDateEndChange} />
                  )}
                  {filterType === 'yearly' && (
                    <DatePicker label={['เลือกปี']} views={['year']} onChange={handleDateEndChange} />
                  )}
                </LocalizationProvider>
                {filterType === 'daily' && (
                  <>
                    <Box width={'1em'} />
                    <IconButton onClick={handleExport} >
                      <Iconify icon="tabler:file-export" />
                    </IconButton>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {!displayType && numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={onDelete}>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) :
        null}
    </StyledRoot>
  );
}
