import { createSlice } from '@reduxjs/toolkit'
import { listOrder, acceptOrder, closeTable } from '../actions/orderActions'

const initialState = {
  loading: false,
  orders: [], // for user object
  error: null,
  success: false,
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listOrder.pending, (state) => {
        state.loading = true
        state.error = null
        state.attributes = []
      })
      .addCase(listOrder.fulfilled, (state, { payload }) => {
        state.loading = false
        state.orders = payload.data
      })
      .addCase(listOrder.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.attributes = []
      })
      .addCase(acceptOrder.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(acceptOrder.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(acceptOrder.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(closeTable.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(closeTable.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(closeTable.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  },
})

export default orderSlice.reducer