import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
// @mui
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

// html-to-image
import { toPng } from 'html-to-image';
// xlsx
import * as XLSX from 'xlsx';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// component
import { useChart } from '../../../components/chart';

AppSummaryByDate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppSummaryByDate({ title, subheader, chartLabels, chartData, ...other }) {
  const cardRef = useRef(null);
  const menuRef = useRef(null);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuVisible, setMenuVisible] = useState(true);

  const handleMenuOpen = (event) => {
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX - 150,
    });

    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorPosition(null);
  };

  const handleDownloadXLSX = () => {
    handleMenuClose();

    let headers;
    if (title.includes('เวลา')) {
      headers = [['เวลา', 'ยอดขาย']];
    } else {
      headers = [['วัน', 'ยอดขาย']];
    }
    const data = chartLabels.map((label, index) => [label, chartData[0].data[index]]);
    // console.log(data);
    const wsData = [...headers, ...data];
    // console.log(wsData);
    const worksheet = XLSX.utils.aoa_to_sheet(wsData);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, title);
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };
  
  const handleDownloadPng = async () => {
    handleMenuClose();

    setMenuVisible(false);

    toPng(cardRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${title}.png`;
        link.click();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setMenuVisible(true);
      });
  };

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '15%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${fCurrency(y)} บาท`;
          }
          return y;
        },
      },
    },
  });

  return (
    <>
      <Card {...other} ref={cardRef}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <IconButton onClick={handleMenuOpen} style={{ visibility: menuVisible ? 'visible' : 'hidden' }}>
              <MenuRoundedIcon />
            </IconButton>
          }
        />
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
          open={Boolean(anchorPosition)}
          onClose={handleMenuClose}
          ref={menuRef}
        >
          <MenuItem onClick={handleDownloadXLSX}>
            <Typography variant="body2">ดาวโหลด Excel</Typography>
          </MenuItem>
          <MenuItem onClick={handleDownloadPng}>
            <Typography variant="body2">ดาวโหลด PNG</Typography>
          </MenuItem>
        </Menu>
        {chartLabels.length > 0 ? (
          <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart series={chartData} options={chartOptions} height={364} />
          </Box>
        ) : (
          <Box
            sx={{
              p: 3,
              height: 411,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#F2F2F2',
              // backgroundColor: '#FFFFFF',
              border: '20px solid white', // Add this line
              borderRadius: '35px',
              borderColor: 'white', // Add this line
              flexDirection: 'column', // Add this line
            }}
          >
            <img src="/assets/not_found.png" alt="No data available" style={{ width: '9em' }} />
            <Typography variant="subtitle1" sx={{ opacity: 0.72 }}>
              ยังไม่พบข้อมูล{title}ในขณะนี้
            </Typography>
          </Box>
        )}
      </Card>
    </>
  );
}
