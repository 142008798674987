import { useState,useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import {
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { listBranch } from '../../redux/actions/branchActions';
import Header from './header';

import Nav from './nav';
import FirstLoginModal from '../../components/survey/FirstLoginModal';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    overflow: 'inherit',
  },
}));

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  overflow: 'auto',
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.up('sm')]: {
    overflow: 'inherit',
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { branch, auth } = useSelector((state) => state);
  const { branches, loading } = branch;
  const branchId = auth.branch;
  const [open, setOpen] = useState(false);
  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
      </Main>
      {/* <div>
        <FirstLoginModal branch={branch} auth={auth} />
      </div> */}
    </StyledRoot>
  );
}
