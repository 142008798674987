import { createSlice } from '@reduxjs/toolkit'
import { listTable, createTable, detailTable, updateTable, deleteTable, displayOrderTable, createManyTable } from '../actions/tableActions'

const initialState = {
  loading: false,
  tables: [],
  table: {},
  total: null,
  totalPage: null,
  error: null,
  success: false,
}

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listTable.pending, (state) => {
        state.loading = true
        state.error = null
        state.tables = []
      })
      .addCase(listTable.fulfilled, (state, { payload }) => {
        state.loading = false
        state.tables = payload.data
        state.total = payload.total
        state.totalPage = payload.total_page
      })
      .addCase(listTable.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.tables = []
      })
      .addCase(detailTable.pending, (state) => {
        state.loading = true
        state.error = null
        state.table = {}
      })
      .addCase(detailTable.fulfilled, (state, { payload }) => {
        state.loading = false
        state.table = payload.data
      })
      .addCase(detailTable.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.table = {}
      })
      .addCase(createTable.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(createTable.fulfilled, (state, { payload }) => {
        state.loading = false
        state.tables = payload.data
        state.success = true
      })
      .addCase(createTable.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(createManyTable.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(createManyTable.fulfilled, (state, { payload }) => {
        state.loading = false
        state.tables = payload.data
        state.success = true
      })
      .addCase(createManyTable.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(updateTable.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateTable.fulfilled, (state, { payload }) => {
        state.loading = false
        state.tables = payload.data
      })
      .addCase(updateTable.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(deleteTable.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(deleteTable.fulfilled, (state, { payload }) => {
        state.loading = false
        state.tables = payload.data
        state.success = false
      })
      .addCase(deleteTable.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(displayOrderTable.pending, (state) => {
        // state.loading = true
        // state.error = null
        state.success = false
      })
      .addCase(displayOrderTable.fulfilled, (state) => {
        // state.loading = false
        // state.products = payload.data
        state.success = false
      })
      .addCase(displayOrderTable.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
  },
})

export default tableSlice.reducer