import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/authSlice'
import productReducer from '../features/productSlice'
import packageReducer from '../features/packageSlice'
import categoryReducer from '../features/categorySlice'
import tagReducer from '../features/tagSlice'
import attributeReducer from '../features/attributeSlice'
import optionReducer from '../features/optionSlice'
import tableReducer from '../features/tableSlice'
import orderReducer from '../features/orderSlice'
import branchReducer from '../features/branchSlice'
import shopReducer from '../features/shopSlice'
import reportReducer from '../features/reportSlice'
import themeReducer from '../features/themeSlice'
import userReducer from '../features/userSlice'

const store = configureStore({
    reducer: {
        auth: authReducer,
        product: productReducer,
        package: packageReducer,
        category: categoryReducer,
        tag: tagReducer,
        attribute: attributeReducer,
        option: optionReducer,
        table: tableReducer,
        order: orderReducer,
        branch: branchReducer,
        shop: shopReducer,
        report: reportReducer,
        user: userReducer,
        theme: themeReducer,
    }
})

export default store