import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Stack, Button } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import { userProfile } from '../../../redux/actions/authActions';
import { listMasterCategory } from '../../../redux/actions/categoryActions';
import { listMasterTag } from '../../../redux/actions/tagActions';
import { listMasterAttribute } from '../../../redux/actions/attributeActions';
import { listOption } from '../../../redux/actions/optionActions';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { userInfo, accessToken, branch, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (accessToken) dispatch(userProfile());
  }, [accessToken, dispatch]);

  // bind master data
  useEffect(() => {
    if (branch) {
      dispatch(listMasterCategory());
      // dispatch(listMasterTag());
      dispatch(listMasterAttribute());
      dispatch(listOption());
    }
  }, [branch, dispatch]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <StoreIcon />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {userInfo?.email && userInfo?.branch[0]?.name.th}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                สาขาของคุณ
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
      {userInfo?.package && (
        <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
          <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography gutterBottom variant="h6">
                แพคเกจของคุณคือ {userInfo?.package?.type}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                วันที่สามารถใช้งานได้ {userInfo?.package?.remaining_days > 0 ? userInfo?.package.remaining_days : 0} วัน
              </Typography>

              <Typography variant="body3" sx={{ color: 'text.secondary' }}>
                วันที่หมดอายุ <br /> {dayjs(userInfo?.package?.expired_at).format('DD/MM/YYYY HH:mm')}
              </Typography>
            </Box>

            {/* <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button> */}
          </Stack>
        </Box>
      )}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
