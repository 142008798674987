import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import _ from 'lodash';
// @mui
import {
  Card,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  CardHeader,
  Button,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createCategory } from '../../redux/actions/categoryActions';

const validationSchema = yup.object({
  name: yup.object().shape({
    th: yup
      .string()
      .min(2, 'โปรดระบุชื่อประเภท (2-100 ตัวอักษร)')
      .max(100, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ')
      .required('โปรดระบุชื่อประเภท'),
    en: yup.string().min(2, 'โปรดระบุชื่อประเภท (2-100 ตัวอักษร)').max(100, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ'),
  }),
});

export default function Create() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.category);

  const formik = useFormik({
    initialValues: {
      name: { th: '' },
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const data = {
          name: values.name,
        };
        dispatch(createCategory({ ...data }));
        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar('This is a success message!', { variant: 'error' });
        }
        enqueueSnackbar('This is a success message!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
        navigate('/category');
      } catch (error) {
        enqueueSnackbar('This is a success message!', { variant: 'error' });
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | เพิ่มประเภท </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">สร้างโต๊ะใหม่</Typography>
        </Stack>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <CardHeader title={'ชื่อ'} />
                <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                  <FormControl fullWidth>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                      (2-100 ตัวอักษร)
                    </Stack>
                    <TextField
                      id="name.th"
                      name="name.th"
                      label="ชื่อประเภท *"
                      type="text"
                      onChange={formik.handleChange}
                      error={formik.touched.name?.th && Boolean(formik.errors.name?.th)}
                      helperText={formik.touched.name?.th && formik.errors.name?.th}
                    />
                  </FormControl>
                </Box>
              </Card>
            </Grid>
            {/* รายละเอียดประเภท (อังกฤษ) */}
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <CardHeader title={'ขนาดโต๊ะ'} />
                <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                  <FormControl fullWidth>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                      (2-100 ตัวอักษร)
                    </Stack>
                    <TextField
                      id="name.en"
                      name="name.en"
                      label="ขนาดโต๊ะ"
                      type="text"
                      onChange={formik.handleChange}
                      error={formik.touched.name?.en && Boolean(formik.errors.name?.en)}
                      helperText={formik.touched.name?.en && formik.errors.name?.en}
                    />
                  </FormControl>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Stack
                direction="row"
                alignItems="flex-end"
                justifyContent="flex-end"
                mb={5}
                spacing={1}
                onClick={() => navigate('/table')}
              >
                <Button variant="outlined">ยกเลิก</Button>

                <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                  บันทึก
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
