/* eslint-disable camelcase */
import _, { filter } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------
import { changePassword } from '../../redux/actions/userActions';

// ----------------------------------------------------------------------

const validationSchema = yup.object().shape({
  a_password: yup
    .string()
    .required('รหัสผ่านจำเป็นต้องกรอก')
    .min(6, 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร')
    .max(12, 'รหัสผ่านต้องมีไม่เกิน 12 ตัวอักษร'),
  b_password: yup
    .string()
    .required('ยืนยันรหัสผ่านจำเป็นต้องกรอก')
    .oneOf([yup.ref("a_password"), null], "รหัสผ่านไม่ตรงกัน")
});

export default function ModalChangePassword({ openModel, handleClose, props = {} }) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { error } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: '',
      last_name: '',
      u_email: '',
      a_password: '',
      b_password: ''
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const data = {
          password: values.a_password,
        };
        dispatch(changePassword({ userId: props?._id, event: { ...data } }));
        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar('โปรดตรวจสอบอีกครั้ง', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        enqueueSnackbar('บันทึกข้อมูลเรียบร้อย', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
        handleClose();
      } catch (error) {
        enqueueSnackbar('โปรดตรวจสอบอีกครั้ง', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
      }
    },
  });


  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openModel}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          เปลี่ยนรหัสผ่าน
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="a_password"
                  name="a_password"
                  label="รหัสผ่าน"
                  type="password"
                  value={formik.values.a_password}
                  onChange={formik.handleChange}
                  error={formik.touched.a_password && Boolean(formik.errors.a_password)}
                  helperText={formik.touched.a_password && formik.errors.a_password}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="b_password"
                  name="b_password"
                  label="ยืนยันรหัสผ่าน"
                  type="password"
                  value={formik.values.b_password}
                  onChange={formik.handleChange}
                  error={formik.touched.b_password && Boolean(formik.errors.b_password)}
                  helperText={formik.touched.b_password && formik.errors.b_password}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()}>ยกเลิก</Button>
            <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
              บันทึก
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
