import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

// @mui
import {
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  CardHeader,
  Stack,
  FormGroup,
  Radio,
  RadioGroup,
  FormControlLabel,
  Paper,
  Avatar,
  Switch,
  Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// redux
import { updateBranch, detailBranch } from '../../redux/actions/branchActions';
// components
// service
import { FileUpload } from '../../services/ImageUploader';
import bank from '../../_mock/bank';
import GooglePlacesAutocomplete from './GooglePlacesAutocomplete';

const validationSchema = yup.object({
  name: yup.object().shape({
    th: yup
      .string()
      .min(2, 'โปรดระบุสาขา (2-100 ตัวอักษร)')
      .max(100, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ')
      .required('โปรดระบุชื่อสาขา'),
    en: yup.string().min(2, 'โปรดระบุชื่อสาขา (2-100 ตัวอักษร)').max(100, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ'),
  }),
  address: yup.string().max(300, 'รายละเอียดที่อยู่เกินจำนวนที่สามารถรองรับได้'),
  phone_number: yup.string().min(9, 'โปรดกรอกข้อมูลให้ถูกต้อง').max(10, 'เบอร์ติดต่อเกินจำนวนที่สามารถรองรับได้'),
  tax_id: yup.string().length(13, 'เลขประจำตัวผู้เสียภาษีต้องมี 13 หลักเท่านั้น'),
  opening_hours: yup.string().min(7, 'โปรดระบุเวลาเปิดร้านให้ถูกต้อง'),
  closing_time: yup.string().min(7, 'โปรดระบุเวลาปืดร้านให้ถูกต้อง'),
  receipt_configs: yup.object().shape({
    header_text: yup.string().min(1, 'โปรดกรอกข้อความหัวบิล').max(30, 'ข้อความหัวบิลเกินจำนวนที่รองรับ'),
    footer_text: yup.string().min(1, 'โปรดกรอกข้อความท้ายบิล').max(30, 'ข้อความท้ายบิลเกินจำนวนที่รองรับ'),
  }),
  system_configs: yup.object().shape({
    vat_rate: yup
      .number()
      .typeError('กรุณาระบบุเป็นจำนวนตัวเลขเท่านั้น')
      .min(0, 'โปรดกรอกภาษีมูลค่าเพิ่ม')
      .max(100, 'ภาษีมูลค่าเพิ่มระบุได้ไม่เกิน 100%'),
    service_charge_rate: yup
      .number()
      .typeError('กรุณาระบบุเป็นจำนวนตัวเลขเท่านั้น')
      .min(0, 'โปรดกรอกค่าบริการ')
      .max(100, 'ค่าบริการ ระบุได้ไม่เกิน 100%'),
    // locations: yup.object().shape({
    //   name: yup.string(),
    //   lat: yup.number().required('Latitude is required'),
    //   lng: yup.number().required('Longitude is required'),
    //   radius: yup.number().required('Radius is required'),
    // }),
  }),
  payment_configs: yup.object().shape({
    account_number: yup
      .string()
      .typeError('กรุณาระบบุเป็นจำนวนตัวเลขเท่านั้น')
      .min(10, 'โปรดใส่เลขบัญชีหรือเลขพร้อยเพย์')
      .max(30, 'เลขบัญชีหรือเลขพร้อยเพย์ เกินจำนวนที่สามารถกรอกได้')
      .when('bank_type', {
        is: 'BANK',
        then: yup.string().required('โปรดระบุเลขบัญชีให้ถูกต้อง'),
        otherwise: yup.string().notRequired(),
      })
      .when('bank_type', {
        is: 'PROMPTPAY',
        then: yup.string().required('โปรดระบุเลขบัญชีหรือเลขพร้อยเพย์'),
        otherwise: yup.string().notRequired(),
      }),
    // .test('check-required', 'โปรดระบุเลขบัญชีหรือเลขพร้อยเพย์', function (val) {
    //   return this.parent.bank_type === 'BANK';
    // }),
    account_name: yup
      .string()
      .typeError('โปรดกรอกชื่อบัญชีหรือชื่อพร้อมเพย์ให้ถูกต้อง')
      .min(6, 'โปรดกรอกชื่อบัญชีหรือชื่อพร้อมเพย์ให้ถูกต้อง')
      .max(100, 'ชื่อบัญชีหรือชื่อพร้อมเพย์ เกินจำนวนที่สามารถกรอกได้')
      .when('bank_type', {
        is: 'BANK',
        then: yup.string().required('โปรดกรอกชื่อบัญชีให้ถูกต้อง'),
        otherwise: yup.string().notRequired(),
      })
      .when('bank_type', {
        is: 'PROMPTPAY',
        then: yup.string().required('โปรดกรอกชื่อบัญชีหรือชื่อพร้อมเพย์ให้ถูกต้อง'),
        otherwise: yup.string().notRequired(),
      }),
    // .test('check-required', 'โปรดระบุชื่อบัญชีหรือชื่อพร้อมเพย์', function (val) {
    //   return this.parent.bank_type === 'BANK';
    // })
  }),
});

export default function Branch(props) {
  // const { id } = useParams();
  const { id } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { branch, accessToken } = useSelector((state) => state.auth);
  const { branchState, error, loading } = useSelector((state) => state.branch);
  const [file, setFile] = useState();
  const [vatRate, setVatRate] = useState();
  const [serviceCharge, setServiceCharge] = useState();
  const [locations, setLocations] = useState();
  const [orderLimit, setOrderLimit] = useState();
  useEffect(() => {
    if (branch && id) dispatch(detailBranch({ branchId: id }));
  }, [id, branch, dispatch]);

  const formik = useFormik({
    initialValues: {
      name: { en: '', th: '' },
      address: '',
      location_address: {
        google_mal_url: '',
        name: '',
        lat: 0,
        lng: 0,
        province: '',
        district: '',
        subdistrict: '',
        radius: 100,
      },
      logo_image: '',
      phone_number: '',
      tax_id: '',
      display_opening_hours: '',
      opening_hours: '',
      display_closing_time: '',
      closing_time: '',
      receipt_configs: {
        headerText: '',
        footerText: '',
        is_show_qr_code: false,
      },
      system_configs: {
        is_vat_enable: false,
        is_vat_exclude: false,
        vat_rate: 0,
        is_service_charge_enable: false,
        service_charge_rate: 0,
        order_limit: '',
        is_limit_order_area: false,
        is_can_takeaway: false,
        is_can_pickup: false,
      },
      payment_configs: {
        bank_type: 'CASH',
        bank_name: '',
        account_name: '',
        account_number: '',
      },
      current_bank: null,
      vat_type: 'none',
      event_configs: [],
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let image = {};
        if (file) {
          const fileUpload = await FileUpload(accessToken, branch, 'B', file);
          image = { logo_image: fileUpload.url };
        } else if (values.image_url) {
          image = { logo_image: values.image_url };
        }

        const paymentConfigs =
          values.payment_configs?.bank_type === 'CASH'
            ? {
                bank_type: 'CASH',
                bank_name: '',
                account_name: '',
                account_number: '',
              }
            : values.payment_configs;

        const data = {
          name: values.name,
          address: values.address,
          phone_number: values.phone_number,
          receipt_configs: values.receipt_configs,
          tax_id: values.tax_id,
          opening_hours: values.opening_hours,
          closing_time: values.closing_time,
          system_configs: values.system_configs,
          payment_configs: paymentConfigs,
          location_address: values.location_address,
          event_configs: values.event_configs,
          ...image,
        };

        await dispatch(updateBranch({ branchId: id, event: { ...data } }));
        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar(error, { variant: 'error' });
          return;
        }
        enqueueSnackbar('แก้ไขข้อมูลเรียบร้อย', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
        navigate('/shop/setting', { replace: true });
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (branchState) {
      formik.setFieldValue('name.th', branchState.name?.th || '');
      formik.setFieldValue('name.en', branchState.name?.en || '');
      // added
      // formik.setFieldValue('tax_id', branchState.tax_id || '');
      formik.setFieldValue('phone_number', branchState.phone_number || '');
      formik.setFieldValue('address', branchState.address || '');
      formik.setFieldValue('logo_image', branchState.logo_image || null);

      formik.setFieldValue(
        'system_configs',
        branchState.system_configs || {
          is_vat_enable: false,
          is_vat_exclude: false,
          vat_rate: 0,
          is_service_charge_enable: false,
          service_charge_rate: 0,
          order_limit: '',
          is_limit_order_area: false,
          is_can_takeaway: false,
          is_can_pickup: false,
        }
      );

      formik.setFieldValue(
        'location_address',
        branchState.location_address || {
          google_mal_url: '',
          name: '',
          lat: 0,
          lng: 0,
          province: '',
          district: '',
          subdistrict: '',
          radius: 100,
        }
      );

      formik.setFieldValue(
        'receipt_configs',
        branchState.receipt_configs || {
          header_text:
            branchState?.receipt_configs?.headerText !== undefined ? branchState?.receipt_configs?.headerText : '',
          footer_text:
            branchState?.receipt_configs?.footerText !== undefined ? branchState?.receipt_configs?.footerText : '',
          is_show_qr_code: branchState?.receipt_configs?.is_show_qr_code,
        }
      );

      formik.setFieldValue('event_configs', branchState.event_configs || []);

      if (Array.isArray(branchState.payment_configs)) {
        formik.setFieldValue('payment_configs', {
          bank_type: 'CASH',
          bank_name: '',
          account_name: '',
          account_number: '',
        });
      } else if (!branchState?.payment_configs?.bank_type) {
        formik.setFieldValue('payment_configs', {
          bank_type: 'CASH',
          bank_name: '',
          account_name: '',
          account_number: '',
        });
      } else {
        formik.setFieldValue(
          'payment_configs',
          branchState.payment_configs || {
            bank_type: 'CASH',
            bank_name: '',
            account_name: '',
            account_number: '',
          }
        );
      }
      const currentBank = bank.find((b) => b.official_name_thai === branchState.payment_configs?.bank_name);
      formik.setFieldValue('current_bank', currentBank || null);

      if (branchState.opening_hours) {
        formik.setFieldValue('display_opening_hours', dayjs(`2000-01-01 ${branchState.opening_hours}`));
        formik.setFieldValue('opening_hours', branchState.opening_hours || '');
      }

      if (branchState.closing_time) {
        formik.setFieldValue('display_closing_time', dayjs(`2000-01-01 ${branchState.closing_time}`));
        formik.setFieldValue('closing_time', branchState.closing_time || '');
      }

      if (branchState.system_configs?.is_vat_enable) {
        formik.setFieldValue('vat_type', branchState.system_configs.is_vat_exclude === true ? 'exclude' : 'include');
      } else {
        formik.setFieldValue('vat_type', 'none');
      }
    }
    if (branchState.system_configs) {
      setVatRate(branchState.system_configs.vat_rate);
      setServiceCharge(branchState.system_configs.service_charge_rate);
      setOrderLimit(branchState.system_configs.order_limit || '');
    }

    if (branchState.tax_id) {
      formik.setFieldValue('tax_id', branchState.tax_id);
    }
  }, [branchState]);

  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFile(e.target.files[0]);
      setImagePreviewUrl(reader.result);
      formik.setFieldValue('logo_image', reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleChangeTime = (type, value) => {
    const cTime = dayjs(value).format('HH:mm:00');
    if (cTime !== 'Invalid Date') {
      if (type === 1) {
        formik.setFieldValue('display_opening_hours', value);
        formik.setFieldValue('opening_hours', cTime);
      } else {
        formik.setFieldValue('display_closing_time', value);
        formik.setFieldValue('closing_time', cTime);
      }
    }
  };

  const handleChangeVatType = (value) => {
    if (value === 'none') {
      // formik.setFieldValue('system_configs.vat_rate', vatRate || formik.values.system_configs.vat_rate);
      formik.setFieldValue('system_configs.vat_rate', vatRate);
      formik.setFieldValue('system_configs.is_vat_enable', false);
    } else {
      formik.setFieldValue('system_configs.is_vat_enable', true);
      if (value === 'include') formik.setFieldValue('system_configs.is_vat_exclude', false);
      else formik.setFieldValue('system_configs.is_vat_exclude', true);
    }
    formik.setFieldValue('vat_type', value);
  };

  const handleChangeServiceCharge = (value) => {
    if (value === 'false') {
      formik.setFieldValue('system_configs.service_charge_rate', serviceCharge);
    }
    formik.setFieldValue('system_configs.is_service_charge_enable', value === 'true');
  };

  const handleBankType = (value) => {
    formik.setFieldValue('payment_configs.bank_type', value);

    if (value === 'PROMPTPAY') {
      formik.setFieldValue('payment_configs.bank_name', 'PROMPTPAY');
      // formik.setFieldValue('payment_configs.account_name', formik.values?.payment_configs?.account_name === '' ? null : formik.values?.payment_configs?.account_name);
      // formik.setFieldValue('payment_configs.account_number', formik.values?.payment_configs?.account_number === '' ? null : formik.values?.payment_configs?.account_number);
    }
  };

  const handleChangeBankName = (e, value) => {
    formik.setFieldValue('payment_configs.bank_name', value.official_name_thai);
    formik.setFieldValue('current_bank', value);
  };

  return (
    <>
      {!loading && branchState && (
        <>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Paper
                  elevation="1"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 5,
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      mb: 2,
                      border: '1px dashed rgba(145, 158, 171, 0.32)',
                      borderRadius: '50%',
                      width: '144px',
                      height: '144px',
                      display: 'flex',
                      alignItems: 'center',
                      p: 5,
                      justifyContent: 'center',
                    }}
                  >
                    <Avatar
                      sx={{ width: '126px', height: '126px', fontSize: '14px' }}
                      onClick={handleUploadClick}
                      alt="รูปภาพสาขา"
                      src={formik.values?.logo_image}
                    >
                      ภาพสาขา
                    </Avatar>
                  </Box>
                  <Typography variant="body2" fontSize={12} textAlign={'center'}>
                    Allowed *.jpeg, *.jpg, *.png, *.gif <br />
                  </Typography>
                  {/* <FormControlLabel control={<Switch name="Profile" sx={{ fontSize: "10px" }} />} sx={{ mt: 5, mb: 5 }} size="small" label="Profile" /> */}
                  <input
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} md={8}>
                <Paper elevation="1" sx={{ mb: 2 }}>
                  <CardHeader title={'ข้อมูลสาขา'} />
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 2,
                      p: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(2, 1fr)',
                      }}
                    >
                      <FormControl fullWidth>
                        <TextField
                          id="name.th"
                          name="name.th"
                          label="ชื่อสาขา *"
                          type="text"
                          value={formik.values.name?.th}
                          onChange={formik.handleChange}
                          error={formik.touched.name?.th && Boolean(formik.errors.name?.th)}
                          helperText={formik.touched.name?.th && formik.errors.name?.th}
                        />
                      </FormControl>

                      <FormControl fullWidth>
                        <TextField
                          id="name.en"
                          name="name.en"
                          label="ชื่อสาขา (อังกฤษ)"
                          type="text"
                          value={formik.values.name?.en}
                          onChange={formik.handleChange}
                          error={formik.touched.name?.en && Boolean(formik.errors.name?.en)}
                          helperText={formik.touched.name?.en && formik.errors.name?.en}
                        />
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: 'grid',
                      }}
                    >
                      <FormControl fullWidth>
                        <TextField
                          id="address"
                          name="address"
                          label="ที่อยู่"
                          multiline
                          rows={3}
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          error={formik.touched.address && Boolean(formik.errors.address)}
                          helperText={formik.touched.address && formik.errors.address}
                        />
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(2, 1fr)',
                      }}
                    >
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            id="opening_hours"
                            name="opening_hours"
                            label="เวลาเปิด"
                            format="HH:mm"
                            value={formik.values.display_opening_hours}
                            // onChange={formik.handleChange}
                            onChange={(newValue) => handleChangeTime(1, newValue)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={formik.touched.opening_hours && Boolean(formik.errors.opening_hours)}
                                helperText={formik.touched.opening_hours && formik.errors.opening_hours}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>

                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            id="closing_time"
                            name="closing_time"
                            label="เวลาปิด"
                            format="HH:mm"
                            value={formik.values.display_closing_time}
                            // value={formik.values.opening_hours}
                            // onChange={formik.handleChange}

                            onChange={(newValue) => handleChangeTime(2, newValue)}
                            renderInput={(params) => (
                              <TextField
                                error={formik.touched.closing_time && Boolean(formik.errors.closing_time)}
                                helperText={formik.touched.closing_time && formik.errors.closing_time}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(2, 1fr)', // แบ่งความกว้างเป็น 2 ส่วน
                      }}
                    >
                      <TextField
                        id="phone_number"
                        name="phone_number"
                        label="เบอร์ติดต่อ"
                        type="text"
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                        helperText={formik.touched.phone_number && formik.errors.phone_number}
                      />
                      <TextField
                        id="tax_id"
                        name="tax_id"
                        label="เลขประจำตัวผู้เสียภาษี"
                        type="text"
                        value={formik.values.tax_id}
                        onChange={formik.handleChange}
                        error={formik.touched.tax_id && Boolean(formik.errors.tax_id)}
                        helperText={formik.touched.tax_id && formik.errors.tax_id}
                        fullWidth
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(1, 1fr)',
                      }}
                    >
                      <GooglePlacesAutocomplete formik={formik} locations={locations} setLocations={setLocations} />
                    </Box>
                  </Box>
                </Paper>

                <Paper elevation="1" sx={{ mb: 2 }}>
                  <CardHeader title={'ตั้งค่าการคำนวณภาษีมูลค่าเพิ่ม (VAT)'} />
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 2,
                      p: 3,
                    }}
                  >
                    <Box>
                      <FormControl fullWidth>
                        <RadioGroup
                          aria-labelledby="vat-radio-buttons-group-label"
                          value={formik.values.vat_type}
                          name="vat_type"
                          id="vat_type"
                          onChange={(e) => handleChangeVatType(e.target.value)}
                          // onChange={(e) => handleChangeVatType(e.target.value)}
                        >
                          <FormControlLabel value="none" control={<Radio />} label="ไม่คิดค่า VAT กับลูกค้า" />
                          <FormControlLabel
                            value="include"
                            control={<Radio />}
                            label="คิดค่า VAT แบบรวมกับราคาสินค้า"
                          />
                          <FormControlLabel
                            value="exclude"
                            control={<Radio />}
                            label="คิดค่า VAT แบบยังไม่รวมกับราคาสินค้า"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    {formik.values.vat_type !== 'none' && (
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(2, 1fr)',
                        }}
                      >
                        <FormControl fullWidth>
                          <TextField
                            id="system_configs.vat_rate"
                            name="system_configs.vat_rate"
                            label="ภาษีมูลค่าเพิ่ม (%VAT)"
                            type="text"
                            inputProps={{ maxLength: 3 }}
                            value={formik.values.system_configs?.vat_rate}
                            onChange={formik.handleChange}
                            onKeyUp={(e) => {
                              setVatRate(e.target.value);
                            }}
                            error={
                              formik.touched.system_configs?.vat_rate && Boolean(formik.errors.system_configs?.vat_rate)
                            }
                            helperText={
                              formik.touched.system_configs?.vat_rate && formik.errors.system_configs?.vat_rate
                            }
                          />
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                </Paper>

                <Paper elevation="1" sx={{ mb: 2 }}>
                  <CardHeader title={'ตั้งค่าการคำนวณค่าบริการ (SERVICE CHARGE)'} />
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 2,
                      p: 3,
                    }}
                  >
                    <Box>
                      <FormControl fullWidth>
                        <RadioGroup
                          aria-labelledby="sc-radio-buttons-group-label"
                          value={formik.values.system_configs?.is_service_charge_enable}
                          name="system_configs.is_service_charge_enable"
                          id="system_configs.is_service_charge_enable"
                          onChange={(e) => handleChangeServiceCharge(e.target.value)}
                        >
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="ไม่คิดค่า Service Charge กับลูกค้า"
                          />
                          <FormControlLabel value="true" control={<Radio />} label="คิดค่า Service Charge" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    {formik.values.system_configs?.is_service_charge_enable === true && (
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(2, 1fr)',
                        }}
                      >
                        <FormControl fullWidth>
                          <TextField
                            id="system_configs.service_charge_rate"
                            name="system_configs.service_charge_rate"
                            label="ค่าบริการเพิ่มเติม (%Service Charge)"
                            type="text"
                            inputProps={{ maxLength: 3 }}
                            value={formik.values.system_configs?.service_charge_rate}
                            onChange={formik.handleChange}
                            onKeyUp={(e) => {
                              setServiceCharge(e.target.value);
                            }}
                            error={
                              formik.touched.system_configs?.service_charge_rate &&
                              Boolean(formik.errors.system_configs?.service_charge_rate)
                            }
                            helperText={
                              formik.touched.system_configs?.service_charge_rate &&
                              formik.errors.system_configs?.service_charge_rate
                            }
                          />
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                </Paper>
                <Paper elevation="1" sx={{ mb: 2 }}>
                  <CardHeader title={'ตั้งค่าใบเสร็จ'} />
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 2,
                      p: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(1, 1fr)',
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.values.receipt_configs?.is_show_qr_code}
                              id="receipt_configs.is_show_qr_code"
                              inputProps={{ 'aria-label': 'controlled' }}
                              onChange={(e, value) =>
                                formik.setFieldValue('receipt_configs.is_show_qr_code', value || false)
                              }
                              label="ตั้งค่าคิวอาร์โค้ดท้ายใบเสร็จ"
                            />
                          }
                          label="ตั้งค่าคิวอาร์โค้ดท้ายใบเสร็จ"
                        />
                      </FormGroup>
                    </Box>

                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(2, 1fr)',
                      }}
                    >
                      <FormControl fullWidth>
                        <TextField
                          id="receipt_configs.header_text"
                          name="receipt_configs.header_text"
                          label="ข้อความหัวบิล"
                          type="text"
                          inputProps={{ maxLength: 30 }}
                          value={formik.values.receipt_configs?.header_text}
                          onChange={formik.handleChange}
                          onKeyUp={(e) => {
                            setServiceCharge(e.target.value);
                          }}
                          error={
                            formik.touched.system_configs?.header_text &&
                            Boolean(formik.errors.system_configs?.header_text)
                          }
                          helperText={
                            formik.touched.system_configs?.header_text && formik.errors.system_configs?.header_text
                          }
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="receipt_configs.footer_text"
                          name="receipt_configs.footer_text"
                          label="ข้อความท้ายบิล"
                          type="text"
                          inputProps={{ maxLength: 30 }}
                          value={formik.values.receipt_configs?.footer_text}
                          onChange={formik.handleChange}
                          onKeyUp={(e) => {
                            setServiceCharge(e.target.value);
                          }}
                          error={
                            formik.touched.system_configs?.footer_text &&
                            Boolean(formik.errors.system_configs?.footer_text)
                          }
                          helperText={
                            formik.touched.system_configs?.footer_text && formik.errors.system_configs?.footer_text
                          }
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </Paper>
                <Paper elevation="1" sx={{ mb: 2 }}>
                  <CardHeader title={'ตั้งค่าช่องทางการชำระเงิน'} />
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 2,
                      p: 3,
                    }}
                  >
                    <Box xs={12} md={8}>
                      <FormControl fullWidth>
                        <RadioGroup
                          aria-labelledby="sc-radio-buttons-group-label"
                          value={formik.values.payment_configs?.bank_type}
                          name="payment_configs.bank_type"
                          id="payment_configs.bank_type"
                          onChange={(e) => handleBankType(e.target.value)}
                        >
                          <FormControlLabel value="CASH" control={<Radio />} label="เงินสดเท่านั้น" />
                          <FormControlLabel value="BANK" control={<Radio />} label="บัญชีธนาคาร" />
                          <FormControlLabel value="PROMPTPAY" control={<Radio />} label="บัญชีพร้อมเพย์" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    {formik.values.payment_configs.bank_type === 'BANK' && (
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(2, 1fr)',
                        }}
                      >
                        <FormControl fullWidth>
                          <Autocomplete
                            id="payment_configs.bank_name"
                            name="payment_configs.bank_name"
                            fullWidth
                            options={bank}
                            defaultValue={formik.values.current_bank}
                            onChange={handleChangeBankName}
                            getOptionLabel={(option) => option.official_name_thai}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                  loading="lazy"
                                  width="20"
                                  style={{ backgroundColor: option.color }}
                                  src={`${option.image}`}
                                  alt=""
                                />
                                {option.official_name_thai}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="เลือกธนาคารของท่าน"
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    )}

                    {formik.values.payment_configs.bank_type !== 'CASH' && (
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(2, 1fr)',
                        }}
                      >
                        <FormControl fullWidth>
                          <TextField
                            id="payment_configs.account_name"
                            name="payment_configs.account_name"
                            label={`${
                              formik.values.payment_configs.bank_type === 'BANK' ? 'ชื่อบัญชี *' : 'ชื่อพร้อมเพย์ *'
                            }`}
                            type="text"
                            value={formik.values.payment_configs?.account_name}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.payment_configs?.account_name &&
                              Boolean(formik.errors.payment_configs?.account_name)
                            }
                            helperText={
                              formik.touched.payment_configs?.account_name &&
                              formik.errors.payment_configs?.account_name
                            }
                          />
                        </FormControl>

                        <FormControl fullWidth>
                          <TextField
                            id="payment_configs.account_number"
                            name="payment_configs.account_number"
                            label={`${
                              formik.values.payment_configs.bank_type === 'BANK' ? 'เลขบัญชีธนาคาร *' : 'เลขพร้อมเพย์ *'
                            }`}
                            type="text"
                            value={formik.values.payment_configs?.account_number}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.payment_configs?.account_number &&
                              Boolean(formik.errors.payment_configs?.account_number)
                            }
                            helperText={
                              formik.touched.payment_configs?.account_number &&
                              formik.errors.payment_configs?.account_number
                            }
                          />
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                </Paper>
                <Paper elevation="1" sx={{ mb: 2 }}>
                  <CardHeader title={'ตั้งค่าระบบ'} />
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 2,
                      p: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(1, 1fr)',
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={orderLimit}
                              id="system_configs.order_limit"
                              inputProps={{ 'aria-label': 'controlled' }}
                              onChange={(e, value) => {
                                setOrderLimit(value || false);
                                if (orderLimit) {
                                  formik.setFieldValue('system_configs.order_limit', '');
                                } else {
                                  formik.setFieldValue(
                                    'system_configs.order_limit',
                                    branchState?.system_configs?.order_limit || ''
                                  );
                                }
                                // formik.setFieldValue('system_configs.order_limit', '');
                              }}
                              label="จำกัดจำนวนการสั่งอาหารต่อบิลต่อรอบ"
                            />
                          }
                          label="จำกัดจำนวนการสั่งอาหารต่อบิลต่อรอบ"
                        />
                      </FormGroup>
                    </Box>

                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(1, 1fr)',
                      }}
                    >
                      <FormControl fullWidth>
                        <TextField
                          id="system_configs.order_limit"
                          name="system_configs.order_limit"
                          label="กรุณาจำนวนรายการต่อบิล"
                          type="text"
                          disabled={!orderLimit}
                          inputProps={{ maxLength: 30 }}
                          value={formik.values.system_configs?.order_limit}
                          onChange={formik.handleChange}
                          onKeyUp={(e) => {
                            setServiceCharge(e.target.value);
                          }}
                          error={
                            formik.touched.system_configs?.order_limit &&
                            Boolean(formik.errors.system_configs?.order_limit)
                          }
                          helperText={
                            formik.touched.system_configs?.order_limit && formik.errors.system_configs?.order_limit
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(1, 1fr)',
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.values.system_configs?.is_limit_order_area}
                              id="system_configs.is_limit_order_area"
                              inputProps={{ 'aria-label': 'controlled' }}
                              onChange={(e, value) =>
                                formik.setFieldValue('system_configs.is_limit_order_area', value || false)
                              }
                              label="จำกัดพื้นที่สั่งอาหาร"
                            />
                          }
                          label="จำกัดพื้นที่สั่งอาหาร"
                        />
                      </FormGroup>
                    </Box>

                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(1, 1fr)',
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.values.system_configs?.is_can_takeaway}
                              id="system_configs.is_can_takeaway"
                              inputProps={{ 'aria-label': 'controlled' }}
                              onChange={(e, value) =>
                                formik.setFieldValue('system_configs.is_can_takeaway', value || false)
                              }
                              label="สั่งอาหารกลับบ้าน"
                            />
                          }
                          label="สั่งอาหารกลับบ้าน"
                        />
                      </FormGroup>
                    </Box>

                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(1, 1fr)',
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.values.system_configs?.is_can_pickup}
                              id="system_configs.is_can_pickup"
                              inputProps={{ 'aria-label': 'controlled' }}
                              onChange={(e, value) =>
                                formik.setFieldValue('system_configs.is_can_pickup', value || false)
                              }
                              label="สั่งอาหารแบบรับที่ร้าน (Pickup)"
                            />
                          }
                          label="สั่งอาหารแบบรับที่ร้าน (Pickup)"
                        />
                      </FormGroup>
                    </Box>

                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(1, 1fr)',
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.values.system_configs?.is_can_call_service}
                              id="system_configs.is_can_call_service"
                              inputProps={{ 'aria-label': 'controlled' }}
                              onChange={(e, value) =>
                                formik.setFieldValue('system_configs.is_can_call_service', value || false)
                              }
                              label="ฟังก์ชั่นเรียกพนักงาน"
                            />
                          }
                          label="ฟังก์ชั่นเรียกพนักงาน"
                        />
                      </FormGroup>
                    </Box>
                  </Box>
                </Paper>
                {formik.values.system_configs?.is_can_call_service && (
                  <Paper elevation="1" sx={{ mb: 2 }}>
                    <CardHeader title={'ตั้งค่าฟังก์ชั่นเรียกพนักงาน'} />
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        p: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(1, 1fr)',
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formik.values.event_configs?.includes('6414c5febd4298c1c49b983a')}
                                id="event_configs.6414c5febd4298c1c49b983a"
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={(e, value) => {
                                  const check = formik.values.event_configs?.includes('6414c5febd4298c1c49b983a');
                                  if (check) {
                                    formik.setFieldValue(
                                      'event_configs',
                                      formik.values.event_configs.filter((c) => c !== '6414c5febd4298c1c49b983a')
                                    );
                                  } else {
                                    formik.setFieldValue('event_configs', [
                                      ...formik.values.event_configs,
                                      '6414c5febd4298c1c49b983a',
                                    ]);
                                  }
                                }}
                                label="เรียกชำระเงิน"
                              />
                            }
                            label="เรียกชำระเงิน"
                          />
                        </FormGroup>
                      </Box>
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(1, 1fr)',
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formik.values.event_configs?.includes('6414c7babd4298c1c49b983b')}
                                id="event_configs.6414c7babd4298c1c49b983b"
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={(e, value) => {
                                  const check = formik.values.event_configs?.includes('6414c7babd4298c1c49b983b');
                                  if (check) {
                                    formik.setFieldValue(
                                      'event_configs',
                                      formik.values.event_configs.filter((c) => c !== '6414c7babd4298c1c49b983b')
                                    );
                                  } else {
                                    formik.setFieldValue('event_configs', [
                                      ...formik.values.event_configs,
                                      '6414c7babd4298c1c49b983b',
                                    ]);
                                  }
                                }}
                                label="เรียกพนักงาน"
                              />
                            }
                            label="เรียกพนักงาน"
                          />
                        </FormGroup>
                      </Box>
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(1, 1fr)',
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formik.values.event_configs?.includes('6415253e8ba40b38c28ec235')}
                                id="event_configs.6415253e8ba40b38c28ec235"
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={(e, value) => {
                                  const check = formik.values.event_configs?.includes('6415253e8ba40b38c28ec235');
                                  if (check) {
                                    formik.setFieldValue(
                                      'event_configs',
                                      formik.values.event_configs.filter((c) => c !== '6415253e8ba40b38c28ec235')
                                    );
                                  } else {
                                    formik.setFieldValue('event_configs', [
                                      ...formik.values.event_configs,
                                      '6415253e8ba40b38c28ec235',
                                    ]);
                                  }
                                }}
                                label="ขออุปกรณ์"
                              />
                            }
                            label="ขออุปกรณ์"
                          />
                        </FormGroup>
                      </Box>
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(1, 1fr)',
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formik.values.event_configs?.includes('65a0e2de5b628d7c3b066682')}
                                id="event_configs.65a0e2de5b628d7c3b066682"
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={(e, value) => {
                                  const check = formik.values.event_configs?.includes('65a0e2de5b628d7c3b066682');
                                  if (check) {
                                    formik.setFieldValue(
                                      'event_configs',
                                      formik.values.event_configs.filter((c) => c !== '65a0e2de5b628d7c3b066682')
                                    );
                                  } else {
                                    formik.setFieldValue('event_configs', [
                                      ...formik.values.event_configs,
                                      '65a0e2de5b628d7c3b066682',
                                    ]);
                                  }
                                }}
                                label="ส่งข้อความถึงพนักงาน"
                              />
                            }
                            label="ส่งข้อความถึงพนักงาน"
                          />
                        </FormGroup>
                      </Box>
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(1, 1fr)',
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formik.values.event_configs?.includes('641525678ba40b38c28ec236')}
                                id="event_configs.641525678ba40b38c28ec236"
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={(e, value) => {
                                  const check = formik.values.event_configs?.includes('641525678ba40b38c28ec236');
                                  if (check) {
                                    formik.setFieldValue(
                                      'event_configs',
                                      formik.values.event_configs.filter((c) => c !== '641525678ba40b38c28ec236')
                                    );
                                  } else {
                                    formik.setFieldValue('event_configs', [
                                      ...formik.values.event_configs,
                                      '641525678ba40b38c28ec236',
                                    ]);
                                  }
                                }}
                                label="อื่นๆ"
                              />
                            }
                            label="อื่นๆ"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Paper>
                )}
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} spacing={1}>
                  {/* <Button variant="outlined" onClick={() => handleBack()}>
                  ยกเลิก
                </Button> */}

                  <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
}
