import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import _ from 'lodash';
// @mui
import {
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  Button,
  Stack,
  CardContent,
  CardActions,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const validationSchema = yup.object({
  //  old_password: yup
  //   .string()
  //   .required('กรุณากรอกรหัสผ่านเก่า')
  //   .min(8, 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร')
  //   .max(16, 'รหัสผ่านต้องมีไม่เกิน 16 ตัวอักษร'),
  //  new_password: yup
  //   .string()
  //   .required('กรุณากรอกรหัสผ่านใหม่')
  //   .min(8, 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร')
  //   .max(16, 'รหัสผ่านต้องมีไม่เกิน 16 ตัวอักษร'),
  //  new_password_confirmation: yup
  //   .string()
  //   .required('กรุณายืนยันรหัสผ่านใหม่')
  //   .min(8, 'ยืนยันรหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร')
  //   .max(16, 'ยืนยันรหัสผ่านต้องมีไม่เกิน 16 ตัวอักษร')
  //   .oneOf([yup.ref('new_password'), null], 'รหัสผ่านไม่ตรงกัน'),
});

export default function ManageEvent() {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [options, setOptions] = useState({
    option1: '',
    option2: '',
    option3: '',
    option4: '',
  });
  // const [showNewPassword, setShowNewPassword] = useState(false);
  // const [showOldPassword, setShowOldPassword] = useState(false);
  // const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);

  const mockData = '';

  const formik = useFormik({
    initialValues: {
      option1: 'การชำระเงิน',
      option2: '',
      option3: '',
      option4: '',
      is_active1: false,
      is_active2: false,
      is_active3: false,
      is_active4: false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // console.log(formik.values);
      try {
        const activeOptions = [
          { option: values.option1, active: values.is_active1 },
          { option: values.option2, active: values.is_active2 },
          { option: values.option3, active: values.is_active3 },
          { option: values.option4, active: values.is_active4 },
        ];
        enqueueSnackbar('บันทึกข้อมูลเรียบร้อย', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setOptions(activeOptions);
      } catch (error) {
        enqueueSnackbar('เกิดข้อผิดพลาด', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
      }
    },
  });

  const handleBack = () => {
    navigate('/dashboard');
  };

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | จัดการเรียกพนักงาน </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">จัดการเรียกพนักงาน</Typography>
        </Stack>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    ตั้งค่าตัวเลือก
                  </Typography>
                  {Array.from({ length: 4 }).map((_, i) => (
                    <Box key={`option${i + 1}`} mb={3}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={8}>
                          <TextField
                            id={`option${i + 1}`}
                            name={`option${i + 1}`}
                            label={`ตัวเลือกที่ ${i + 1}`}
                            type="text"
                            onChange={formik.handleChange}
                            disabled={i === 0}
                            value={i === 0 ? 'การชำระเงิน' : formik.values[`option${i + 1}`]}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">สถานะ</FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={formik.values[`is_active${i + 1}`]}
                                    onChange={formik.handleChange}
                                    name={`is_active${i + 1}`}
                                    color="primary"
                                  />
                                }
                                label={
                                  !isMobileScreen && (formik.values[`is_active${i + 1}`] ? 'ใช้งาน' : 'ปิดการใช้งาน')
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Stack direction="row" spacing={2} ml="auto">
                    <Button variant="outlined" onClick={() => handleBack()}>
                      ยกเลิก
                    </Button>
                    <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained" color="primary">
                      บันทึก
                    </LoadingButton>
                  </Stack>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
