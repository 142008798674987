// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'ภาพรวมร้านค้า',
    path: '/dashboard',
    icon: icon('flaticon_dashboard'),
    resource: ['dashboards']
  },
  {
    title: 'รายงาน',
    path: '',
    icon: icon('flaticon_report'),
    resource: ['reports']
  },
  // {
  //   title: 'รายงานยอดขายประจำวัน',
  //   path: '/report/daily',
  // },
  // {
  //   title: 'รายงานยอดขายประจำเดือน',
  //   path: '/report/monthly',
  // },
  // {
  //   title: 'รายงานยอดขายประจำปี',
  //   path: '/report/yearly',
  // },
  // {
  //   title: 'รายงานยอดขายรายบิล',
  //   path: '/report/bill',
  // },
  // {
  //   title: 'ออเดอร์วันนี้',
  //   path: '/live-order',
  //   icon: icon('ic_cart'),
  // },
  {
    title: 'ประเภทสินค้า',
    path: '/category',
    icon: icon('flaticon_attribute'),
    resource: ['categories']
  },
  {
    title: 'เมนูสินค้า',
    path: '/product',
    icon: icon('flaticon_utensils'),
    resource: ['products']
  },
  {
    title: 'แพ็กเกจ (บุฟเฟ่ต์)',
    path: '/package',
    icon: icon('flaticon_table'),
    resource: ['packages']
  },
  {
    title: 'ตัวเลือกสินค้า',
    path: '/attribute',
    icon: icon('flaticon_category'),
    resource: ['attributes']
  },
  {
    title: 'จัดการโต๊ะ',
    path: '/table',
    icon: icon('flaticon_setting_table'),
    resource: ['tables']
  },
  {
    title: 'จัดการผู้ใช้งาน',
    path: '/manage-user',
    icon: icon('flaticon_user'),
    resource: ['users']
  },
  // {
  //   title: 'จัดการรูปแบบ Qr ร้าน',
  //   path: '/setting-qrcode',
  //   icon: icon('ic_cart'),
  //   resource : ['generates']
  // },
  // {
  //   title: 'จัดการเรียกพนักงาน',
  //   path: '/manage-event',
  //   icon: icon('ic_cart'),
  //   resource : ['users']
  // },
  {
    title: 'จัดการร้านค้า',
    path: '/shop/setting',
    icon: icon('flaticon_shop'),
    resource: ['branches', "shops"]
  },
];

export default navConfig;
