import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Card, CardHeader, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
// xlsx
import * as XLSX from 'xlsx';
// html-to-image
import { toPng } from 'html-to-image';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 340;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppCurrentVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease 0s',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 15px 20px rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-3px)',
    },
  },
}));

export default function AppCurrentVisits({ title, subheader, chartColors, chartData, ...other }) {
  const theme = useTheme();
  const classes = useStyles();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md')); // Hook to detect screen size
  const cardRef = useRef(null);

  const formattedTitle = title; // Change to new line for each word if screen size is 'md' or smaller
  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);
  const [menuVisible, setMenuVisible] = useState(true);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDownloadXLSX = () => {
    const headers = [['ประเภท', 'ยอดขาย']];
    const data = chartLabels.map((label, index) => [label, chartSeries[index]]);
    const wsData = [...headers, ...data];

    const worksheet = XLSX.utils.aoa_to_sheet(wsData);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, title);
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };

  const handleMenuOpen = (event) => {
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX - 150,
    });

    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorPosition(null);
  };
  const handleDownloadPng = async () => {
    handleMenuClose();

    setMenuVisible(false);

    toPng(cardRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${title}.png`;
        link.click();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setMenuVisible(true);
      });
  };

  const chartOptions = useChart({
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName} `,
        },
      },
    },
    chart: {
      events: {
        mounted: (chartContext, config) => {
          const button = document.querySelector('.download-button');
          try {
            const chartContainer = config.el.parentNode;
            chartContainer.style.position = 'relative';
            chartContainer.appendChild(button);
          } catch (error) {
            // console.debug(error)
          }
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <>
      {' '}
      <Card {...other} ref={cardRef}>
        <CardHeader
          title={formattedTitle}
          subheader={subheader}
          action={
            <IconButton onClick={handleMenuOpen} style={{ visibility: menuVisible ? 'visible' : 'hidden' }}>
              <MenuRoundedIcon />
            </IconButton>
          }
        />
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
          open={Boolean(anchorPosition)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDownloadXLSX}>
            <Typography variant="body2">ดาวน์โหลด Excel</Typography>
          </MenuItem>
          <MenuItem onClick={handleDownloadPng}>
            <Typography variant="body2">ดาวน์โหลด PNG</Typography>
          </MenuItem>
        </Menu>
        {chartLabels.length > 0 ? (
          <StyledChartWrapper dir="ltr">
            <ReactApexChart type="pie" series={chartSeries} options={chartOptions} height={280} />
          </StyledChartWrapper>
        ) : (
          <Box
            sx={{
              p: 3,
              height: 379,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f2f2f2',
              border: '20px solid white', // Add this line
              borderRadius: '35px',
              borderColor: 'white', // Add this line
              flexDirection: 'column', // Add this line
            }}
          >
            <img src="/assets/not_found.png" alt="No data available" style={{ width: '9em' }} />
            <Typography variant="subtitle1" sx={{ opacity: 0.72 }}>
              ยังไม่พบข้อมูล{title}ในขณะนี้
            </Typography>
          </Box>
        )}
      </Card>{' '}
    </>
  );
}
