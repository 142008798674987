/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import { filter } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QRCodeCanvas } from 'qrcode.react';
import { useSnackbar } from 'notistack';
import '../services/qrcodesetting.css';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// @mui
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Grid,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import ManyTable from '../components/table/manytable';
import ModalTable from '../components/table/table';
// components

import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// ----------------------------------------------------------------------
import { listTable, deleteTable, displayOrderTable } from '../redux/actions/tableActions';
// import { listTheme, updateTheme } from '../redux/actions/themeActions';
import { frontendURL } from '../services/var';
import Reorderdata from '../components/reorder';
import Canvas from '../components/canvas/Canvas';

const TABLE_HEAD = [
  { id: 'name', label: 'ชื่อโต๊ะ', alignRight: false },
  { id: 'qrcode', label: 'คิวอาร์โค้ด', alignRight: false },
  { id: 'status', label: 'สถานะ', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (r) => r.name.th.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ListCategory() {
  const { enqueueSnackbar } = useSnackbar();
  const { table, auth } = useSelector((state) => state);
  const { theme } = useSelector((state) => state.theme);
  const { tables, loading } = table;
  const { branch } = auth;
  const themes = useTheme();
  const isSmall = useMediaQuery(themes.breakpoints.down('md'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name.th');
  const [filterName, setFilterName] = useState('');
  const [limit, setLimit] = useState(25);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmValue, setConfirmValue] = useState();
  const [modelOpen, setModelOpen] = useState(false);
  const [dataEdit, setDataEdit] = useState([]);
  const [themeDefault, setThemeDefault] = useState({});
  const [manyModelOpen, setManyModelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [selectTheme, setSelectTheme] = useState(false);
  const [downloadAll, setDownloadAll] = useState(false);
  const [openReorder, setOpenReorder] = useState(false);
  const [canvasSelected, setCanvasDownload] = useState(null);
  const [selectDownload, setSelectDownload] = useState(false)

  // useEffect(() => {
  //   setIsLoading(true);
  //   if (branch) {
  //  dispatch(listTheme());
  //  dispatch(myShop({}));
  //     dispatch(listTable());
  //   }
  //   setIsLoading(false);
  //   // .then(() => setIsLoading(false))
  //   // .catch(() => setIsLoading(false));
  // }, [branch, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    if (branch) {
      dispatch(listTable()).then(() => setIsLoading(false)).catch(() => setIsLoading(false));
    }
  }, [branch, dispatch]);

  useEffect(() => {
    if (theme.length !== 0) {
      const data = theme[0];
      if (Object.keys(themeDefault).length === 0) {
        setThemeDefault(data);
      }
    }
  }, [theme]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tables.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, value) => {
    const selectedIndex = selected.indexOf(value);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = tables.length === 0;
  const filteredTable = applySortFilter(tables, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredTable.length && !!filterName;

  const handleDelete = (value) => {
    setConfirmDelete(true);
    setConfirmValue([value]);
  };
  const handleConfirmDelete = () => {
    dispatch(deleteTable({ tables: confirmValue || selected }));
    setConfirmDelete(false);
    setSelected([]);
  };

  const handleConfirmDeleteAll = () => {
    setConfirmDelete(true);
    setConfirmValue();
  };

  const handleClose = () => {
    setDataEdit([]);
    setModelOpen(false);
  };
  const handleManyClose = () => {
    setDataEdit([]);
    setManyModelOpen(false);
  };

  const handleCreateNew = () => {
    setDataEdit([]);
    setModelOpen(true);
  };

  const handleCreateManyNew = () => {
    setDataEdit([]);
    setManyModelOpen(true);
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar('คัดลอกเรียบร้อย', {
      variant: 'success',
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 1000,
    });
  };

  const handleDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const event = {
      source: {
        ref: filteredTable[source.index]._id,
        index: source.index + 1,
      },
      destination: {
        ref: filteredTable[destination.index]._id,
        index: destination.index + 1,
      },
    };

    reorderData(source.index, destination.index);
    dispatch(displayOrderTable(event));
  };

  const reorderData = (startIndex, endIndex) => {
    const [removed] = filteredTable.splice(startIndex, 1);
    filteredTable.splice(endIndex, 0, removed);
    return filteredTable;
  };

  async function download(id, name, qrlink, dataTable) {
    setSelectTheme(true);
    setDownloadAll(false)
    setThemeDefault({ ...themeDefault, idItem: id, tableName: name, link: qrlink, fromTable: dataTable });
  }
  const handleDownloadTotalQR = async () => {
    setSelectTheme(true);
    setDownloadAll(true)
  };

  if (isLoading) {
    return (
      <>
        <Helmet>
          <title> EzOrder Backoffice | จัดการโต๊ะ </title>
        </Helmet>
        <Container>
          <Stack className={'group_header_filter'} direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              จัดการโต๊ะ
            </Typography>
            <div style={{ flex: 1 }}> </div>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => handleCreateNew()}>
              เพิ่มโต๊ะใหม่
            </Button>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleCreateManyNew()}
              style={{ marginLeft: '8px', marginRight: '8px' }}
            >
              เพิ่มหลายโต๊ะ
            </Button>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="center" style={{ height: 'calc(100vh - 50vh)' }}>
            <CircularProgress size={100} />
          </Stack>
        </Container>
      </>
    );
  }

  const downloadCanvas = async (e) => {
    e.preventDefault();
    setIsLoadingButton(true)
    if (downloadAll) {
      setSelectDownload(true)
    } else {
      setTimeout(() => {
        const dataUrl = canvasSelected.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${themeDefault?.fromTable.name.th}.png`;
        link.click();
        setIsLoadingButton(false)
        enqueueSnackbar('ดาวน์โหลดสำเร็จ', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          autoHideDuration: 1000,
        });
      }, 500);
    }
  }

  const submitDialog = async (data) => {
    const orderEvent = data.map((v, i) => {
      const info = {
        "ref": v._id,
        "index": i
      }
      return info
    })
    setIsLoading(true)
    await dispatch(displayOrderTable({ orderby: orderEvent }))
    dispatch(listTable()).then(() => setIsLoading(false)).catch(() => setIsLoading(false));
  }

  return (
    <>
      <Helmet>
        <title>EzOrder Backoffice | จัดการโต๊ะ </title>
      </Helmet>

      <Container>
        {/* {isLoadingZip && <Stack direction="row" alignItems="center" justifyContent="center" style={{ height: '100%', position: 'absolute', zIndex: 999999999, width: '100%', left: 0, top: 0 }}>
          <CircularProgress size={100} />
        </Stack>
        } */}

        <Stack className={'group_header_filter'} direction="row" alignItems="center" justifyContent="space-between" mb={5} >
          <Typography variant="h4" gutterBottom>
            จัดการโต๊ะ
          </Typography>
          <div style={{ flex: 1 }}> </div>
          {tables.length > 0 && <Button
            variant="contained"
            startIcon={<Iconify icon="teenyicons:drag-outline" />}
            onClick={() => setOpenReorder(true)}
            style={{ marginLeft: '8px', marginRight: '8px' }}
          >
            จัดเรียงรายการ
          </Button>}
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => handleCreateNew()}>
            เพิ่มโต๊ะใหม่
          </Button>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => handleCreateManyNew()}
            style={{ marginLeft: '8px', marginRight: '8px' }}
          >
            เพิ่มหลายโต๊ะ
          </Button>

          {tables.length > 0 && <Button
            variant="contained"
            onClick={(e) => handleDownloadTotalQR([])}
          >
            ดาวน์โหลดคิวอาร์โค้ดทั้งหมด
          </Button>
          }

        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onDelete={() => handleConfirmDeleteAll()}
          />
          <Reorderdata title={'จัดเรียงโต๊ะ'} open={openReorder} handleDragEnd={handleDragEnd} onClose={(data) => setOpenReorder(data)} itemsData={tables} handleSubmit={(data) => submitDialog(data)} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tables.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody >
                  {filteredTable?.slice(page * limit, page * limit + limit).map((row, index) => {
                    const { _id, name, qrcode, is_enable } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;
                    const qrcodeLink = qrcode.length > 0 ? `${frontendURL}/qrcode/${qrcode[0].code}` : '';
                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={selectedUser}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none" style={{ width: '9rem' }}>
                          <Typography variant="subtitle2">
                            <Link
                              underline="none"
                              onClick={() => {
                                setDataEdit({ ...row, qrcodeLink });
                                setModelOpen(true);
                              }}
                            >
                              {name.th}
                            </Link>
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          {qrcodeLink && <QRCodeCanvas size={64} includeMargin value={qrcodeLink} imageSettings={{
                            src: '/assets/illustrations/ezorder_login.png',
                            x: undefined,
                            y: undefined,
                            height: 10,
                            width: 10,
                            excavate: false,
                          }} />}
                        </TableCell>

                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Label color={(!is_enable && 'error') || 'success'}>
                              {(!is_enable && 'ปิดการแสดง') || 'แสดงปกติ'}
                            </Label>
                          </Stack>
                        </TableCell>

                        <TableCell align="right">
                          <Button
                            variant="text"
                            onClick={() => {
                              handleCopyText(qrcodeLink);
                            }}
                          >
                            คัดลอกลิ้ง
                          </Button>
                          <Button variant="text" onClick={() => download(`qrcode_${_id}`, `${name.th}${name.en && ` : ${name.en}`}`, qrcodeLink, row)}>
                            ดาวน์โหลดคิวอาร์โค้ด
                          </Button>
                          <IconButton color="error" onClick={() => handleDelete(_id)} component="label">
                            <Iconify icon={'eva:trash-2-outline'} sx={{ color: 'error.main' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>{/* <TableCell colSpan={6} /> */}</TableRow>
                  )}
                </TableBody>
                {isNotFound ||
                  (emptyRows && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <img
                              src="/assets/images/empty_handle/table_notfound.png"
                              alt="product not found"
                              width={'17%'}
                              style={{ filter: 'grayscale(100%)', opacity: 0.6 }}
                            />
                            <Typography variant="h6" paragraph sx={{ pt: 6, mb: 1 }}>
                              ไม่พบรายการโต๊ะ
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 0, opacity: 0.7 }}>
                              ยังไม่มีรายการโต๊ะที่เพิ่มเข้ามา
                            </Typography>
                            {isNotFound && (
                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            )}
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                {/* {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={tables.length}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {modelOpen && (
          <ModalTable
            totalTables={tables.length}
            openModel={modelOpen}
            handleClose={() => handleClose()}
            props={dataEdit}
          />
        )}
        {manyModelOpen && (
          <ManyTable
            totalTables={tables.length}
            openModel={manyModelOpen}
            handleClose={() => handleManyClose()}
            props={dataEdit}
          />
        )}
      </Container>

      <Dialog
        open={selectTheme}
        onClose={() => {
          setThemeDefault({})
          setDownloadAll(false)
          setCanvasDownload(null)
          setSelectTheme(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={isSmall}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container direction="row" justifyContent={'center'} alignItems="center">
            <Box>เลือกรูปแบบการดาวน์โหลด</Box>
            <IconButton aria-label="close" onClick={(e) => {
              setThemeDefault({})
              setDownloadAll(false)
              setCanvasDownload(null)
              setSelectTheme(false)
            }} style={{ position: 'absolute', right: 15 }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Canvas isLoading={isLoadingButton} setIsLoading={setIsLoadingButton} selectDownload={selectDownload} setSelectDownload={setSelectDownload} downloadAll={downloadAll} setDownloadAll={setDownloadAll} data={themeDefault?.idItem ? themeDefault : {}} setCanvasDownload={setCanvasDownload} />
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => {
            setThemeDefault({})
            setDownloadAll(false)
            setCanvasDownload(null)
            setSelectTheme(false)
          }}>ปิด</Button>
          <LoadingButton variant="contained" loading={isLoadingButton} onClick={(e) => downloadCanvas(e)}>ดาวน์โหลด</LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(!confirmDelete)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'แจ้งเตือนระบบ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">คุณต้องการที่จะลบจัดการโต๊ะนี้หรือไม่?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(!confirmDelete)}>ยกเลิก</Button>
          <Button onClick={() => handleConfirmDelete()} autoFocus>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
