import { createSlice } from '@reduxjs/toolkit'
import { overview, summarySale, summarySaleWithProduct, summarySaleWithCategory } from '../actions/reportActions'

const initialState = {
  loading: false,
  dashboard: [],
  results: [],
  totalRows: 0,
  error: null,
  success: false,
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(overview.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(overview.fulfilled, (state, { payload }) => {
        state.loading = false
        state.dashboard = payload.data
      })
      .addCase(overview.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(summarySale.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(summarySale.fulfilled, (state, { payload }) => {
        state.loading = false
        state.results = payload.data
        state.totalRows = payload.total ?? 0
      })
      .addCase(summarySale.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      
      .addCase(summarySaleWithProduct.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(summarySaleWithProduct.fulfilled, (state, { payload }) => {
        state.loading = false
        state.results = payload.data
        state.totalRows = payload.total ?? 0
      })
      .addCase(summarySaleWithProduct.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(summarySaleWithCategory.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(summarySaleWithCategory.fulfilled, (state, { payload }) => {
        state.loading = false
        state.results = payload.data
        state.totalRows = payload.total ?? 0
      })
      .addCase(summarySaleWithCategory.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })

  },
})

export default reportSlice.reducer