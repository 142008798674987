import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import _ from 'lodash';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Container,
  Typography,
  Grid,
  TextField,
  FormControl,
  CardHeader,
  Button,
  Stack,
  CardContent,
} from '@mui/material';

import { createUser } from '../../redux/actions/userActions';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('ชื่อจำเป็นต้องกรอก')
    .min(1, 'โปรดระบุชื่อผู้ใช้ (1-20 ตัวอักษร)')
    .max(20, 'ชื่อผู้ใช้เกินจำนวนที่สามารถรองรับ'),
  last_name: yup.string()
    .required('นามสกุลจำเป็นต้องกรอก')
    .min(1, 'โปรดระบุนามสกุลผู้ใช้ (1-20 ตัวอักษร)')
    .max(20, 'นามสกุลผู้ใช้เกินจำนวนที่สามารถรองรับ'),
  u_email: yup
    .string()
    .email('อีเมลไม่ถูกต้อง')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'ที่อยู่อีเมลผิด')
    .required('อีเมลจำเป็นต้องกรอก'),
  a_password: yup
    .string()
    .required('รหัสผ่านจำเป็นต้องกรอก')
    .min(6, 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร')
    .max(12, 'รหัสผ่านต้องมีไม่เกิน 12 ตัวอักษร'),
  b_password: yup
    .string()
    .required('ยืนยันรหัสผ่านจำเป็นต้องกรอก')
    .oneOf([yup.ref("a_password"), null], "รหัสผ่านไม่ตรงกัน")
});

export default function Create() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.user);

  const formik = useFormik({
    initialValues: {
      name: '',
      last_name: '',
      u_email: '',
      a_password: '',
      b_password: ''
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const data = {
          name: values.name,
          last_name: values.last_name,
          email: values.u_email,
          password: values.a_password,
        };

        dispatch(createUser({ ...data }));
        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar('โปรดตรวจสอบอีกครั้ง', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        enqueueSnackbar('เพิ่มผู้ใช้เรียบร้อย', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
        navigate('/manage-user');
      } catch (error) {
        enqueueSnackbar('โปรดตรวจสอบอีกครั้ง', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
      }
    },
  });

  const handleBack = () => {
    navigate('/manage-user');
  };

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | เพิ่มผู้ใช้งาน </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">เพิ่มผู้ใช้งาน</Typography>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Card>
            <CardHeader title={'ข้อมูลทั่วไป'} />
            <CardContent>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="ชื่อ"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="last_name"
                    name="last_name"
                    label="นามสกุล"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="u_email"
                    name="u_email"
                    label="อีเมล"
                    value={formik.values.u_email}
                    onChange={formik.handleChange}
                    error={formik.touched.u_email && Boolean(formik.errors.u_email)}
                    helperText={formik.touched.u_email && formik.errors.u_email}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="role-label">ตำแหน่ง</InputLabel>
                    <Select
                      labelId="role-label"
                      id="role"
                      name="role"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                      label="Role"
                    >
                      <MenuItem value={'Staff'}>พนักงาน</MenuItem>
                      <MenuItem value={'Owner'}>เจ้าของร้าน</MenuItem>
                    </Select> */}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="a_password"
                    name="a_password"
                    label="รหัสผ่าน"
                    type="password"
                    value={formik.values.a_password}
                    onChange={formik.handleChange}
                    error={formik.touched.a_password && Boolean(formik.errors.a_password)}
                    helperText={formik.touched.a_password && formik.errors.a_password}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="b_password"
                    name="b_password"
                    label="ยืนยันรหัสผ่าน"
                    type="password"
                    value={formik.values.b_password}
                    onChange={formik.handleChange}
                    error={formik.touched.b_password && Boolean(formik.errors.b_password)}
                    helperText={formik.touched.b_password && formik.errors.b_password}
                  />
                </Grid>
              </Grid>

            </CardContent>
          </Card>
          <Grid container justifyContent="flex-end" style={{ marginTop: '20px' }}>
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" spacing={1}>
              <Button variant="outlined" onClick={() => handleBack()}>
                ยกเลิก
              </Button>

              <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                บันทึก
              </LoadingButton>
            </Stack>
          </Grid>
        </form>
      </Container>
    </>
  );
}
