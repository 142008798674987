import { createSlice } from '@reduxjs/toolkit'
import { listPackage, createPackage, detailPackage, updatePackage, deletePackage, displayOrderPackage } from '../actions/packageActions'

const initialState = {
  loading: false,
  packages: [],
  packageState: {},
  total: null,
  totalPage: null,
  error: null,
  success: false,
}
const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    setPackages: (state, action) => {
      const { payload } = action.payload
      state.packages = payload.data || []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listPackage.pending, (state) => {
        state.loading = true
        state.error = null
        state.packages = []
      })
      .addCase(listPackage.fulfilled, (state, { payload }) => {
        state.loading = false
        state.packages = payload.data
        state.total = payload.total
        state.totalPage = payload.total_page
      })
      .addCase(listPackage.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.packages = []
      })
      .addCase(detailPackage.pending, (state) => {
        state.loading = true
        state.error = null
        state.packageState = {}
      })
      .addCase(detailPackage.fulfilled, (state, { payload }) => {
        state.loading = false
        state.packageState = payload.data
      })
      .addCase(detailPackage.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.packageState = {}
      })
      .addCase(createPackage.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(createPackage.fulfilled, (state, { payload }) => {
        state.loading = false
        state.packages = payload.data
        state.success = true
      })
      .addCase(createPackage.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(updatePackage.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updatePackage.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(updatePackage.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(deletePackage.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(deletePackage.fulfilled, (state, { payload }) => {
        state.loading = false
        state.packages = payload.data
        state.success = false
      })
      .addCase(deletePackage.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(displayOrderPackage.pending, (state) => {
        // state.loading = true
        // state.error = null
        state.success = false
      })
      .addCase(displayOrderPackage.fulfilled, (state) => {
        // state.loading = false
        // state.packages = payload.data
        state.success = false
      })
      .addCase(displayOrderPackage.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })

  },
})
export const { setPackages } = packageSlice.actions
export default packageSlice.reducer