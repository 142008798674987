/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// ----------------------------------------------------------------------
import { listUser, deleteUser, listUserRole } from '../redux/actions/userActions';

import ModalUser from '../components/modal/user';
// eslint-disable-next-line import/no-unresolved
import ModalChangePassword from '../components/modal/changePassword';


const TABLE_HEAD = [
  { id: 'name', label: 'ชื่อ', alignRight: false },
  { id: 'email', label: 'อีเมล', alignRight: false },
  { id: 'role', label: 'ตำแหน่ง', alignRight: false },
  // { id: 'updated_at', label: 'อัพเดตล่าสุด', alignRight: false },
  { id: 'status', label: 'สถานะ', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (r) =>
        r.name.th.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (r.name.en && r.name.en?.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ListUser() {
  const dispatch = useDispatch();
  const { user, auth } = useSelector((state) => state);
  const { users, loading } = user;
  const { branch } = auth;

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');

  const [modelCreate, setModelCreate] = useState(false);

  const [modelChangePassword, setModelChangePassword] = useState(false);

  const [dataEdit, setDataEdit] = useState({});

  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name.th');
  const [filterName, setFilterName] = useState('');
  const [limit, setLimit] = useState(25);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmValue, setConfirmValue] = useState();

  const emptyRows = users.length === 0;
  const filteredUser = applySortFilter(users, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUser.length && !!filterName;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    if (!loading && branch)
      dispatch(listUser())
        .then(() => {
          setIsLoading(false)
          dispatch(listUserRole()).catch(() => { });
        })
        .catch(() => setIsLoading(false));
  }, [branch, dispatch]);


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDelete = (value) => {
    setConfirmDelete(true);
    setConfirmValue([value]);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteUser({ users: confirmValue || selected }));
    setConfirmDelete(false);
    setSelected([]);
  };

  const handleConfirmDeleteAll = () => {
    setConfirmDelete(true);
    setConfirmValue();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleClick = (event, value) => {
    const selectedIndex = selected.indexOf(value);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleCreateNew = () => {
    setDataEdit({});
    setModelCreate(true);
  };

  const handleClose = () => {
    setDataEdit({});
    setModelCreate(false);
  };


  const handleCloseChange = () => {
    setModelChangePassword(false);
  };

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | จัดการผู้ใช้งาน </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            จัดการผู้ใช้งาน
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => handleCreateNew()}
          >
            เพิ่มผู้ใช้งาน
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} onDelete={() => handleConfirmDeleteAll()} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody >
                  {users.slice(page * limit, page * limit + limit).map((row, index) => {
                    const {
                      _id,
                      email,
                      roles,
                      first_name,
                      is_enable,
                      last_name,
                    } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;
                    const strRoles = roles.map((role) => role.name).join(', ')

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={selectedUser}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedUser}
                            onChange={(event) => { handleClick(event, _id) }}
                          />
                        </TableCell>

                        <TableCell scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>

                            <Link
                              underline="hover"
                              onClick={() => {
                                setDataEdit(row);
                                setModelCreate(true);
                              }}
                            >
                              {first_name} {last_name}
                            </Link>
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {email}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{strRoles}</TableCell>


                        {/* <TableCell align="left">{updated_at}</TableCell> */}

                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Label color={(!is_enable && 'error') || 'success'}>
                              {(!is_enable && 'ปิดใช้งาน') || 'เปิดใช้งาน'}
                            </Label>
                          </Stack>
                        </TableCell>

                        <TableCell align="right">
                          <Button variant="text" onClick={() => {
                            setDataEdit(row);
                            setModelChangePassword(true);
                          }} >
                            เปลี่ยนรหัสผ่าน
                          </Button>
                          <IconButton color="error" onClick={() => handleDelete(_id)} component="label">
                            <Iconify icon={'eva:trash-2-outline'} sx={{ color: 'error.main' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={users.length}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        {modelCreate && <ModalUser openModel={modelCreate} handleClose={() => handleClose()} props={dataEdit} />}
        {modelChangePassword && <ModalChangePassword openModel={modelChangePassword} handleClose={() => handleCloseChange()} props={dataEdit} />}
      </Container>

      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(!confirmDelete)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'แจ้งเตือนระบบ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">คุณต้องการที่จะลบผู้ใช้นี้หรือไม่?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(!confirmDelete)}>ยกเลิก</Button>
          <Button onClick={() => handleConfirmDelete()} autoFocus>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
