import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseURL } from '../../services/var'
import { userRefresh } from './authActions'

export const listMasterCategory = createAsyncThunk('master/category',
    async (arg, { getState, rejectWithValue, dispatch }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/category/select`, config)
            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const listCategory = createAsyncThunk('management/category',
    async (arg, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/category`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const createCategory = createAsyncThunk('management/createCategory',
    async (event, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/category`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const detailCategory = createAsyncThunk('management/detailCategory',
    async ({ categoryId }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/category/${categoryId}`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const updateCategory = createAsyncThunk('management/updateCategory',
    async ({ categoryId, event }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.put(`${baseURL}/v1/category/${categoryId}`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const deleteCategory = createAsyncThunk('management/deleteCategory',
    async (event, { dispatch, getState, rejectWithValue }) => {
        try {
           
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/category/delete`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const displayOrderCategory = createAsyncThunk('management/displayOrderCategory',
    async (event, { dispatch, getState, rejectWithValue }) => {
        try {

            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/category/display`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)




