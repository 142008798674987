import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  Grid,
  Box,
  Button,
  FormControl,
  TextField,
  Paper,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  Divider,
  Switch,
  FormControlLabel,
  ListItem,
  Stack,
  ListItemText,
  ButtonGroup,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import ModalOption from './option';
// ----------------------------------------------------------------------
import { setOptions } from '../../redux/features/optionSlice';
import { listOption, displayOrderOption } from '../../redux/actions/optionActions';
import { createAttribute, updateAttribute } from '../../redux/actions/attributeActions';

import Iconify from '../iconify';

// ----------------------------------------------------------------------
const validationSchema = yup.object({
  name: yup.object().shape({
    th: yup
      .string()
      .min(2, 'โปรดระบุชื่อประเภท (2-50 ตัวอักษร)')
      .max(50, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ')
      .required('โปรดระบุชื่อประเภท'),
    en: yup.string().min(2, 'โปรดระบุชื่อประเภท (2-50 ตัวอักษร)').max(50, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ'),
  }),
  choice_min: yup
    .number()
    .test('check-chice', 'โปรดระบุช้อยส์', function (value) {
      // const currentOptions = Number(this.parent.options.length || 0);
      return this.parent.options.length > 0;
    })
    .test('check-min', 'ช้อยส์ควรมีมากกว่าจำนวนขั้นต่ำ', function (value) {
      // const currentOptions = Number(this.parent.options.length || 0);
      if (value > 0) {
        return value <= this.parent.options.length;
      }
      return true;
    }),
});


export default function ModalAttribute({ openModel, handleClose, props = {} }) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { branch } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.attribute);
  const { options } = useSelector((state) => state.option);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name.th');
  const [filterName, setFilterName] = useState('');
  const [option, setOption] = useState([]);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: { th: '' },
      options: [],
      choice_min: 0,
      choice_limit: null,
      is_enable: true,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const newOptions = _.map(values.options, '_id');
        const event = {
          name: values.name,
          options: newOptions,
          choice_min: values.choice_min,
          choice_limit: values.choice_limit,
          is_enable: values.is_enable,
        };
        if (props?._id) {
          await dispatch(updateAttribute({ attributeId: props?._id, event: { ...event } }));
        } else {
          dispatch(createAttribute({ ...event }));
        }

        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar('เกิดข้อผิดพลาด!', { variant: 'error' });
        }
        enqueueSnackbar('บันทึกข้อมูลเรียบร้อย!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        // setSubmitting(false)
        handleClose();
      } catch (error) {
        enqueueSnackbar('เกิดข้อผิดพลาด!', { variant: 'error' });
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    // if (branch) dispatch(listOption());
  }, [branch, dispatch]);

  useEffect(() => {
    if (props?.name) {
      formik.setFieldValue('name.th', props.name?.th || '');
      formik.setFieldValue('name.en', props.name?.en || '');
      formik.setFieldValue('options', props.options);
      formik.setFieldValue('choice_min', props.choice_min);
      formik.setFieldValue('choice_limit', props.choice_limit);
      if (props.name?.en) setExpanded('panel1');
    }
  }, [props]);

  // console.log(options)
  const [expanded, setExpanded] = useState();

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [openOption, setOpenOption] = useState(false);

  const handleAdd = (type) => {
    if (type === 'min') {
      const current = formik.values.choice_min;
      formik.setFieldValue('choice_min', current + 1);
    } else {
      const current = formik.values.choice_limit;
      formik.setFieldValue('choice_limit', current + 1);
    }
  };

  const handleRemove = (type) => {
    if (type === 'min') {
      const current = formik.values.choice_min;
      if (current === 1) return;
      formik.setFieldValue('choice_min', current - 1);
    } else {
      const current = formik.values.choice_limit;
      if (current === 1) return;
      formik.setFieldValue('choice_limit', current - 1);
    }
  };

  const handleCloseOption = (value) => {
    if (value?._id) {
      try {
        const newArray = [...formik.values.options, value];
        formik.setFieldValue('options', newArray);
      } catch (error) {
        console.log(error);
      }
    }
    setOpenOption(false);
    setOption([]);
  };
  const handleOpenOption = (value) => {
    setOption(value);
    setOpenOption(true);
  };

  const handleClear = (value) => {
    try {
      const findOption = formik.values.options.filter((o) => o._id !== value._id);
      const newArray = [...findOption];
      formik.setFieldValue('options', newArray);
    } catch (error) {
      console.log(error);
    }
  };

  // const filteredOption = applySortFilter(formik.values.options, getComparator(order, orderBy), filterName);

  const handleDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const event = {
      source: {
        ref: formik.values.options[source.index]._id,
        index: source.index + 1,
      },
      destination: {
        ref: formik.values.options[destination.index]._id,
        index: destination.index + 1,
      },
    };
    // await dispatch(displayOrderProduct(event));
    // console.log(result, filteredProduct[source.index], filteredProduct[destination.index])
    
    dispatch(displayOrderOption(event));
    reorderData(source.index, destination.index);
  };

  const reorderData = (startIndex, endIndex) => {
    const options =  formik.values.options.filter(() => true);
    const [removed] = options.splice(startIndex, 1);
    options.splice(endIndex, 0, removed);
    const newArray = [...options];
    return formik.setFieldValue('options', newArray);
  };
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openModel}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {props?.name?.th ? 'แก้ไขกลุ่มตัวเลือก' : 'สร้างกลุ่มตัวเลือก'}
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    pt: 1,
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      id="name.th"
                      name="name.th"
                      label="ชื่อกลุ่มตัวเลือก *"
                      type="text"
                      value={formik.values.name?.th}
                      onChange={formik.handleChange}
                      error={formik.touched.name?.th && Boolean(formik.errors.name?.th)}
                      helperText={formik.touched.name?.th && formik.errors.name?.th}
                    />
                  </FormControl>
                </Box>
                <Accordion disableGutters expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1a-header"
                    sx={{ pt: 0, pr: 0, pl: 0, pb: 1 }}
                  >
                    ชื่อกลุ่มตัวเลือก (อังกฤษ)
                  </AccordionSummary>
                  <AccordionDetails sx={{ pt: 0, pr: 0, pl: 0, pb: 1 }}>
                    <Box>
                      <FormControl fullWidth>
                        <TextField
                          id="name.en"
                          name="name.en"
                          label="ชื่อกลุ่มตัวเลือก (อังกฤษ)"
                          type="text"
                          value={formik.values.name?.en}
                          onChange={formik.handleChange}
                          error={formik.touched.name?.en && Boolean(formik.errors.name?.en)}
                          helperText={formik.touched.name?.en && formik.errors.name?.en}
                        />
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Box dir="ltr">
                  <FormControlLabel
                    control={
                      <Switch
                        name="is_choice_min"
                        checked={formik.values.choice_min > 0}
                        onChange={() => formik.setFieldValue('choice_min', formik.values.choice_min > 0 ? 0 : 1)}
                      />
                    }
                    label="จำเป็นต้องเลือกหรือไม่ ?"
                  />
                </Box>
                <Box dir="ltr">
                  <FormControlLabel
                    control={
                      <Switch
                        name="is_choice_limit"
                        checked={formik.values.choice_limit !== null}
                        onChange={() => formik.setFieldValue('choice_limit', formik.values.choice_limit ? null : 1)}
                      />
                    }
                    label="สามารถเลือกได้มากกว่า 1 ช้อยส์ ?"
                  />
                </Box>
                {formik.values.choice_min > 0 && (
                  <Box sx={{ mt: 1 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                      ต้องเลือกอย่างน้อย
                      <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button onClick={() => handleRemove('min')}>-</Button>
                        <Button>{formik.values.choice_min}</Button>
                        <Button onClick={() => handleAdd('min')}>+</Button>
                      </ButtonGroup>
                    </Stack>
                  </Box>
                )}

                {formik.values.choice_limit && (
                  <Box sx={{ mt: 1 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                      สามารถเลือกได้สูงสุด
                      <ButtonGroup variant="outlined" aria-label="outlined button group">
                        <Button onClick={() => handleRemove('limit')}>-</Button>
                        <Button>{formik.values.choice_limit}</Button>
                        <Button onClick={() => handleAdd('limit')}>+</Button>
                      </ButtonGroup>
                    </Stack>
                  </Box>
                )}

                <Divider sx={{ my: 2 }} />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Box sx={{ color: 'red' }}>
                  <Typography variant="caption">{formik.touched.choice_min && formik.errors.choice_min}</Typography>
                </Box>
                {formik.values.options.length > 0 && (
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table-body">
                      {(provided, snapshot) => (
                        <List
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            width: '100%',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 250,
                            '& ul': { padding: 0 },
                          }}
                        >
                          {formik.values.options.map((value, index) => {
                            const masterOption = options.find((o) => o._id === value._id);
                            let showOptionName = value?.name?.th;
                            if (value?.name?.en) {
                              showOptionName += ' / ';
                              showOptionName += value.name?.en;
                            }

                            return (
                              <Draggable key={value._id} draggableId={value._id} index={index}>
                                {(provided) => (
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={index}
                                    sx={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      '& > :not(style)': {
                                        mb: 1,
                                        mr: 1,
                                        width: '100%',
                                      },
                                    }}
                                  >
                                    <Paper elevation={3} variant="outlined">
                                      <ListItem
                                        secondaryAction={
                                          <Box spacing={2}>
                                            <IconButton
                                              edge="end"
                                              aria-label="edit"
                                              onClick={() => handleOpenOption(masterOption)}
                                            >
                                              <Iconify icon={'eva:edit-fill'} />
                                            </IconButton>
                                            <IconButton
                                              edge="end"
                                              aria-label="clear"
                                              onClick={() => handleClear(masterOption)}
                                            >
                                              <Iconify icon={'ic:baseline-clear'} />
                                            </IconButton>
                                          </Box>
                                        }
                                        disablePadding
                                      >
                                        <ListItem role={undefined} dense>
                                          <ListItemText
                                            primary={showOptionName}
                                            secondary={<>{masterOption?.price} บาท</>}
                                          />
                                        </ListItem>
                                      </ListItem>
                                    </Paper>
                                  </Box>
                                )}
                              </Draggable>
                            );
                          })}
                        </List>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}

                <Box sx={{ pt: 1 }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    size="medium"
                    startIcon={<AddCircleOutlineRoundedIcon />}
                    onClick={() => handleOpenOption([])}
                  >
                    เพิ่มช้อยส์
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()}>ยกเลิก</Button>
            <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
              บันทึก
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>

      {openOption && <ModalOption openModel={openOption} handleClose={handleCloseOption} props={option} />}
    </>
  );
}
