import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
// @mui
import { Paper, Avatar, Container, Typography, Box, Grid, Button, Stack } from '@mui/material';

// redux
import { listBranch } from '../redux/actions/branchActions';

// components
import Iconify from '../components/iconify';
// service

export default function BranchPage() {
  const { branch, auth } = useSelector((state) => state);
  const { branches, loading } = branch;
  const branchId = auth.branch;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  useEffect(() => {
    if (branchId) dispatch(listBranch());
  }, [branchId, dispatch]);

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | ข้อมูลร้าน </title>
      </Helmet>

      <Container>
        {!loading && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                เลือกสาขาของคุณ
              </Typography>
              <Button
                variant="contained"
                disabled
                startIcon={<Iconify icon="eva:plus-fill" />}
                // onClick={() => handleCreateNew()}
              >
                เพิ่มสาขาใหม่
              </Button>
            </Stack>

            <Grid container spacing={2}>
              {branches &&
                branches.map((itemBranch, i) => (
                  <Grid item xs={12} md={4} key={i}>
                    <Paper
                      dp="1"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 5,
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        sx={{
                          mb: 2,
                          border: '1px dashed rgba(145, 158, 171, 0.32)',
                          borderRadius: '50%',
                          width: '144px',
                          height: '144px',
                          display: 'flex',
                          alignItems: 'center',
                          p: 5,
                          justifyContent: 'center',
                        }}
                      >
                        <Avatar
                          sx={{ width: '126px', height: '126px', fontSize: '14px' }}
                          alt="สาขาใหม่"
                          src={itemBranch?.logo_image}
                        >
                          เพิ่มสาขา
                        </Avatar>
                      </Box>
                      <Typography variant="h5" fontSize={12} textAlign={'center'}>
                        {itemBranch?.name?.th} {itemBranch?.name?.en ? `/ ${itemBranch?.name?.en}` : ''}
                      </Typography>
                      <Typography variant="subtitle1" fontSize={12} textAlign={'center'}>
                        {itemBranch?.description?.th}{' '}
                        {itemBranch?.description?.en ? `/ ${itemBranch?.description?.en}` : ''}
                      </Typography>
                      <Box sx={{ pt: 2, width: '100%' }}>
                        <Button variant="contained" fullWidth onClick={() => navigate(`/branch/${itemBranch._id}`)}>
                          เลือก
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Container>
    </>
  );
}
