import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

// @mui
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  CardHeader,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  Paper,
  Avatar,
  Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import PhoneIcon from '@mui/icons-material/Phone';
// redux
import { updateBranch, detailBranch } from '../../redux/actions/branchActions';
// components
// service
import { FileUpload } from '../../services/ImageUploader';
import bank from '../../_mock/bank';

const validationSchema = yup.object({
  name: yup.object().shape({
    th: yup
      .string()
      .min(2, 'โปรดระบุสาขา (2-100 ตัวอักษร)')
      .max(100, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ')
      .required('โปรดระบุชื่อสาขา'),
    en: yup.string().min(2, 'โปรดระบุชื่อสาขา (2-100 ตัวอักษร)').max(100, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ'),
  }),
  address: yup.string().max(300, 'รายละเอียดที่อยู่เกินจำนวนที่สามารถรองรับได้'),
  phone_number: yup.string().min(9, 'โปรดกรอกข้อมูลให้ถูกต้อง').max(10, 'เบอร์ติดต่อเกินจำนวนที่สามารถรองรับได้'),
  opening_hours: yup.string().min(7, 'โปรดระบุเวลาเปิดร้านให้ถูกต้อง'),
  closing_time: yup.string().min(7, 'โปรดระบุเวลาปืดร้านให้ถูกต้อง'),
  system_configs: yup.object().shape({
    vat_rate: yup
      .number()
      .typeError('กรุณาระบบุเป็นจำนวนตัวเลขเท่านั้น')
      .min(0, 'โปรดกรอกภาษีมูลค่าเพิ่ม')
      .max(100, 'ภาษีมูลค่าเพิ่มระบุได้ไม่เกิน 100%'),
    service_charge_rate: yup
      .number()
      .typeError('กรุณาระบบุเป็นจำนวนตัวเลขเท่านั้น')
      .min(0, 'โปรดกรอกค่าบริการ')
      .max(100, 'ค่าบริการ ระบุได้ไม่เกิน 100%'),
  }),
  payment_configs: yup.object().shape({
    account_number: yup
      .string()
      .typeError('กรุณาระบบุเป็นจำนวนตัวเลขเท่านั้น')
      .min(10, 'โปรดเลขบัญชีหรือเลขพร้อยเพย์')
      .max(30, 'เลขบัญชีหรือเลขพร้อยเพย์ เกินจำนวนที่สามารถกรอกได้')
      .required('โปรดระบุเลขบัญชีหรือเลขพร้อยเพย์'),
    account_name: yup
      .string()
      .min(6, 'โปรดกรอกชื่อบัญชีหรือชื่อพร้อมเพย์ให้ถูกต้อง')
      .max(100, 'ชื่อบัญชีหรือชื่อพร้อมเพย์ เกินจำนวนที่สามารถกรอกได้')
      .required('โปรดระบุชื่อบัญชีหรือชื่อพร้อมเพย์'),
  }),
});

export default function Edit() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { branch, accessToken } = useSelector((state) => state.auth);
  const { branchState, error, loading } = useSelector((state) => state.branch);
  const [file, setFile] = useState();
  const [vatRate, setVatRate] = useState();
  const [serviceCharge, setServiceCharge] = useState();

  useEffect(() => {
    if (branch && id) dispatch(detailBranch({ branchId: id }));
  }, [id, branch, dispatch]);

  const formik = useFormik({
    initialValues: {
      name: { th: '' },
      address: '',
      logo_image: '',
      phone_number: '',
      display_opening_hours: '',
      opening_hours: '',
      display_closing_time: '',
      closing_time: '',
      system_configs: {
        is_vat_enable: false,
        is_vat_exclude: false,
        vat_rate: 0,
        is_service_charge_enable: false,
        service_charge_rate: 0,
      },
      payment_configs: {
        bank_type: 'BANK',
        bank_name: '',
        account_name: '',
        account_number: '',
      },
      current_bank: null,
      vat_type: 'none',
      event_configs: [],
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let image = {};
        if (file) {
          const fileUpload = await FileUpload(accessToken, branch, 'B', file);
          image = { logo_image: fileUpload.url };
        } else if (values.image_url) {
          image = { logo_image: values.image_url };
        }

        const data = {
          name: values.name,
          address: values.address,
          phone_number: values.phone_number,
          opening_hours: values.opening_hours,
          closing_time: values.closing_time,
          system_configs: values.system_configs,
          payment_configs: values.payment_configs,
          ...image,
        };

        await dispatch(updateBranch({ branchId: id, event: { ...data } }));
        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar(error, { variant: 'error' });
          return;
        }
        enqueueSnackbar('แก้ไขข้อมูลเรียบร้อย', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        // setSubmitting(false)
        // navigate("/category")
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (branchState) {
      formik.setFieldValue('name.th', branchState.name?.th || '');
      formik.setFieldValue('name.en', branchState.name?.en || '');
      formik.setFieldValue('phone_number', branchState.phone_number || '');
      formik.setFieldValue('address', branchState.address || '');
      formik.setFieldValue('logo_image', branchState.logo_image || null);
      formik.setFieldValue(
        'system_configs',
        branchState.system_configs || {
          is_vat_enable: false,
          is_vat_exclude: false,
          vat_rate: 0,
          is_service_charge_enable: false,
          service_charge_rate: 0,
        }
      );

      if (Array.isArray(branchState.payment_configs)) {
        formik.setFieldValue('payment_configs', {
          bank_type: 'PROMPTPAY',
          bank_name: '',
          account_name: '',
          account_number: '',
        });
      } else {
        formik.setFieldValue(
          'payment_configs',
          branchState.payment_configs || {
            bank_type: 'PROMPTPAY',
            bank_name: '',
            account_name: '',
            account_number: '',
          }
        );
      }

      const currentBank = bank.find((b) => b.official_name_thai === branchState.payment_configs?.bank_name);
      formik.setFieldValue('current_bank', currentBank || null);

      if (branchState.opening_hours) {
        formik.setFieldValue('display_opening_hours', dayjs(`2000-01-01 ${branchState.opening_hours}`));
        formik.setFieldValue('opening_hours', branchState.opening_hours || '');
      }

      if (branchState.closing_time) {
        formik.setFieldValue('display_closing_time', dayjs(`2000-01-01 ${branchState.closing_time}`));
        formik.setFieldValue('closing_time', branchState.closing_time || '');
      }

      if (branchState.system_configs?.is_vat_enable) {
        formik.setFieldValue('vat_type', branchState.system_configs.is_vat_exclude === true ? 'exclude' : 'include');
      } else {
        formik.setFieldValue('vat_type', 'none');
      }
    }
    if (branchState.system_configs) {
      setVatRate(branchState.system_configs.vat_rate);
      setServiceCharge(branchState.system_configs.service_charge_rate);
    }
  }, [branchState]);

  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFile(e.target.files[0]);
      setImagePreviewUrl(reader.result);
      formik.setFieldValue('logo_image', reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleChangeTime = (type, value) => {
    const cTime = dayjs(value).format('HH:mm:00');
    if (cTime !== 'Invalid Date') {
      if (type === 1) {
        formik.setFieldValue('display_opening_hours', value);
        formik.setFieldValue('opening_hours', cTime);
      } else {
        formik.setFieldValue('display_closing_time', value);
        formik.setFieldValue('closing_time', cTime);
      }
    }
  };

  const handleChangeVatType = (value) => {
    if (value === 'none') {
      // formik.setFieldValue('system_configs.vat_rate', vatRate || formik.values.system_configs.vat_rate);
      formik.setFieldValue('system_configs.vat_rate', vatRate);
      formik.setFieldValue('system_configs.is_vat_enable', false);
    } else {
      formik.setFieldValue('system_configs.is_vat_enable', true);
      if (value === 'include') formik.setFieldValue('system_configs.is_vat_exclude', false);
      else formik.setFieldValue('system_configs.is_vat_exclude', true);
    }
    formik.setFieldValue('vat_type', value);
  };

  const handleChangeServiceCharge = (value) => {
    if (value === 'false') {
      formik.setFieldValue('system_configs.service_charge_rate', serviceCharge);
    }
    formik.setFieldValue('system_configs.is_service_charge_enable', value === 'true');
  };

  const handleBankType = (value) => {
    formik.setFieldValue('payment_configs.bank_type', value);

    if (value !== 'BANK') {
      formik.setFieldValue('payment_configs.bank_name', 'PROMPTPAY');
    }
  };

  const handleChangeBankName = (e, value) => {
    formik.setFieldValue('payment_configs.bank_name', value.official_name_thai);
    formik.setFieldValue('current_bank', value);
  };

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | แก้ไขประเภท </title>
      </Helmet>

      <Container>
        {!loading && branchState && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                จัดการสาขา
              </Typography>
            </Stack>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Paper
                    elevation="1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: 5,
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      sx={{
                        mb: 2,
                        border: '1px dashed rgba(145, 158, 171, 0.32)',
                        borderRadius: '50%',
                        width: '144px',
                        height: '144px',
                        display: 'flex',
                        alignItems: 'center',
                        p: 5,
                        justifyContent: 'center',
                      }}
                    >
                      <Avatar
                        sx={{ width: '126px', height: '126px', fontSize: '14px' }}
                        onClick={handleUploadClick}
                        alt="รูปภาพสาขา"
                        src={formik.values?.logo_image}
                      >
                        ภาพสาขา
                      </Avatar>
                    </Box>
                    <Typography variant="body2" fontSize={12} textAlign={'center'}>
                      Allowed *.jpeg, *.jpg, *.png, *.gif <br />
                    </Typography>
                    {/* <FormControlLabel control={<Switch name="Profile" sx={{ fontSize: "10px" }} />} sx={{ mt: 5, mb: 5 }} size="small" label="Profile" /> */}
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputRef}
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Paper elevation="1" sx={{ mb: 2 }}>
                    <CardHeader title={'ข้อมูลสาขา'} />
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        p: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(2, 1fr)',
                        }}
                      >
                        <FormControl fullWidth>
                          <TextField
                            id="name.th"
                            name="name.th"
                            label="ชื่อสาขา *"
                            type="text"
                            value={formik.values.name?.th}
                            onChange={formik.handleChange}
                            error={formik.touched.name?.th && Boolean(formik.errors.name?.th)}
                            helperText={formik.touched.name?.th && formik.errors.name?.th}
                          />
                        </FormControl>

                        <FormControl fullWidth>
                          <TextField
                            id="name.en"
                            name="name.en"
                            label="ชื่อสาขา (อังกฤษ)"
                            type="text"
                            value={formik.values.name?.en}
                            onChange={formik.handleChange}
                            error={formik.touched.name?.en && Boolean(formik.errors.name?.en)}
                            helperText={formik.touched.name?.en && formik.errors.name?.en}
                          />
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          display: 'grid',
                        }}
                      >
                        <FormControl fullWidth>
                          <TextField
                            id="address"
                            name="address"
                            label="ที่อยู่"
                            multiline
                            rows={3}
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                          />
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(2, 1fr)',
                        }}
                      >
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              id="opening_hours"
                              name="opening_hours"
                              label="เวลาเปิด"
                              format="HH:mm"
                              value={formik.values.display_opening_hours}
                              // onChange={formik.handleChange}
                              onChange={(newValue) => handleChangeTime(1, newValue)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={formik.touched.opening_hours && Boolean(formik.errors.opening_hours)}
                                  helperText={formik.touched.opening_hours && formik.errors.opening_hours}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>

                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              id="closing_time"
                              name="closing_time"
                              label="เวลาปิด"
                              format="HH:mm"
                              value={formik.values.display_closing_time}
                              // value={formik.values.opening_hours}
                              // onChange={formik.handleChange}

                              onChange={(newValue) => handleChangeTime(2, newValue)}
                              renderInput={(params) => (
                                <TextField
                                  error={formik.touched.closing_time && Boolean(formik.errors.closing_time)}
                                  helperText={formik.touched.closing_time && formik.errors.closing_time}
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <PhoneIcon sx={{ color: 'action.active', mr: 1, my: 2 }} />
                        <TextField
                          id="phone_number"
                          name="phone_number"
                          label="เบอร์ติดต่อ"
                          type="text"
                          value={formik.values.phone_number}
                          onChange={formik.handleChange}
                          error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                          helperText={formik.touched.phone_number && formik.errors.phone_number}
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Paper>

                  <Paper elevation="1" sx={{ mb: 2 }}>
                    <CardHeader title={'ตั้งค่าการคำนวณภาษีมูลค่าเพิ่ม (VAT)'} />
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        p: 3,
                      }}
                    >
                      <Box>
                        <FormControl fullWidth>
                          <RadioGroup
                            aria-labelledby="vat-radio-buttons-group-label"
                            value={formik.values.vat_type}
                            name="vat_type"
                            id="vat_type"
                            onChange={(e) => handleChangeVatType(e.target.value)}
                          // onChange={(e) => handleChangeVatType(e.target.value)}
                          >
                            <FormControlLabel value="none" control={<Radio />} label="ไม่คิดค่า VAT กับลูกค้า" />
                            <FormControlLabel
                              value="include"
                              control={<Radio />}
                              label="คิดค่า VAT แบบรวมกับราคาสินค้า"
                            />
                            <FormControlLabel
                              value="exclude"
                              control={<Radio />}
                              label="คิดค่า VAT แบบยังไม่รวมกับราคาสินค้า"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {formik.values.vat_type !== 'none' && (
                        <Box
                          sx={{
                            display: 'grid',
                            gap: 2,
                            gridTemplateColumns: 'repeat(2, 1fr)',
                          }}
                        >
                          <FormControl fullWidth>
                            <TextField
                              id="system_configs.vat_rate"
                              name="system_configs.vat_rate"
                              label="ภาษีมูลค่าเพิ่ม (%VAT)"
                              type="text"
                              inputProps={{ maxLength: 3 }}
                              value={formik.values.system_configs?.vat_rate}
                              onChange={formik.handleChange}
                              onKeyUp={(e) => {
                                setVatRate(e.target.value);
                              }}
                              error={
                                formik.touched.system_configs?.vat_rate &&
                                Boolean(formik.errors.system_configs?.vat_rate)
                              }
                              helperText={
                                formik.touched.system_configs?.vat_rate && formik.errors.system_configs?.vat_rate
                              }
                            />
                          </FormControl>
                        </Box>
                      )}
                    </Box>
                  </Paper>

                  <Paper elevation="1" sx={{ mb: 2 }}>
                    <CardHeader title={'ตั้งค่าการคำนวณค่าบริการ (SERVICE CHARGE)'} />
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        p: 3,
                      }}
                    >
                      <Box>
                        <FormControl fullWidth>
                          <RadioGroup
                            aria-labelledby="sc-radio-buttons-group-label"
                            value={formik.values.system_configs?.is_service_charge_enable}
                            name="system_configs.is_service_charge_enable"
                            id="system_configs.is_service_charge_enable"
                            onChange={(e) => handleChangeServiceCharge(e.target.value)}
                          >
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="ไม่คิดค่า Service Charge กับลูกค้า"
                            />
                            <FormControlLabel value="true" control={<Radio />} label="คิดค่า Service Charge" />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {formik.values.system_configs?.is_service_charge_enable === true && (
                        <Box
                          sx={{
                            display: 'grid',
                            gap: 2,
                            gridTemplateColumns: 'repeat(2, 1fr)',
                          }}
                        >
                          <FormControl fullWidth>
                            <TextField
                              id="system_configs.service_charge_rate"
                              name="system_configs.service_charge_rate"
                              label="ค่าบริการเพิ่มเติม (%Service Charge)"
                              type="text"
                              inputProps={{ maxLength: 3 }}
                              value={formik.values.system_configs?.service_charge_rate}
                              onChange={formik.handleChange}
                              onKeyUp={(e) => {
                                setServiceCharge(e.target.value);
                              }}
                              error={
                                formik.touched.system_configs?.service_charge_rate &&
                                Boolean(formik.errors.system_configs?.service_charge_rate)
                              }
                              helperText={
                                formik.touched.system_configs?.service_charge_rate &&
                                formik.errors.system_configs?.service_charge_rate
                              }
                            />
                          </FormControl>
                        </Box>
                      )}
                    </Box>
                  </Paper>

                  <Paper elevation="1">
                    <CardHeader title={'ตั้งค่าช่องทางการชำระเงิน'} />
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        p: 3,
                      }}
                    >
                      <Box xs={12} md={8}>
                        <FormControl fullWidth>
                          <RadioGroup
                            aria-labelledby="sc-radio-buttons-group-label"
                            value={formik.values.payment_configs?.bank_type}
                            name="payment_configs.bank_type"
                            id="payment_configs.bank_type"
                            onChange={(e) => handleBankType(e.target.value)}
                          >
                            <FormControlLabel value="BANK" control={<Radio />} label="บัญชีธนาคาร" />
                            <FormControlLabel value="PROMPTPAY" control={<Radio />} label="บัญชีพร้อมเพย์" />
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {formik.values.payment_configs.bank_type === 'BANK' && (
                        <Box
                          sx={{
                            display: 'grid',
                            gap: 2,
                            gridTemplateColumns: 'repeat(2, 1fr)',
                          }}
                        >
                          <FormControl fullWidth>
                            <Autocomplete
                              id="payment_configs.bank_name"
                              name="payment_configs.bank_name"
                              fullWidth
                              options={bank}
                              defaultValue={formik.values.current_bank}
                              onChange={handleChangeBankName}
                              getOptionLabel={(option) => option.official_name_thai}
                              renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  <img
                                    loading="lazy"
                                    width="20"
                                    style={{ backgroundColor: option.color }}
                                    src={`${option.image}`}
                                    alt=""
                                  />
                                  {option.official_name_thai}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="เลือกธนาคารของท่าน"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Box>
                      )}

                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(2, 1fr)',
                        }}
                      >
                        <FormControl fullWidth>
                          <TextField
                            id="payment_configs.account_name"
                            name="payment_configs.account_name"
                            label={`${formik.values.payment_configs.bank_type === 'BANK' ? 'ชื่อบัญชี *' : 'ชื่อพร้อมเพย์ *'
                              }`}
                            type="text"
                            value={formik.values.payment_configs?.account_name}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.payment_configs?.account_name &&
                              Boolean(formik.errors.payment_configs?.account_name)
                            }
                            helperText={
                              formik.touched.payment_configs?.account_name &&
                              formik.errors.payment_configs?.account_name
                            }
                          />
                        </FormControl>

                        <FormControl fullWidth>
                          <TextField
                            id="payment_configs.account_number"
                            name="payment_configs.account_number"
                            label={`${formik.values.payment_configs.bank_type === 'BANK' ? 'เลขบัญชีธนาคาร *' : 'เลขพร้อมเพย์ *'
                              }`}
                            type="text"
                            value={formik.values.payment_configs?.account_number}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.payment_configs?.account_number &&
                              Boolean(formik.errors.payment_configs?.account_number)
                            }
                            helperText={
                              formik.touched.payment_configs?.account_number &&
                              formik.errors.payment_configs?.account_number
                            }
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} spacing={1}>
                    {/* <Button variant="outlined" onClick={() => handleBack()}>
                  ยกเลิก
                </Button> */}

                    <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                      บันทึก
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Container>
    </>
  );
}
