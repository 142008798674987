import { createSlice } from '@reduxjs/toolkit'
import { listMasterCategory, listCategory, createCategory, detailCategory, updateCategory, deleteCategory, displayOrderCategory } from '../actions/categoryActions'

const initialState = {
  loading: false,
  masterCategories: [],
  categories: [],
  category: null,
  error: null,
  success: false,
}

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listMasterCategory.pending, (state) => {
        state.loading = true
        state.error = null
        state.masterCategories = []
      })
      .addCase(listMasterCategory.fulfilled, (state, { payload }) => {
        state.loading = false
        state.masterCategories = payload.data
      })
      .addCase(listMasterCategory.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.masterCategories = []
      })
      .addCase(listCategory.pending, (state) => {
        state.loading = true
        state.error = null
        state.categories = []
      })
      .addCase(listCategory.fulfilled, (state, { payload }) => {
        state.loading = false
        state.categories = payload.data
      })
      .addCase(listCategory.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.categories = []
      }).addCase(detailCategory.pending, (state) => {
        state.loading = true
        state.error = null
        state.category = {}
      })
      .addCase(detailCategory.fulfilled, (state, { payload }) => {
        state.loading = false
        state.category = payload.data
      })
      .addCase(detailCategory.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.category = {}
      })
      .addCase(createCategory.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(createCategory.fulfilled, (state) => {
        state.loading = false
        state.success = true
      })
      .addCase(createCategory.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(updateCategory.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateCategory.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(updateCategory.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(deleteCategory.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(deleteCategory.fulfilled, (state, { payload }) => {
        state.loading = false
        state.categories = payload.data
        state.success = false
      })
      .addCase(deleteCategory.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(displayOrderCategory.pending, (state) => {
        // state.loading = true
        // state.error = null
        state.success = false
      })
      .addCase(displayOrderCategory.fulfilled, (state) => {
        // state.loading = false
        // state.products = payload.data
        state.success = false
      })
      .addCase(displayOrderCategory.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
  },
})

export default categorySlice.reducer