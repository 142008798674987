import React, { useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const SurveyDialog = ({ surveyData, open, onClose }) => {
  const [checked, setChecked] = useState({});

  const handleToggle = (questionId, answer) => () => {
    setChecked((prev) => ({ ...prev, [questionId]: answer }));
  };
  // console.log(surveyData)
  const handleSubmit = () => {
    // Handle the submission of the survey here
    // For now, we'll just log to the console
    // console.log(checked);
    onClose(); // Close the dialog after submitting
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="survey-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="survey-dialog-title">Survey</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: '100%',
            overflow: 'auto',
            bgcolor: 'background.paper',
          }}
        >
          <List>
            {surveyData.survey_form.map((question) => (
              <ListItem
                key={question.questions_id}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <ListItemText primary={`${question.questions_name}`} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  {question.aws
                    .sort((a, b) => b.order - a.order)
                    .map((answer, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Radio
                            checked={checked[question.questions_id] === answer.answer}
                            onChange={handleToggle(question.questions_id, answer.answer)}
                            value={answer.answer}
                            name={question.questions_id}
                            inputProps={{ 'aria-label': answer.answer }}
                          />
                        }
                        label={answer.answer}
                      />
                    ))}
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SurveyDialog;
