import { createSlice } from '@reduxjs/toolkit'
import { listMasterTag, createTag } from '../actions/tagActions'

const initialState = {
  loading: false,
  tags: [], // for user object
  total: null,
  totalPage: null,
  error: null,
  success: false,
}

const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listMasterTag.pending, (state) => {
        state.loading = true
        state.error = null
        state.tags = []
      })
      .addCase(listMasterTag.fulfilled, (state, { payload }) => {
        state.loading = false
        state.tags = payload.data
      })
      .addCase(listMasterTag.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(createTag.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(createTag.fulfilled, (state, { payload }) => {
        state.loading = false
        state.tables = payload.data
        state.success = true
      })
      .addCase(createTag.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
  },
})

export default tagSlice.reducer