/* eslint-disable camelcase */
import _, { filter } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { QRCodeCanvas } from 'qrcode.react';
import { useNavigate } from 'react-router-dom';

import '../../services/qrcodesetting.css';
import {
  Grid,
  Box,
  FormControl,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

import { createTable, updateTable } from '../../redux/actions/tableActions';

// ----------------------------------------------------------------------
const validationSchema = yup.object({
  name: yup.object().shape({
    th: yup
      .string()
      .min(1, 'โปรดระบุชื่อโต๊ะ (1-20 ตัวอักษร)')
      .max(20, 'ชื่อโต๊ะเกินจำนวนที่สามารถรองรับ')
      .required('โปรดระบุชื่อโต๊ะ'),
    en: yup.string().min(1, 'โปรดระบุชื่อโต๊ะ (1-20 ตัวอักษร)').max(20, 'ชื่อโต๊ะเกินจำนวนที่สามารถรองรับ'),
  }),
});

export default function ModalAttribute({ openModel, handleClose, props = {}, totalTables }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { branch } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.table);
  const dispatch = useDispatch();

  // useEffect(() => {
  // }, [props]);

  const formik = useFormik({
    initialValues: {
      name: { th: '', en: '' },
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const event = {
          name: values.name,
          display_order: totalTables + 1,
          configs: values.configs,
        };
        if (props?._id) {
          await dispatch(updateTable({ tableId: props?._id, event: { ...event } }));
        } else {
          await dispatch(createTable({ ...event }));
        }

        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar('เกิดข้อผิดพลาด!', { variant: 'error' });
        }
        enqueueSnackbar('บันทึกข้อมูลเรียบร้อย!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
        handleClose();
      } catch (error) {
        enqueueSnackbar('เกิดข้อผิดพลาด!', { variant: 'error' });
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (props?.name) {
      formik.setFieldValue('name.th', props.name?.th || '');
      formik.setFieldValue('name.en', props.name?.en || '');

      if (props.name?.en) setExpanded('panel1');
    }
  }, [props]);

  // console.log(formik.values.configs);
  const [expanded, setExpanded] = useState();

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function download() {
    const canvas = document.getElementById('canvas');
    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = `table_${formik.values.name?.th}.png`;
    link.href = url;
    link.click();
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={openModel}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{props?.name?.th ? 'แก้ไขโต๊ะ' : 'สร้างโต๊ะ'}</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    pt: 1,
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      id="name.th"
                      name="name.th"
                      label="ชื่อโต๊ะ *"
                      type="text"
                      characterLimit={20}
                      variant="outlined"
                      value={formik.values.name?.th}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 10 }}
                      error={formik.touched.name?.th && Boolean(formik.errors.name?.th)}
                      helperText={formik.touched.name?.th && formik.errors.name?.th}
                    />
                    <div className="helplimit">{formik.values.name?.th.length}/10</div>
                  </FormControl>
                </Box>
                <Accordion disableGutters expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1a-header"
                    sx={{ pt: 0, pr: 0, pl: 0, pb: 1 }}
                  >
                    ชื่อโต๊ะ (อังกฤษ)
                  </AccordionSummary>
                  <AccordionDetails sx={{ pt: 0, pr: 0, pl: 0, pb: 1 }}>
                    <Box>
                      <FormControl fullWidth>
                        <TextField
                          id="name.en"
                          name="name.en"
                          label="ชื่อโต๊ะ (อังกฤษ)"
                          type="text"
                          value={formik.values.name?.en}
                          onChange={formik.handleChange}
                          error={formik.touched.name?.en && Boolean(formik.errors.name?.en)}
                          helperText={formik.touched.name?.en && formik.errors.name?.en}
                          inputProps={{ maxLength: 10 }}
                        />{' '}
                        <div className="helplimit">{formik.values.name?.en.length}/10</div>
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    pt: 1,
                  }}
                >
                  {props?.qrcodeLink && (
                    <QRCodeCanvas
                      size={256}
                      value={props?.qrcodeLink}
                      includeMargin
                      imageSettings={{
                        src: '/assets/illustrations/ezorder_login.png',
                        x: undefined,
                        y: undefined,
                        height: 48,
                        width: 48,
                        excavate: false,
                      }}
                    />
                  )}
                  {props?.qrcodeLink && (
                    <QRCodeCanvas
                      hidden
                      id={`canvas`}
                      size={512}
                      value={props?.qrcodeLink}
                      includeMargin
                      imageSettings={{
                        src: '/assets/illustrations/ezorder_login.png',
                        x: undefined,
                        y: undefined,
                        height: 48,
                        width: 48,
                        excavate: false,
                      }}
                    />
                  )}
                </Box>
              </Grid>
              <Box
                sx={{
                  pt: 1,
                }}
              >
                {props?.qrcodeLink && (
                  <Button variant="text" onClick={() => download()}>
                    ดาวน์โหลดคิวอาร์โค้ด
                  </Button>
                )}
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()}>ยกเลิก</Button>
            <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
              บันทึก
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
