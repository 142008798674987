import React from 'react';
import { Grid, TextField, FormControl, Button } from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';
import MapWithForm from './MapWithForm';
import { googleMapApiKey } from '../../services/var';

const GooglePlacesAutocomplete = ({ formik }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script-auto',
    googleMapsApiKey: googleMapApiKey,
    libraries: ["places"],
  });

  const extractLatLngZoomFromGoogleMapsURL = (url) => {
    const centerPattern = /@([-.\d]+),([-.\d]+),(\d+\.?\d*)z/;
    const markerPattern = /!3d([-.\d]+)!4d([-.\d]+)/;
    const queryPattern = /query=([-.\d]+),([-.\d]+)&zoom=(\d+)/;
    const centerMatch = url.match(centerPattern);
    const markerMatch = url.match(markerPattern);
    const queryMatch = url.match(queryPattern);
    let latLngZoom = {}
    // let queryCoords = {}
    if (centerMatch)
      latLngZoom = markerMatch ? { lat: markerMatch[1], lng: markerMatch[2], zoom: centerMatch[3] } : null;
    else {
      latLngZoom = queryMatch ? { lat: queryMatch[1], lng: queryMatch[2], zoom: queryMatch[3] } : null;
    }
    return latLngZoom;
  };

  const handleExtractDetails = () => {
    // setMapUrl(formik.values.location_address?.google_mal_url)
    if (!formik.values.location_address?.google_mal_url) {
      alert("Please enter a Google Maps URL.");
      return;
    }

    // Extract details from the mapUrl
    const details = extractLatLngZoomFromGoogleMapsURL(formik.values.location_address.google_mal_url);
    if (details) {
      if (formik.values.location_address) {
        formik.setFieldValue("location_address.lat", parseFloat(details.lat));
        formik.setFieldValue("location_address.lng", parseFloat(details.lng));
        formik.setFieldValue("location_address.zoom", parseFloat(details.zoom));
      }
    } else {
      alert("The provided URL does not contain valid geolocation data.");
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <FormControl fullWidth>
            <TextField
              id="location_address.google_mal_url"
              name="location_address.google_mal_url"
              type="text"
              label="Google Map Url"
              // inputProps={{ ref: inputRef }}
              value={formik.values.location_address?.google_mal_url}
              defaultValue={formik.values.location_address?.google_mal_url}
              onChange={formik.handleChange}
              error={
                formik.touched?.location_address?.google_mal_url &&
                Boolean(formik.errors?.location_address?.google_mal_url)
              }
              helperText={
                formik.touched?.location_address?.google_mal_url &&
                formik.errors?.location_address?.google_mal_url
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <TextField
              id="location_address.radius"
              name="location_address.radius"
              type="number"
              label="พื้นที่รอบร้าน"
              inputProps={{ maxLength: 3 }}
              defaultValue={formik.values.location_address?.radius}
              value={formik.values.location_address?.radius}
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => handleExtractDetails()}>โหลดที่ตั้ง GoogleMap</Button>
        </Grid>
      </Grid>

      <MapWithForm formik={formik} zoom={formik.values.location_address?.zoom || 10} isLoaded={isLoaded} />

    </>
  );
};

export default GooglePlacesAutocomplete;
