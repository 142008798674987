import { createSlice } from '@reduxjs/toolkit'
import { listProduct, createProduct, detailProduct, updateProduct, deleteProduct, displayOrderProduct } from '../actions/productActions'

const initialState = {
  loading: false,
  products: [],
  product: {},
  total: null,
  totalPage: null,
  error: null,
  success: false,
}
const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      const { payload } = action.payload
      state.products = payload.data || []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listProduct.pending, (state) => {
        state.loading = true
        state.error = null
        state.products = []
      })
      .addCase(listProduct.fulfilled, (state, { payload }) => {
        state.loading = false
        state.products = payload.data
        state.total = payload.total
        state.totalPage = payload.total_page
      })
      .addCase(listProduct.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.products = []
      })
      .addCase(detailProduct.pending, (state) => {
        state.loading = true
        state.error = null
        state.product = {}
      })
      .addCase(detailProduct.fulfilled, (state, { payload }) => {
        state.loading = false
        state.product = payload.data
      })
      .addCase(detailProduct.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.product = {}
      })
      .addCase(createProduct.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(createProduct.fulfilled, (state, { payload }) => {
        state.loading = false
        state.products = payload.data
        state.success = true
      })
      .addCase(createProduct.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(updateProduct.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateProduct.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(updateProduct.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(deleteProduct.fulfilled, (state, { payload }) => {
        state.loading = false
        state.products = payload.data
        state.success = false
      })
      .addCase(deleteProduct.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(displayOrderProduct.pending, (state) => {
        // state.loading = true
        // state.error = null
        state.success = false
      })
      .addCase(displayOrderProduct.fulfilled, (state) => {
        // state.loading = false
        // state.products = payload.data
        state.success = false
      })
      .addCase(displayOrderProduct.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })

  },
})
export const { setProducts } = productSlice.actions
export default productSlice.reducer