import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { Container, Typography, Stack, Tab, Tabs } from '@mui/material';
// redux
import { listBranch } from '../redux/actions/branchActions';
// components
// service
import SettingShop from '../components/setting/Shop';
import SettingBranch from '../components/setting/Branch';

export default function ShopPage() {
  // const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { branch, auth } = useSelector((state) => state);
  const branchId = auth.branch;
  const userPermissions = auth.userInfo.permissions
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (branchId) dispatch(listBranch());
  }, [branchId, dispatch]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | จัดการร้าน/สาขา </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
          <Typography variant="h4" gutterBottom>
            จัดการร้าน/สาขา
          </Typography>
        </Stack>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          sx={{ m: 0, mb: 4 }}
        >
          {
            userPermissions !== undefined &&  userPermissions.find((v) => v.resource.includes("branches")) && <Tab label="ตั้งค่าร้าน" />
          }
          {
            userPermissions !== undefined && userPermissions.find((v) => v.resource.includes("shops")) && <Tab label="ตั้งค่าสาขา" />
          }
          {/* <Tab label="ตั้งค่าร้าน" />

          <Tab label="ตั้งค่าสาขา" /> */}

        </Tabs>
        {tabValue === 0 && <SettingShop />}
        {tabValue === 1 && <SettingBranch id={branchId} />}
      </Container>
    </>
  );
}
