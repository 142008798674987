import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseURL } from '../../services/var'
import { userRefresh } from './authActions'

export const listUser = createAsyncThunk('management/user',
    async (arg, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            // const checkSearch = search !== "" && search !== undefined ? `&search=${search}` : ''
            // const { data } = await axios.get(`${baseURL}/v1/user?page=${page}&limit=${limit}${checkSearch}`, config)
            const { data } = await axios.get(`${baseURL}/v1/user`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const createUser = createAsyncThunk('management/createUser',
    async (event, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/user`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const detailUser = createAsyncThunk('management/detailUser',
    async ({ userId }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/user/${userId}`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const updateUser = createAsyncThunk('management/updateUser',
    async ({ userId, event }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.put(`${baseURL}/v1/user/${userId}`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const deleteUser = createAsyncThunk('management/deleteUser',
    async (event, { dispatch, getState, rejectWithValue }) => {
        try {

            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/user/delete`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)


export const changePassword = createAsyncThunk('management/changePassword',
    async ({ userId, event }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.put(`${baseURL}/v1/user/password/${userId}`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)


export const listUserRole = createAsyncThunk('management/user/role',
    async (arg, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/user/role`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)
