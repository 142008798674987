import { createSlice } from '@reduxjs/toolkit'
import { listMasterAttribute, listAttribute, createAttribute, updateAttribute, deleteAttribute, displayOrderAttribute } from '../actions/attributeActions'

const initialState = {
  loading: false,
  attributes: [],
  error: null,
  success: false,
}

const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    setAttributes: (state, action) => {
      const { attributes } = action.payload
      state.attributes = attributes || []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listMasterAttribute.pending, (state) => {
        state.loading = true
        state.error = null
        state.attributes = []
      })
      .addCase(listMasterAttribute.fulfilled, (state, { payload }) => {
        state.loading = false
        state.attributes = payload.data
      })
      .addCase(listMasterAttribute.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(listAttribute.pending, (state) => {
        state.loading = true
        state.error = null
        state.attributes = []
      })
      .addCase(listAttribute.fulfilled, (state, { payload }) => {
        state.loading = false
        state.attributes = payload.data
      })
      .addCase(listAttribute.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.attributes = []
      })
      .addCase(createAttribute.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(createAttribute.fulfilled, (state, { payload }) => {
        state.loading = false
        state.attributes = payload.data
        state.success = true
      })
      .addCase(createAttribute.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(updateAttribute.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(updateAttribute.fulfilled, (state, { payload }) => {
        state.attributes = payload.data
        state.success = true
        state.loading = false
      })
      .addCase(updateAttribute.rejected, (state, { payload }) => {
        state.loading = false
        state.success = false
        state.error = payload
      })
      .addCase(deleteAttribute.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(deleteAttribute.fulfilled, (state, { payload }) => {
        state.loading = false
        state.attributes = payload.data
        state.success = false
      })
      .addCase(deleteAttribute.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(displayOrderAttribute.pending, (state) => {
        // state.loading = true
        // state.error = null
        state.success = false
      })
      .addCase(displayOrderAttribute.fulfilled, (state) => {
        // state.attributes = payload.data
        state.success = true
      })
      .addCase(displayOrderAttribute.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
  },
})
export const { setAttributes } = attributeSlice.actions

export default attributeSlice.reducer