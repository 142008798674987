// routes
import { useRoutes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import routes from './routes';

// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { userRefresh } from './redux/actions/authActions'
// eslint-disable-next-line import/no-unresolved
import 'reactjs-popup/dist/index.css';

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, accessToken, error, refreshExpire } = useSelector((state) => state.auth);
  const isLoginRoute = isLoggedIn || !!accessToken;
  const router = useRoutes(routes(isLoginRoute));

  useEffect(() => {
    if (error === "jwt expired") dispatch(userRefresh());
  }, [error, dispatch])

  useEffect(() => {
    if (refreshExpire) navigate('login');
  }, [refreshExpire, dispatch])


  useEffect(() => {
    if (error === "Unauthorized") navigate('login');
  }, [error, dispatch])


  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider>
        <ScrollToTop />
        <StyledChart />
        {router}
      </ThemeProvider>
    </SnackbarProvider>
  );
}
