import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogContent, DialogActions, Button, Grid, Box, IconButton, DialogTitle } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LoadingButton } from '@mui/lab';

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  draggableItem: {
    padding: 3,
    color: theme.palette.common.black,
    cursor: 'grab',
  },
  box: {
    border: "1px dashed #ccc",
    padding: "10px",
    display: "flex",
    background: "#efefef",
    alignItems: "center",
    justifyItems: "center",
    minWidth: '345px',
    justifyContent: 'space-between',
  },
  centerbox: {
    alignItems: "center",
    display: "flex",
    flex: 1

  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const initialItems = [
  { id: 'item-1', content: 'Item 1' },
  { id: 'item-2', content: 'Item 2' },
  { id: 'item-3', content: 'Item 3' },
];

// const DragAndDropDialog = ({ open, onClose, itemsData, handleSubmit, title }) => {
// =======
const ReorderDialog = ({ open, onClose, itemsData, handleSubmit, title }) => {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const themes = useTheme()
  const isSmall = useMediaQuery(themes.breakpoints.down('md'));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setItems(itemsData)
  }, [itemsData])

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, movedItem);
    setItems(updatedItems);
    // Submit(updatedItems)

  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "#2065D1" : "#eee",
    color: isDragging ? "#fff" : "#000",
  });

  const handleReorder = async () => {
    setIsLoading(true)
    await handleSubmit(items).then(() => setIsLoading(false)).catch(() => setIsLoading(false));
    onClose(false)
    handleSubmit(items)
    setIsLoading(false)
  }

  const moveItem = (fromIndex, toIndex) => {
    // Make a copy of the items array to avoid mutating the original state
    const updatedItems = [...items];

    // Remove the item from its current position
    const [movedItem] = updatedItems.splice(fromIndex, 1);

    // Insert the item at the new position
    updatedItems.splice(toIndex, 0, movedItem);

    // Update the state with the new array order
    setItems(updatedItems);
  };

  const onMoveItemUp = (index) => {
    if (index > 0) {
      moveItem(index, index - 1);
    }
  };

  const onMoveItemDown = (index) => {
    if (index < items.length - 1) {
      moveItem(index, index + 1);
    }
  };
  return (
    <Dialog open={open} onClose={() => onClose(false)} className={classes.dialog} fullScreen={isSmall}>
      <DialogTitle id="alert-dialog-title">
        <Grid container direction="row" justifyContent={'center'} alignItems="center">
          <Box>{title}</Box>
          <IconButton aria-label="close" onClick={(e) => onClose(false)} style={{ position: 'absolute', right: 15 }}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <div>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}

                >
                  {items && items.map((item, index) => (
                    <Draggable key={item._id} draggableId={item._id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.draggableItem}

                        >
                          <div className={classes.box} style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}>
                            <div className={classes.centerbox}>
                              <DragIndicatorIcon /> <div style={{ "display": "-webkit-box", "WebkitLineClamp": "1", "WebkitBoxOrient": "vertical", "overflow": "hidden" }}>{item.name.th}</div>
                            </div>

                            <div>
                              <KeyboardArrowUpIcon onClick={() => onMoveItemUp(index)} />
                              <KeyboardArrowDownIcon onClick={() => onMoveItemDown(index)} />
                            </div>

                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </DialogContent>
      <DialogActions sx={{
        padding: '15px 15px',
        boxShadow: "-4px -20px 26px -31px rgba(0,0,0,0.41)",
        WebkitBoxShadow: "-4px -20px 26px -31px rgba(0,0,0,0.41)",
        MozBoxShadow: "-4px -20px 26px -31px rgba(0,0,0,0.41)",

      }}>
        <Button onClick={() => onClose(false)}>ยกเลิก</Button>
        <LoadingButton loading={isLoading} onClick={() => handleReorder()} variant="contained">
          บันทึก
        </LoadingButton>
        {/* <Button onClick={() => onClose(false)} className={classes.cancelButton}>
          ยกเลิก
        </Button>
        <Button onClick={() => SubmitReorder()} className={classes.confirmButton}>
          ตกลง
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default ReorderDialog;
