import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseURL } from '../../services/var'

export const userLogin = createAsyncThunk('auth/login',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/auth/backoffice/login`, { email, password }, config)
            localStorage.setItem('accessToken', data.token)
            localStorage.setItem('refreshToken', data.refresh_token)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const userProfile = createAsyncThunk('auth/me',
    async (arg, { getState, rejectWithValue, dispatch }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/auth/backoffice/me`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                if (error.response.status === 401) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const userRefresh = createAsyncThunk('auth/refresh',
    async (arg, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.refreshToken}`
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/auth/backoffice/refresh`, {}, config)
            localStorage.setItem('accessToken', data.token)
            localStorage.setItem('refreshToken', data.refresh_token)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

