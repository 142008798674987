import { createSlice } from '@reduxjs/toolkit'
import { listOption, createOption, updateOption, displayOrderOption } from '../actions/optionActions'

const initialState = {
  loading: false,
  options: [],
  error: null,
  success: false,
}

const optionSlice = createSlice({
  name: 'option',
  initialState,
  reducers: {
    setOptions: (state, action) => {
      const { options } = action.payload
      state.options = options || []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listOption.pending, (state) => {
        state.loading = true
        state.error = null
        // state.options = []
      })
      .addCase(listOption.fulfilled, (state, { payload }) => {
        state.loading = false
        state.options = payload.data
      })
      .addCase(listOption.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(createOption.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(createOption.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = true
        state.options = payload.data
      })
      .addCase(createOption.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(updateOption.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateOption.fulfilled, (state, { payload }) => {
        state.loading = false
        state.options = payload.data
      })
      .addCase(updateOption.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(displayOrderOption.pending, (state) => {
        // state.loading = true
        // state.error = null
        state.success = false
      })
      .addCase(displayOrderOption.fulfilled, (state) => {
        // state.loading = false
        // state.products = payload.data
        state.success = false
      })
      .addCase(displayOrderOption.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
  },
})
export const { setOptions } = optionSlice.actions

export default optionSlice.reducer