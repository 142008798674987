import { createSlice } from '@reduxjs/toolkit'
import { listBranch, createBranch, updateBranch, deleteBranch, detailBranch } from '../actions/branchActions'

const initialState = {
  loading: false,
  branches: [],
  branchState: {},
  error: null,
}

const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listBranch.pending, (state) => {
        state.loading = true
        state.error = null
        state.branches = []
      })
      .addCase(listBranch.fulfilled, (state, { payload }) => {
        state.loading = false
        state.branches = payload.data
      })
      .addCase(listBranch.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.branches = []
      })
      .addCase(detailBranch.pending, (state) => {
        state.branchState = {}
        state.loading = true
        state.error = null
      })
      .addCase(detailBranch.fulfilled, (state, { payload }) => {
        state.branchState = payload.data
        state.success = true
        state.loading = false
      })
      .addCase(detailBranch.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(createBranch.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createBranch.fulfilled, (state, { payload }) => {
        state.loading = false
        state.branches = payload.data
        state.success = true
      })
      .addCase(createBranch.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload

      })
      .addCase(updateBranch.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateBranch.fulfilled, (state, { payload }) => {
        state.branches = payload.data
        state.success = true
        state.loading = false
      })
      .addCase(updateBranch.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })


      .addCase(deleteBranch.pending, (state) => {
        state.loading = true
        state.error = null
      })

      .addCase(deleteBranch.fulfilled, (state, { payload }) => {
        state.loading = false
        state.branches = payload.data

      })
      .addCase(deleteBranch.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload

      })
  },
})

export default branchSlice.reducer