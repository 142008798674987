import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import _ from 'lodash';
// @mui
import {
  InputAdornment,
  Card,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  Button,
  Stack,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Iconify from '../components/iconify';

const validationSchema = yup.object({
  old_password: yup
    .string()
    .required('กรุณากรอกรหัสผ่านเก่า')
    .min(8, 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร')
    .max(16, 'รหัสผ่านต้องมีไม่เกิน 16 ตัวอักษร'),
  new_password: yup
    .string()
    .required('กรุณากรอกรหัสผ่านใหม่')
    .min(8, 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร')
    .max(16, 'รหัสผ่านต้องมีไม่เกิน 16 ตัวอักษร'),
  new_password_confirmation: yup
    .string()
    .required('กรุณายืนยันรหัสผ่านใหม่')
    .min(8, 'ยืนยันรหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร')
    .max(16, 'ยืนยันรหัสผ่านต้องมีไม่เกิน 16 ตัวอักษร')
    .oneOf([yup.ref('new_password'), null], 'รหัสผ่านไม่ตรงกัน'),
});

export default function ChangePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);

  const endAdornmentStyle = {
    position: 'absolute',
    right: 0,
    transform: 'translateX(-100%)',
  };

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {

      try {
        const data = {
          old_password: values.old_password,
          new_password: values.new_password,
          new_password_confirmation: values.new_password_confirmation,
        };

        enqueueSnackbar('เปลี่ยนรหัสผ่านเรียบร้อย', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
        handleBack();
      } catch (error) {
        enqueueSnackbar('This is a success message!', { variant: 'error' });
        setSubmitting(false);
      }
    },
  });

  const handleBack = () => {
    navigate('/dashboard');
  };

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | เปลี่ยนรหัสผ่าน </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">เปลี่ยนรหัสผ่าน</Typography>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                  <FormControl fullWidth>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                      (8-16 ตัวอักษร)
                    </Stack>
                    <TextField
                      id="old_password"
                      name="old_password"
                      label="รหัสผ่านเก่า"
                      type={showOldPassword ? 'text' : 'password'}
                      onChange={formik.handleChange}
                      error={formik.touched.old_password && Boolean(formik.errors.old_password)}
                      helperText={formik.touched.old_password && formik.errors.old_password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={endAdornmentStyle}>
                            <IconButton
                              onClick={() => setShowOldPassword(!showOldPassword)}
                              edge="end"
                              aria-label={showOldPassword ? 'แสดงรหัสผ่าน' : 'ซ่อนรหัสผ่าน'}
                            >
                              {/* {showOldPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} */}
                              <Iconify width="1.2em" icon={showOldPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                  <FormControl fullWidth>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                      (8-16 ตัวอักษร)
                    </Stack>
                    <TextField
                      id="new_password"
                      name="new_password"
                      label="รหัสผ่านใหม่"
                      type={showNewPassword ? 'text' : 'password'}
                      onChange={formik.handleChange}
                      error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                      helperText={formik.touched.new_password && formik.errors.new_password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={endAdornmentStyle}>
                            <IconButton
                              onClick={() => setShowNewPassword(!showNewPassword)}
                              edge="end"
                              aria-label={showNewPassword ? 'แสดงรหัสผ่าน' : 'ซ่อนรหัสผ่าน'}
                            >
                              <Iconify width="1.2em" icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                  <FormControl fullWidth>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                      (8-16 ตัวอักษร)
                    </Stack>
                    <TextField
                      id="new_password_confirmation"
                      name="new_password_confirmation"
                      label="ยืนยันรหัสผ่านใหม่"
                      type={showNewPasswordConfirmation ? 'text' : 'password'}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.new_password_confirmation && Boolean(formik.errors.new_password_confirmation)
                      }
                      helperText={formik.touched.new_password_confirmation && formik.errors.new_password_confirmation}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={endAdornmentStyle}>
                            <IconButton
                              onClick={() => setShowNewPasswordConfirmation(!showNewPasswordConfirmation)}
                              edge="end"
                              aria-label={showNewPasswordConfirmation ? 'แสดงรหัสผ่าน' : 'ซ่อนรหัสผ่าน'}
                            >
                              {/* {showNewPasswordConfirmation ? <FaEye /> : <FaEyeSlash />} */}
                              <Iconify
                                width="1.2em"
                                icon={showNewPasswordConfirmation ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} spacing={1}>
                <Button variant="outlined" onClick={() => handleBack()}>
                  ยกเลิก
                </Button>

                <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                  บันทึก
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
