import { Navigate } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// main menu
import BlogPage from './pages/BlogPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardPage from './pages/DashboardPage';

import BranchPage from './pages/BranchPage';
import CreateBranch from './pages/branch/Create';
import EditBranch from './pages/branch/Edit';
// product
import ListProduct from './pages/ListProductPage';
import CreateProduct from './pages/product/Create';
import EditProduct from './pages/product/Edit';
// attribute
import ListAttribute from './pages/ListAttributePage';
// category
import ListCategory from './pages/ListCategoryPage';
import CreateCategory from './pages/category/Create';
import EditCategory from './pages/category/Edit';
// user
import CreateUser from './pages/user/Create';
// import EditUser from './pages/user/Edit';
// table
import ListTable from './pages/ListTablePage';
// import CreateTable from './pages/table/Create';
// import EditTable from './pages/table/Edit';
import SettingQrPage from './pages/SettingQrPage';
// change password
import ChangePassword from './pages/ChangePassword';
// manage event
import ManageEvent from './pages/ManageEvent';
import ListUser from './pages/ListUser';

// setting page
import ShopPage from './pages/ShopPage';

// report page
import SummarySalePage from './pages/report/SummarySalePage';
import SummarySaleWithProductPage from './pages/report/SummarySaleWithProductPage';
import SummarySaleWithCategoryPage from './pages/report/SummarySaleWithCategoryPage';
import ReportBillPage from './pages/report/BillPage';
import EditSetting from './pages/theme/Edit';
import CreateSetting from './pages/theme/Create';

import ListPackage from './pages/ListPackagePage';
import CreatePackage from './pages/package/Create';
import EditPackage from './pages/package/Edit';

const routes = (isLoggedIn) => [
  {
    path: '/',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { element: <Navigate to="/dashboard" />, index: true },
      { path: 'dashboard', element: <DashboardPage /> },
      { path: 'report', element: <DashboardPage /> },
      { path: 'report/summary-sale', element: <SummarySalePage /> },
      { path: 'report/summary-sale-product', element: <SummarySaleWithProductPage /> },
      { path: 'report/summary-sale-category', element: <SummarySaleWithCategoryPage /> },
      { path: 'report/bill', element: <ReportBillPage /> },
      { path: 'product', element: <ListProduct /> },
      { path: 'product/create', element: <CreateProduct /> },
      { path: 'product/:id', element: <EditProduct /> },
      { path: 'package', element: <ListPackage /> },
      { path: 'package/create', element: <CreatePackage /> },
      { path: 'package/:id', element: <EditPackage /> },
      { path: 'setting/create', element: <CreateSetting /> },
      { path: 'setting/:id', element: <EditSetting /> },
      { path: 'attribute', element: <ListAttribute /> },
      { path: 'category', element: <ListCategory /> },
      { path: 'category/create', element: <CreateCategory /> },
      { path: 'category/:id', element: <EditCategory /> },
      { path: 'table', element: <ListTable /> },
      // { path: 'manage-user/create', element: <CreateUser /> },
      // { path: 'table/create', element: <CreateTable /> },
      // { path: 'table/:id', element: <EditTable /> },
      { path: 'blog', element: <BlogPage /> },
      // { path: 'live-order', element: <LiveOrderPage /> },
      { path: 'shop/setting', element: <ShopPage /> },
      { path: 'branch', element: <BranchPage /> },
      { path: 'branch/create', element: <CreateBranch /> },
      { path: 'branch/:id', element: <EditBranch /> },
      // { path: 'manage-user/create', element: <UserPage /> },
      
      // ! careful
      // { path: 'branch/:id', element: <ShopSettingPage /> },
      // { path: 'change-password', element: <ChangePassword /> },
      // ! careful

      // { path: 'setting-qrcode', element: <SettingQrPage /> },
      { path: 'manage-event', element: <ManageEvent /> },
      { path: 'manage-user', element: <ListUser /> },
      // { path: 'setting-general', element: <GeneralSettingPage /> },
    ],
  },

  {
    path: '/',
    element: !isLoggedIn ? <SimpleLayout /> : <Navigate to="/dashboard" />,
    children: [
      { path: 'login', element: <LoginPage /> },
      { path: '/', element: <Navigate to="/login" /> },
    ],
  },
  {
    element: <SimpleLayout />,
    children: [
      { element: <Navigate to="/dashboard/app" />, index: true },
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/404" replace />,
  },
];

export default routes;
