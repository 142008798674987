/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Box, Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------
import { createUser, updateUser } from '../../redux/actions/userActions';

// ----------------------------------------------------------------------

const validationCreateSchema = yup.object().shape({
  first_name: yup.string()
    .required('ชื่อจำเป็นต้องกรอก')
    .min(1, 'โปรดระบุชื่อผู้ใช้ (1-20 ตัวอักษร)')
    .max(20, 'ชื่อผู้ใช้เกินจำนวนที่สามารถรองรับ'),
  last_name: yup.string()
    .min(1, 'โปรดระบุนามสกุลผู้ใช้ (1-20 ตัวอักษร)')
    .max(20, 'นามสกุลผู้ใช้เกินจำนวนที่สามารถรองรับ'),
  roles: yup.array().min(1, 'กรุณาระบุตำแหน่ง').required('กรุณาระบุตำแหน่ง'),
  u_email: yup
    .string()
    .email('อีเมลไม่ถูกต้อง')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'ที่อยู่อีเมลผิด')
    .required('อีเมลจำเป็นต้องกรอก'),
  a_password: yup
    .string()
    .required('รหัสผ่านจำเป็นต้องกรอก')
    .min(6, 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร')
    .max(12, 'รหัสผ่านต้องมีไม่เกิน 12 ตัวอักษร'),
  b_password: yup
    .string()
    .required('ยืนยันรหัสผ่านจำเป็นต้องกรอก')
    .oneOf([yup.ref("a_password"), null], "รหัสผ่านไม่ตรงกัน")
});

const validationEditSchema = yup.object().shape({
  first_name: yup.string()
    .required('ชื่อจำเป็นต้องกรอก')
    .min(1, 'โปรดระบุชื่อผู้ใช้ (1-20 ตัวอักษร)')
    .max(20, 'ชื่อผู้ใช้เกินจำนวนที่สามารถรองรับ'),
  last_name: yup.string()
    .min(1, 'โปรดระบุนามสกุลผู้ใช้ (1-20 ตัวอักษร)')
    .max(20, 'นามสกุลผู้ใช้เกินจำนวนที่สามารถรองรับ'),
  roles: yup.array().min(1, 'กรุณาระบุตำแหน่ง').required('กรุณาระบุตำแหน่ง'),
});

export default function ModalUser({ openModel, handleClose, props = {} }) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const { error, userRoles } = useSelector((state) => state.user);
  const validationSchema = !props?._id ? validationCreateSchema : validationEditSchema
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      roles: [],
      u_email: '',
      a_password: '',
      b_password: ''
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const newRoles = [];
        values.roles.forEach((item) => {
          // const newOptions = _.map(item.options, '_id');
          newRoles.push(item._id);
        });

        const data = {
          first_name: values.first_name,
          last_name: values.last_name,
          roles: newRoles,
          email: values.u_email,
          password: values.a_password,
        };

        if (props?._id) {
          await dispatch(updateUser({ userId: props?._id, event: { ...data } }));
        } else {
          dispatch(createUser({ ...data }));
        }

        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar('โปรดตรวจสอบอีกครั้ง', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
        enqueueSnackbar('บันทึกข้อมูลเรียบร้อย', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
        handleClose();
      } catch (error) {
        enqueueSnackbar('โปรดตรวจสอบอีกครั้ง', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (props?._id) {
      formik.setFieldValue('first_name', props.first_name || '');
      formik.setFieldValue('last_name', props.last_name || '');
      formik.setFieldValue('u_email', props.email);
    }
  }, [props]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openModel}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {props?._id ? 'แก้ไขผู้ใช้งาน' : 'สร้างผู้ใช้งาน'}
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="first_name"
                  name="first_name"
                  label="ชื่อ"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                  helperText={formik.touched.first_name && formik.errors.first_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="last_name"
                  name="last_name"
                  label="นามสกุล"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                  helperText={formik.touched.last_name && formik.errors.last_name}
                />
              </Grid>

              {!props?._id &&
                <>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      id="u_email"
                      name="u_email"
                      label="อีเมล"
                      value={formik.values.u_email}
                      onChange={formik.handleChange}
                      error={formik.touched.u_email && Boolean(formik.errors.u_email)}
                      helperText={formik.touched.u_email && formik.errors.u_email}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="a_password"
                      name="a_password"
                      label="รหัสผ่าน"
                      type="password"
                      value={formik.values.a_password}
                      onChange={formik.handleChange}
                      error={formik.touched.a_password && Boolean(formik.errors.a_password)}
                      helperText={formik.touched.a_password && formik.errors.a_password}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="b_password"
                      name="b_password"
                      label="ยืนยันรหัสผ่าน"
                      type="password"
                      value={formik.values.b_password}
                      onChange={formik.handleChange}
                      error={formik.touched.b_password && Boolean(formik.errors.b_password)}
                      helperText={formik.touched.b_password && formik.errors.b_password}
                    />
                  </Grid>
                </>
              }
              <Grid item xs={12} md={12}>

                <Autocomplete
                  id="attributes"
                  multiple
                  options={userRoles}
                  getOptionLabel={(option) => option.name || ''}
                  onChange={(e, value) => formik.setFieldValue('roles', value || [])}
                  isOptionEqualToValue={(option, value) => option?._id === value?._id}
                  defaultValue={props.roles}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  renderInput={(params) => (
                    <TextField {...params} label="ตำแหน่ง" placeholder="ตำแหน่ง" />
                  )}
                />
                <Box sx={{ color: 'red', marginLeft: "15px" }}>
                  <Typography variant="caption">{formik.touched.roles && formik.errors.roles}</Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()}>ยกเลิก</Button>
            <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
              บันทึก
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>


    </>
  );
}
