import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Box, Card, CardHeader, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
// html-to-image
import { toPng } from 'html-to-image';
// xlsx
import * as XLSX from 'xlsx';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------
AppConversionRates.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
};

export default function AppConversionRates({ title, subheader, chartData, ...other }) {
  const cardRef = useRef(null);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuVisible, setMenuVisible] = useState(true);

  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);

  const handleDownloadXLSX = () => {
    const headers = [['สินค้า', 'จำนวน']];

    const data = chartLabels.map((label, index) => [label, chartSeries[index]]);
    const wsData = [...headers, ...data];

    const worksheet = XLSX.utils.aoa_to_sheet(wsData);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, title);
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };

  const handleMenuOpen = (event) => {
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX - 150,
    });

    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorPosition(null);
  };

  const handleDownloadPng = async () => {
    handleMenuClose();

    setMenuVisible(false);

    toPng(cardRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${title}.png`;
        link.click();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setMenuVisible(true);
      });
  };

  const chartOptions = useChart({
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => 'จำนวน',
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '28%', borderRadius: 2 },
    },
    xaxis: {
      categories: chartLabels,
    },
    chart: {
      events: {
        mounted: (chartContext, config) => {
          const button = document.querySelector('.download-button');
          try {
            const chartContainer = config.el.parentNode;
            chartContainer.style.position = 'relative';
            chartContainer.appendChild(button);
          } catch (error) {
            // console.debug(error)
          }
         
        },
      },
    },
  });

  return (
    <>
      {' '}
      <Card {...other} ref={cardRef}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <IconButton onClick={handleMenuOpen} style={{ visibility: menuVisible ? 'visible' : 'hidden' }}>
              <MenuRoundedIcon />
            </IconButton>
          }
        />
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
          open={Boolean(anchorPosition)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDownloadXLSX}>
            <Typography variant="body2">ดาวน์โหลด Excel</Typography>
          </MenuItem>
          <MenuItem onClick={handleDownloadPng}>
            <Typography variant="body2">ดาวน์โหลด PNG</Typography>
          </MenuItem>
        </Menu>

        {chartLabels.length > 0 ? (
          <Box sx={{ mx: 3 }} dir="ltr">
            <ReactApexChart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={364} />
          </Box>
        ) : (
          <Box
            sx={{
              p: 3,
              height: 379,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f2f2f2',
              border: '20px solid white', // Add this line
              borderRadius: '35px',
              borderColor: 'white', // Add this line
              flexDirection: 'column', // Add this line
            }}
          >
            <img src="/assets/not_found.png" alt="No data available" style={{ width: '9em' }} />
            <Typography variant="subtitle1" sx={{ opacity: 0.72 }}>
              ยังไม่พบข้อมูล{title}ในขณะนี้
            </Typography>
          </Box>
        )}
      </Card>{' '}
    </>
  );
}
