/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link, useParams } from 'react-router-dom';
import _, { filter } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { QRCodeCanvas } from 'qrcode.react';
import { ColorPicker } from 'mui-color';
import "../../services/qrcode.css"
import * as yup from 'yup';
import html2canvas from "html2canvas";
// @mui
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { LoadingButton } from '@mui/lab';
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    Box,
    CardHeader,
    TextField,
    Grid,
    Item,
    DialogContentText,
    MenuItem,
    InputLabel
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// components
import { FileUpload } from '../../services/ImageUploader';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import { createCategory } from '../../redux/actions/categoryActions'
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// ----------------------------------------------------------------------
import { listTable, deleteTable } from '../../redux/actions/tableActions'
import { listTheme, updateTheme, createTheme } from '../../redux/actions/themeActions';
import { myShop } from '../../redux/actions/shopActions';

const TABLE_HEAD = [
    { id: 'name', label: 'ชื่อโต๊ะ', alignRight: false },
    { id: 'qrcode', label: 'คิวอาร์โค้ด', alignRight: false },
    { id: 'status', label: 'สถานะ', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (r) => r.name.th.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function CreateSetting() {
    const { id } = useParams();
    const { table, auth, shop } = useSelector((state) => state)
    const { tables, loading } = table
    const { branch, accessToken } = auth
    const { shopState } = shop
    const { error } = useSelector((state) => state.category);
    const { theme } = useSelector((state) => state.theme);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name.th');
    const [filterName, setFilterName] = useState('');
    const [limit, setLimit] = useState(25);

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmValue, setConfirmValue] = useState();
    const [file, setFile] = useState([]);
    const [colorText, setColorText] = useState('#000');

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (branch) {
            dispatch(listTable())
            dispatch(listTheme())
            dispatch(myShop({}))
        };
    }, [branch, dispatch])

    useEffect(() => {
        formik.setFieldValue('name', 'Theme Name');
        formik.setFieldValue('color_text', '#000');
        formik.setFieldValue('footer_text', 'ezorder.co');
        formik.setFieldValue('background_cover', '');
        formik.setFieldValue('size', 0);
        formik.setFieldValue('background_cover', { images: '', opacity: '1', color_overlay: 'transparent' });

    }, [theme]);

    const validationSchema = yup.object({
        name: yup.string()
            .min(2, 'โปรดระบุชื่อTheme (2-20 ตัวอักษร)')
            .max(20, 'ชื่อThemeเกินจำนวนที่สามารถรองรับ')
            .required('โปรดระบุชื่อTheme'),
        footer_text: yup.string()
            .min(2, 'โปรดระบุชื่อ (2-20 ตัวอักษร)')
            .max(20, 'ข้อความเกินจำนวนที่สามารถรองรับ'),
    });
    const certificateRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            name: '',
            color_text: '#000',
            footer_text: '',
            background_cover: { images: '', opacity: '', color_overlay: '' },
            size: 0,
            preview_theme_url: ''
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                let image = ''
                if (file.length !== 0) {
                    const fileUpload = await FileUpload(accessToken, branch, 'B', file);
                    image = fileUpload.url;
                } else if (values.background_cover.image) {
                    image = values.background_cover.image;
                }
                const data = {
                    name: values.name,
                    color: values.color_text,
                    footer_text: values.footer_text,
                    background_cover: JSON.stringify({ images: image, opacity: values.background_cover.opacity, color_overlay: values.background_cover.color_overlay }),
                    preview_theme_url: '',
                    size: values.size
                }
                dispatch(createTheme({ themeId: id, event: { ...data } }))
                if ((error) && Object.keys(error).length === 0 && error.constructor === Object) {
                    enqueueSnackbar('ผิดพลาด', { variant: 'error' });
                }
                enqueueSnackbar('เพิ่มรูปแบบสำเร็จ', { variant: 'success', anchorOrigin: { vertical: "top", horizontal: "right" } });
                // setSubmitting(false)
                navigate("/setting-qrcode")
            } catch (error) {
                // console.log(error)
                enqueueSnackbar('ผิดพลาด !', { variant: 'error' });
                // setSubmitting(false)
            }
        },
    });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = tables.map((n) => n._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, value) => {
        const selectedIndex = selected.indexOf(value);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setLimit(parseInt(event.target.value, 10));
    };
    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = tables.length === 0;
    const filteredCategory = applySortFilter(tables, getComparator(order, orderBy), filterName);
    const isNotFound = !filteredCategory.length && !!filterName;

    const handleRedirect = (route) => {
        navigate(route)
    }

    const handleDelete = value => {
        setConfirmDelete(true)
        setConfirmValue([value])
    }
    const handleConfirmDelete = () => {
        dispatch(deleteTable({ "tables": confirmValue || selected }))
        setConfirmDelete(false)
        setSelected([]);
    }

    const handleConfirmDeleteAll = () => {
        setConfirmDelete(true)
        setConfirmValue();
    }
    const printDocument = (domElement) => {
        html2canvas(domElement, {
            allowTaint: true,
            useCORS: true,
            width: formik.values.size === 0 && 396.8503937 || formik.values.size === 1 && 377.95275591 || formik.values.size === 2 && 453.54330709,
            height: formik.values.size === 0 && 559.37007874 || formik.values.size === 1 && 377.95275591 || formik.values.size === 2 && 283.46456693
        }).then((canvas) => {
            const image = canvas.toDataURL('png');
            const a = document.createElement('a');
            a.setAttribute('download', 'qrcodeTable.png');
            a.setAttribute('href', image);
            a.click();
        });
    };
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const onChangeImage = (e) => {
        if (!e.target.files) {
            return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            setFile(e.target.files[0]);
            setImagePreviewUrl(reader.result);
            formik.setFieldValue('background_cover.images', reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    }
    const onChangeColorText = (e) => {
        formik.setFieldValue('color_text', e.css.backgroundColor);
    }
    const onChangeOpacity = (e) => {
        formik.setFieldValue('background_cover.color_overlay', e.css.backgroundColor);
    }
    const handleFileChange = (e) => {
        if (!e.target.files) {
            return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            setFile(e.target.files[0]);
            formik.setFieldValue('background_cover.images', e.target.files[0]);
        };
        reader.readAsDataURL(e.target.files[0]);
    };
    return (
        <>
            <Helmet>
                <title> EzOrder Backoffice | จัดการโต๊ะ </title>
            </Helmet>
            <Container >
                <Stack className={'group_header_filter'} direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        จัดการรูปแบบ QRcode
                    </Typography>
                    <Button variant="contained" startIcon={''} onClick={() => printDocument(certificateRef.current)}>
                        ดาวน์โหลด QR ตัวอย่าง
                    </Button>
                </Stack>
                <Box sx={{ position: 'absolute', top: '-9999999px' }}>
                    {
                        formik.values.size === 0 && <div className={"nano "} style={{
                            width: "396.8503937px",
                            height: "559.37007874px", fontSize: 18, paddingTop: 40
                        }} ref={certificateRef}>
                            <div className='coverBG' style={{ backgroundImage: `url(${formik.values?.background_cover.images})`, zIndex: -1 }} />
                            <div className='coverBG' style={{ opacity: formik.values.background_cover.opacity, backgroundColor: formik.values.background_cover.color_overlay, zIndex: 0 }} />
                            <div>
                                {Object.keys(auth?.userInfo).length && <img src={auth?.userInfo?.branch[0].logo_image} alt='' height="50px" width={'100%'} />}
                            </div>
                            <div>
                                <div style={{ color: formik.values.color_text, lineHeight: 1 }}>{shopState?.name?.th}</div>
                                <div style={{ color: formik.values.color_text, lineHeight: 1 }}>{shopState?.name?.en}</div>
                                <div style={{ color: formik.values.color_text }}>โต๊ะ 1</div>
                            </div>
                            <div>
                                <div className='qrcodeBG' style={{ lineHeight: 1 }}> <QRCodeCanvas value="" size={140} /></div>
                            </div>
                            <div className={"copy_light"} style={{ position: 'relative', top: '-8px' }}>
                                <div style={{ color: formik.values.color_text, fontSize: 14, width: '100%' }}>{formik.values.footer_text}</div>
                            </div>
                            <div style={{ position: 'relative', top: '-8px' }}>
                                <div style={{ color: formik.values.color_text, fontSize: 30, lineHeight: 1 }}>สแกนเพื่อสั่งอาหาร <br /> Scan to order</div>
                                <div style={{ color: formik.values.color_text, fontSize: 14 }}>www.ez-order.co</div>
                            </div>
                        </div>
                    }
                    {
                        formik.values.size === 1 && <div className={"nano "} style={{ width: "377.95275591px", height: "377.95275591px", fontSize: '15px', paddingTop: "15px", gap: 10, whiteSpace: "nowrap" }} ref={certificateRef}>
                            <div className='coverBG' style={{ backgroundImage: `url(${formik.values?.background_cover.images})`, zIndex: -1 }} />
                            <div className='coverBG' style={{ opacity: formik.values.background_cover.opacity, backgroundColor: formik.values.background_cover.color_overlay, zIndex: 0 }} />
                            <div>
                                {Object.keys(auth?.userInfo).length && <img src={auth?.userInfo?.branch[0].logo_image} alt='' height="30px" width={'100%'} />}
                            </div>
                            <div>
                                <div style={{ color: formik.values.color_text, lineHeight: 1 }}>{shopState?.name?.th}</div>
                                <div style={{ color: formik.values.color_text, lineHeight: 1 }}>{shopState?.name?.en}</div>
                                <div style={{ color: formik.values.color_text, position: 'relative', top: 5 }}>โต๊ะ 1</div>
                            </div>
                            <div>
                                <div className='qrcodeBG' style={{ padding: "10px", paddingTop: 5 }}><QRCodeCanvas value="" size={120} /></div>
                            </div>
                            <div className={"copy_light"} style={{ position: 'relative', marginTop: '-8px' }}>
                                <div style={{ color: formik.values.color_text, fontSize: 14, width: '100%' }}>{formik.values.footer_text}</div>
                            </div>
                            <div style={{ position: 'relative', top: '-8px' }}>
                                <div style={{ color: formik.values.color_text, fontSize: 22, lineHeight: 1.2 }}>สแกนเพื่อสั่งอาหาร <br /> Scan to order</div>
                                <div style={{ color: formik.values.color_text, fontSize: 14 }}>www.ez-order.co</div>
                            </div>
                        </div>
                    }
                    {
                        formik.values.size === 2 && <div className={"nano  theme1"} style={{ width: "453.54330709px", height: "283.46456693px", fontSize: '16px', padding: "15px", gap: 15 }} ref={certificateRef}>
                            <div className='coverBG' style={{ backgroundImage: `url(${formik.values?.background_cover.images})`, zIndex: -1 }} />
                            <div className='coverBG' style={{ opacity: formik.values.background_cover.opacity, backgroundColor: formik.values.background_cover.color_overlay, zIndex: 0 }} />
                            <div className='theme1_col'>
                                <div>
                                    {Object.keys(auth?.userInfo).length && <img src={auth?.userInfo?.branch[0].logo_image} alt='' height="50px" width={'100%'} />}
                                </div>
                                <div>
                                    <div style={{ color: formik.values.color_text, fontSize: 16, paddingTop: 10 }}>{shopState?.name?.th}</div>
                                    <div style={{ color: formik.values.color_text, fontSize: 30, padding: " 30px 0" }}>โต๊ะ 1</div>
                                </div>
                                <div style={{ color: formik.values.color_text, fontSize: 14, position: 'relative', bottom: 15, width: '100%' }}>{formik.values.footer_text}</div>
                                <div style={{ color: formik.values.color_text, fontSize: 14, position: 'relative', bottom: 15 }}>www.ez-order.co</div>
                            </div>
                            <div className='theme1_col' style={{ gap: 10 }}>
                                <div style={{}}>
                                    <div className='qrcodeBG' style={{ lineHeight: 1 }}> <QRCodeCanvas value="" size={100} /></div>
                                </div>
                                <div style={{ color: formik.values.color_text, fontSize: 20, lineHeight: 1.2 }}>Scan to order  <br /> สแกนเพื่อสั่งอาหาร</div>

                            </div>
                        </div>
                    }
                </Box>
                <Grid container spacing={2} alignItems={'start'}>
                    <Grid item md={6} className={'group_form_preview'} sx={{
                        flex: 1,
                        justifyContent: "center",
                        display: 'flex',
                        position: 'sticky',
                        top: 150
                    }} >
                        {
                            formik.values.size === 0 && <div className={"nano preview_mm"} style={{
                                width: "396.8503937px",
                                height: "559.37007874px", fontSize: 18, paddingTop: 40
                            }} >
                                <div className='coverBG' style={{ backgroundImage: `url(${formik.values?.background_cover.images})`, zIndex: -1 }} />
                                <div className='coverBG' style={{ opacity: formik.values.background_cover.opacity, background: formik.values.background_cover.color_overlay, zIndex: 0, border: '1px dashed rgb(168 173 179 / 51%)' }} />

                                <div>
                                    {Object.keys(auth?.userInfo).length && <img src={auth?.userInfo?.branch[0].logo_image} alt='' height="50px" width={'100%'} />}
                                </div>
                                <div>
                                    <div style={{ color: formik.values.color_text, lineHeight: 1 }}>{shopState?.name?.th}</div>
                                    <div style={{ color: formik.values.color_text, lineHeight: 1 }}>{shopState?.name?.en}</div>
                                    <div style={{ color: formik.values.color_text }}>โต๊ะ 1</div>
                                </div>
                                <div>
                                    <div className='qrcodeBG' style={{ lineHeight: 1 }}> <QRCodeCanvas value="" size={140} /></div>
                                </div>
                                <div className={"copy_light"} style={{ position: 'relative', top: '-8px' }}>
                                    <div style={{ color: formik.values.color_text, fontSize: 14, width: '100%' }}>{formik.values.footer_text}</div>
                                </div>
                                <div style={{ position: 'relative', top: '-8px' }}>
                                    <div style={{ color: formik.values.color_text, fontSize: 30, lineHeight: 1 }}>สแกนเพื่อสั่งอาหาร <br /> Scan to order</div>
                                    <div style={{ color: formik.values.color_text, fontSize: 14 }}>www.ez-order.co</div>
                                </div>
                            </div>
                        }
                        {
                            formik.values.size === 1 && <div className={"nano preview_mm"} style={{ width: "377.95275591px", height: "377.95275591px", fontSize: '15px', paddingTop: "15px", gap: 10, whiteSpace: "nowrap", border: '1px dashed rgb(168 173 179 / 51%)' }} >
                                <div className='coverBG' style={{ backgroundImage: `url(${formik.values?.background_cover.images})`, zIndex: -1 }} />
                                <div className='coverBG' style={{ opacity: formik.values.background_cover.opacity, background: formik.values.background_cover.color_overlay, zIndex: 0 }} />
                                <div>
                                    {Object.keys(auth?.userInfo).length && <img src={auth?.userInfo?.branch[0].logo_image} alt='' height="30px" width={'100%'} />}
                                </div>
                                <div>
                                    <div style={{ color: formik.values.color_text, lineHeight: 1 }}>{shopState?.name?.th}</div>
                                    <div style={{ color: formik.values.color_text, lineHeight: 1 }}>{shopState?.name?.en}</div>
                                    <div style={{ color: formik.values.color_text, position: 'relative', top: 5 }}>โต๊ะ 1</div>
                                </div>
                                <div>
                                    <div className='qrcodeBG' style={{ padding: "10px", paddingTop: 5 }}><QRCodeCanvas value="" size={120} /></div>
                                </div>
                                <div className={"copy_light"} style={{ position: 'relative', marginTop: '-8px' }}>
                                    <div style={{ color: formik.values.color_text, fontSize: 14, width: '100%' }}>{formik.values.footer_text}</div>
                                </div>
                                <div style={{ position: 'relative', top: '-8px' }}>
                                    <div style={{ color: formik.values.color_text, fontSize: 22, lineHeight: 1.2 }}>สแกนเพื่อสั่งอาหาร <br /> Scan to order</div>
                                    <div style={{ color: formik.values.color_text, fontSize: 14 }}>www.ez-order.co</div>
                                </div>
                            </div>
                        }
                        {
                            formik.values.size === 2 && <div className={"nano preview_mm theme1"} style={{ width: "453.54330709px", height: "283.46456693px", fontSize: '16px', padding: "15px", gap: 15, border: '1px dashed rgb(168 173 179 / 51%)' }}   >
                                <div className='coverBG' style={{ backgroundImage: `url(${formik.values?.background_cover.images})`, zIndex: -1 }} />
                                <div className='coverBG' style={{ opacity: formik.values.background_cover.opacity, background: formik.values.background_cover.color_overlay, zIndex: 0 }} />
                                <div className='theme1_col'>
                                    <div>
                                        {Object.keys(auth?.userInfo).length && <img src={auth?.userInfo?.branch[0].logo_image} alt='' height="50px" width={'100%'} />}
                                    </div>
                                    <div>
                                        <div style={{ color: formik.values.color_text, fontSize: 16, paddingTop: 10 }}>{shopState?.name?.th}</div>
                                        <div style={{ color: formik.values.color_text, fontSize: 16, paddingTop: 10 }}>{shopState?.name?.en}</div>
                                        <div style={{ color: formik.values.color_text, fontSize: 30, padding: " 30px 0", lineHeight: 1 }}>โต๊ะ 1</div>
                                    </div>
                                    <div style={{ color: formik.values.color_text, fontSize: 14, position: 'absolute', bottom: 15 }}>www.ez-order.co</div>
                                </div>
                                <div className='theme1_col' style={{ gap: 10 }}>
                                    <div style={{}}>
                                        <div className='qrcodeBG' style={{ lineHeight: 1 }}> <QRCodeCanvas value="" size={100} /></div>
                                    </div>
                                    <div style={{ color: formik.values.color_text, fontSize: 20, lineHeight: 1.2 }}>Scan to order  <br /> สแกนเพื่อสั่งอาหาร</div>

                                </div>
                            </div>
                        }
                    </Grid>
                    <Grid item md={6} sx={{
                        flex: 1
                    }}>
                        <Card sx={{ pl: 3, pr: 3, pb: 3, pt: 3 }}>
                            <form onSubmit={formik.handleSubmit}>
                                <CardHeader title={"ชื่อรูปแบบ *"} action={
                                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                                        (2-20 ตัวอักษร)
                                    </Stack>
                                } />
                                <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                                    <FormControl fullWidth sx={{ "&:focus-within": { borderColor: '#e0e0e0' } }}>
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="ชื่อ Template *"
                                            placeholder='กรุณากรอกชื่อ Template *'
                                            type="text"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                        />
                                    </FormControl>
                                </Box>
                                <CardHeader title={"เลือกขนาด QR"} />
                                <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                                    <InputLabel>เลือกขนาด QR </InputLabel>
                                    <FormControl fullWidth>
                                        <Select id="size"
                                            name="size"
                                            label="เลือกขนาด QR"
                                            type="text"
                                            value={formik.values.size}
                                            onChange={formik.handleChange}
                                            error={formik.touched.size && Boolean(formik.errors.size)}
                                            helperText={formik.touched.size && formik.errors.size}>
                                            <MenuItem value={0}>4 x 6 cm</MenuItem>
                                            <MenuItem value={1}>10 x 10 cm</MenuItem>
                                            <MenuItem value={2}>12 x 7.5 cm</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                                <CardHeader title={"ข้อความส่วนล่าง "} action={
                                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                                        (2-20 ตัวอักษร)
                                    </Stack>
                                } />
                                <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                                    <FormControl fullWidth sx={{ "&:focus-within": { borderColor: '#e0e0e0' } }}>
                                        <TextField
                                            id="footer_text"
                                            name="footer_text"
                                            label="ข้อความส่วนล่าง "
                                            placeholder='กรุณากรอกข้อความส่วนล่าง'
                                            type="text"
                                            value={formik.values.footer_text}
                                            onChange={formik.handleChange}
                                            error={formik.touched.footer_text && Boolean(formik.errors.footer_text)}
                                            helperText={formik.touched.footer_text && formik.errors.footer_text}
                                        />
                                    </FormControl>
                                </Box>
                                <CardHeader title={"อัพโหลดรูปภาพพื้นหลัง"} />
                                <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                                    {formik.values?.background_cover.images !== '' && <img src={`${formik.values?.background_cover.images !== '' ? !imagePreviewUrl ? formik.values?.background_cover.images : imagePreviewUrl : '../../assets/illustrations/ezorder_login.png'}`} alt='asd' />}
                                    <br />
                                    <Button variant="contained" component="label">
                                        Upload
                                        <input hidden accept="image/*" type="file" onChange={(e) => onChangeImage(e)} />
                                    </Button>
                                </Box>
                                <CardHeader title={"สีตัวหนังสือ"} />
                                <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                                    <FormControl fullWidth>
                                        <ColorPicker defaultValue="transparent" value={formik.values.color_text} style={{ width: "100%" }} name="color_text" onChange={(color) => onChangeColorText(color)} />
                                    </FormControl>
                                </Box>

                                <CardHeader title={"เปลี่ยนสีพื้นหลัง"} />
                                <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                                    <FormControl fullWidth>
                                        <ColorPicker defaultValue="transparent" value={formik.values.background_cover.color_overlay} style={{ width: "100%" }} name="background_cover.color_overlay" onChange={(color) => onChangeOpacity(color)} />
                                    </FormControl>
                                </Box>



                                <Grid item xs={12} md={12} lg={12} sx={{ p: 3, pb: 0 }}>
                                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} spacing={1}>
                                        <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                                            บันทึก
                                        </LoadingButton>
                                    </Stack>
                                </Grid>
                            </form>
                        </Card>
                    </Grid>
                </Grid>

            </Container>

            <Dialog
                open={confirmDelete}
                onClose={() => setConfirmDelete(!confirmDelete)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"แจ้งเตือนระบบ"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        คุณต้องการที่จะลบจัดการโต๊ะนี้หรือไม่?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDelete(!confirmDelete)}>ยกเลิก</Button>
                    <Button onClick={() => handleConfirmDelete()} autoFocus>
                        ตกลง
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
