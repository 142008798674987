import { baseURL } from './var';

export const FileUpload = async (token, branch, type, file) => {
  const formData = new FormData();
  formData.append('image', file, URL.createObjectURL(file));

  const requestOptions = {
    method: 'POST',
    body: formData,
    redirect: 'follow',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      Branch: branch,
      Type: type
    },
  };
  return fetch(`${baseURL}/v1/upload`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
};
