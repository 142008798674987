/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  Box,
  TableFooter,
  Collapse,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';

// components

import userData from '../../_mock/shop_users.json';

import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar, UserListFilterTool } from '../../sections/@dashboard/user';
// ----------------------------------------------------------------------
import { listProduct, deleteProduct, displayOrderProduct } from '../../redux/actions/productActions';

const TABLE_HEAD = [
  { id: 'date', label: 'วันที่', alignRight: false },
  { id: 'invoice_number', label: 'เลขที่บิล', alignRight: false },
  { id: 'sales_amout', label: 'ยอดขาย', alignRight: false },
  { id: 'status', label: 'สถานะ', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (r) =>
        r.name.th.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (r.name.en && r.name.en?.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ReportBillPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name.th');
  const [filterName, setFilterName] = useState('');
  const [limit, setLimit] = useState(25);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmValue, setConfirmValue] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const handleDateStartChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleDateEndChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleSubmitDate = () => {

  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = userData.map((n) => n._id.$oid);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDelete = (value) => {
    setConfirmDelete(true);
    setConfirmValue([value]);
  };

  const handleConfirmDelete = () => {
    // dispatch(deleteProduct({ products: confirmValue || selected }));
    setConfirmDelete(false);
    setSelected([]);
  };

  const handleConfirmDeleteAll = () => {
    setConfirmDelete(true);
    setConfirmValue();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleClick = (event, value) => {
    const selectedIndex = selected.indexOf(value);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleRedirect = (route) => {
    navigate(route);
  };

  // const filteredProduct = applySortFilter(products, getComparator(order, orderBy), filterName);
  const filteredProduct = '';
  // const emptyRows = products.length === 0;
  const emptyRows = true;

  const handleDragEnd = async (result) => {};
  const useStyles = makeStyles((theme) => ({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 'bold',
      transition: 'color .3s',
      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.secondary.main,
      },
    },
    detailTable: {
      border: '15px solid rgba(244, 246, 248, 0.5)',

      borderBottom: 'none',
    },
    normalRowTable: {
      border: '15px solid rgba(244, 246, 248, 0.5)',
      borderBottom: 'none',
      borderTop: 'none',
    },
    endRowTable: {
      border: '15px solid rgba(244, 246, 248, 0.5)',
      borderTop: 'none',
    },
  }));

  const classes = useStyles();
  // คำนวณยอดขายรวม

  // const totalSales = userData.reduce((acc, cur) => acc + cur.total_sales, 0);
  const totalSales = 10522;

  // คำนวณจำนวนบิลที่ขายรวม
  // const totalBill = userData.reduce((acc, cur) => acc + cur.bill_amount, 0);
  const totalBill = 2812;

  const [openDetail, setOpenDetail] = useState(null);
  // const [openDetail, setOpenDetail] = useState(null);

  const handleOpenDetail = (id) => {
    if (openDetail === id) {
      setOpenDetail(null);
    } else {
      setOpenDetail(id);
    }
  };
  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | รายงานยอดขายรายบิล </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            รายงานยอดขายรายบิล
          </Typography>
        </Stack>

        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} onDelete={() => handleConfirmDeleteAll()} /> */}
          <UserListFilterTool
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onDelete={() => handleConfirmDeleteAll()}
            onDateStartChange={handleDateStartChange}
            onDateEndChange={handleDateEndChange}
            onSubmitDate={handleSubmitDate}
            displayType={'daily'}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userData.length}
                  numSelected={selected.length}
                  // onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table-body">
                    {(provided, snapshot) => (
                      <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                        {userData.slice(page * limit, page * limit + limit).map((row, index) => {
                          const {
                            _id,
                            email,
                            first_name,
                            is_delete,
                            is_enable,
                            is_owner_account,
                            last_name,
                            updatedAt,
                            items,
                          } = row;
                          const selectedUser = selected.indexOf(_id.$oid) !== -1;
                          const editLink = `/manage-user/${_id.$oid}`;

                          return (
                            <Draggable key={_id.$oid} draggableId={_id.$oid} index={index}>
                              {(provided) => (
                                <>
                                  <TableRow
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    hover
                                    key={_id.$oid}
                                    tabIndex={-1}
                                    role="checkbox"
                                    selected={selectedUser}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        checked={selectedUser}
                                        onChange={(event) => handleClick(event, _id.$oid)}
                                      />
                                    </TableCell>

                                    <TableCell scope="row" padding="none">
                                      <Typography variant="subtitle2" noWrap>
                                        <Link underline="hover" to={editLink} className={classes.link}>
                                          {first_name}
                                        </Link>
                                      </Typography>
                                    </TableCell>

                                    <TableCell align="left">
                                      <Typography variant="subtitle2" noWrap>
                                        {email}
                                      </Typography>
                                    </TableCell>

                                    <TableCell align="left">{`${is_owner_account ? 'Owner' : 'Staff'}`}</TableCell>

                                    <TableCell align="left">
                                      <Stack direction="row" alignItems="center" spacing={1}>
                                        <Label color={(!is_enable && 'error') || 'success'}>
                                          {(!is_enable && 'Disabled') || 'Enabled'}
                                        </Label>
                                      </Stack>
                                    </TableCell>
                                    <TableCell align="center">
                                      <IconButton onClick={() => handleOpenDetail(_id.$oid)} component="label">
                                        <Iconify
                                          icon={'eva:arrow-ios-downward-outline'}
                                          style={{
                                            transform: openDetail === _id.$oid ? 'rotate(180deg)' : 'none',
                                            transition: 'transform 0.3s ease-in-out',
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan={6} style={{ padding: 0 }}>
                                      <Collapse in={openDetail === _id.$oid}>
                                        <TableContainer>
                                          <Table>
                                            <TableBody>
                                              <TableRow className={classes.detailTable}>
                                                <TableCell align="center">
                                                  <b>#</b>
                                                </TableCell>

                                                <TableCell>
                                                  <b>รายการ</b>
                                                </TableCell>

                                                <TableCell alignRight>
                                                  <b>จำนวน</b>
                                                </TableCell>
                                                <TableCell>
                                                  <b>ราคา</b>
                                                </TableCell>
                                                <TableCell>
                                                  <b>ราคารวม</b>
                                                </TableCell>
                                                <TableCell>
                                                  <b>สถานะ</b>
                                                </TableCell>
                                              </TableRow>
                                              {items.map((item, index) => {
                                                return (
                                                  <TableRow
                                                    key={index}
                                                    className={
                                                      index === items.length - 1
                                                        ? classes.endRowTable
                                                        : classes.normalRowTable
                                                    }
                                                    style={{ borderRadius: 5 }}
                                                  >
                                                    <TableCell
                                                      className={classes.tableCell}
                                                      style={{ fontWeight: 'bold', textAlign: 'center' }}
                                                    >
                                                      {/* <Paper className={classes.roundCell}>{index + 1}</Paper> */}
                                                      <Box className={classes.roundedBox}>{index + 1}</Box>
                                                    </TableCell>

                                                    <TableCell className={classes.tableCell}>
                                                      <Box className={classes.roundedBox}>{item.name}</Box>
                                                    </TableCell>

                                                    <TableCell>{item.quantity}</TableCell>
                                                    <TableCell>{item.price}</TableCell>
                                                    <TableCell>{item.total_price}</TableCell>
                                                    <TableCell>
                                                      <Label color={item.status === 'สำเร็จ' ? 'success' : 'error'}>
                                                        {item.status === 'สำเร็จ' ? 'สำเร็จ' : 'ล้มเหลว'}
                                                      </Label>
                                                    </TableCell>
                                                  </TableRow>
                                                );
                                              })}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}
                            </Draggable>
                          );
                        })}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      {' '}
                      <Typography variant="subtitle1">รวม</Typography>
                    </TableCell>
                    <TableCell align="right"> </TableCell>
                    <TableCell align="left">
                      <Typography variant="subtitle1">{totalBill}</Typography>
                    </TableCell>

                    <TableCell align="left">
                      <Typography variant="subtitle1">{totalSales}</Typography>
                    </TableCell>
                    <TableCell> </TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={userData.length}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(!confirmDelete)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'แจ้งเตือนระบบ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">คุณต้องการที่จะลบเมนูสินค้านี้หรือไม่?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(!confirmDelete)}>ยกเลิก</Button>
          <Button onClick={() => handleConfirmDelete()} autoFocus>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
