import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack } from '@mui/material';

// components
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppSummaryByDate,
  AppWebsiteVisits,
  AppCurrentVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
// ----------------------------------------------------------------------
import { overview } from '../redux/actions/reportActions';

export default function DashboardPage() {
  const theme = useTheme();
  const { branch } = useSelector((state) => state.auth);
  const { dashboard } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const hasData = true;
  // const hasData = !!dashboard?.summary;

  useEffect(() => {
    if (branch) dispatch(overview());
  }, [branch, dispatch]);

  return (
    <>
      <Helmet>
        <title> Dashboard | EzOrder Backoffice </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          ภาพรวม
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="ยอดขายสุทธิ"
              color="info"
              total={dashboard?.summary?.total_amount || 0}
              icon={'material-symbols:show-chart'}
              hasData={hasData}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="ยอดออเดอร์"
              total={dashboard?.summary?.total_bill || 0}
              color="info"
              icon={'circum:receipt'}
              hasData={hasData}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="มูลค่าเฉลี่ยต่อการสั่งซื้อ"
              total={dashboard?.summary?.total_avg_amount || 0}
              color="error"
              icon={'solar:pie-chart-2-bold'}
              hasData={hasData}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppSummaryByDate
              title="ยอดขายแยกตามช่วงเวลา"
              chartLabels={dashboard?.net_sale_hour?.map((i) => i.label) || []}
              chartData={[
                {
                  name: 'ยอดขาย',
                  type: 'line',
                  fill: 'solid',
                  data: dashboard?.net_sale_hour?.map((i) => i.value) || [],
                },
              ]}
              hasData={hasData}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppSummaryByDate
              title="ยอดขายแยกตามช่วงวัน"
              chartLabels={dashboard?.net_sale_week?.map((i) => i.label) || []}
              chartData={[
                {
                  name: 'ยอดขาย',
                  type: 'column',
                  fill: 'solid',
                  data: dashboard?.net_sale_week?.map((i) => i.value) || [],
                },
              ]}
              hasData={hasData}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="สินค้าขายดี 10 รายการแรก"
              chartData={dashboard?.top_10_products || []}
              hasData={hasData}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="ยอดขายแยกตามประเภทการชำระเงิน"
              chartData={dashboard?.net_sale_payment_type || []}
              chartColors={['#008FFB', '#E29F25', theme.palette.warning.main, theme.palette.error.main]}
              hasData={hasData}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
