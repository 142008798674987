import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseURL } from '../../services/var'
import { userRefresh } from './authActions'

export const listMasterAttribute = createAsyncThunk('master/attribute',
    async (arg, { getState, rejectWithValue, dispatch }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/attribute/select`, config)
            return data
        } catch (error) {
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const listAttribute = createAsyncThunk('management/attribute',
    async (arg, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/attribute`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const createAttribute = createAsyncThunk('management/createAttribute',
    async (event, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/attribute`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const updateAttribute = createAsyncThunk('management/updateAttribute',
    async ({ attributeId, event }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.put(`${baseURL}/v1/attribute/${attributeId}`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const deleteAttribute = createAsyncThunk('management/deleteAttribute',
    async (event, { dispatch, getState, rejectWithValue }) => {
        try {

            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/attribute/delete`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading && error.response.data.message !== "Unauthorized") dispatch(userRefresh())
                
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const displayOrderAttribute = createAsyncThunk('management/displayOrderAttribute',
    async (event, { dispatch, getState, rejectWithValue }) => {
        try {

            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/attribute/display`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

