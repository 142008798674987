import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseURL } from '../../services/var'
import { userRefresh } from './authActions'

export const overview = createAsyncThunk('report/overview',
    async (arg, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/report/overview`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const summarySale = createAsyncThunk('report/summarySale',
    async ({ page, limit, dateFrom, dateTo, search }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/report/summary-sale?page=${page}&limit=${limit}&dateFrom=${dateFrom}&dateTo=${dateTo}${search ? `&search=${search}` : ''}`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const exportSummarySale = createAsyncThunk('report/exportSummarySale',
    async ({ dateFrom, dateTo }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/report/summary-sale/export?dateFrom=${dateFrom}&dateTo=${dateTo}`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const summarySaleWithProduct = createAsyncThunk('report/summarySale/product',
    async ({ page, limit, dateFrom, dateTo, search }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/report/summary-sale/product?page=${page}&limit=${limit}&dateFrom=${dateFrom}&dateTo=${dateTo}${search ? `&search=${search}` : ''}`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const exportSummarySaleWithProduct = createAsyncThunk('report/exportSummarySaleWithProduct',
    async ({ dateFrom, dateTo }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/report/summary-sale/product/export?dateFrom=${dateFrom}&dateTo=${dateTo}`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const summarySaleWithCategory = createAsyncThunk('report/summarySale/category',
    async ({ page, limit, dateFrom, dateTo, search }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/report/summary-sale/category?page=${page}&limit=${limit}&dateFrom=${dateFrom}&dateTo=${dateTo}${search ? `&search=${search}` : ''}`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const exportSummarySaleWithCategory = createAsyncThunk('report/exportSummarySaleWithCategory',
    async ({ dateFrom, dateTo }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/report/summary-sale/category/export?dateFrom=${dateFrom}&dateTo=${dateTo}`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)