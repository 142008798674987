import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseURL } from '../../services/var'
import { userRefresh } from './authActions'

export const listBranch = createAsyncThunk('management/branch',
    async (arg, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/branch`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const detailBranch = createAsyncThunk('management/detailbranch',
    async ({ branchId }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.get(`${baseURL}/v1/branch/${branchId}`, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const createBranch = createAsyncThunk('management/createBranch',
    async (event, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/branch`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const updateBranch = createAsyncThunk('management/updateBranch',
    async ({ branchId, event }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.put(`${baseURL}/v1/branch/${branchId}`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading) dispatch(userRefresh())
                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

export const deleteBranch = createAsyncThunk('management/deleteBranch',
    async (event, { dispatch, getState, rejectWithValue }) => {
        try {

            const state = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${state.auth.accessToken}`,
                    'speedy-branch': `${state.auth.branch}`
                },
            }
            const { data } = await axios.post(`${baseURL}/v1/branch/delete`, event, config)
            return data
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                const state = getState();
                if (error.response.status === 401 && !state.auth.loading && error.response.data.message !== "Unauthorized") dispatch(userRefresh())

                return rejectWithValue(error.response.data.message)
            }
            return rejectWithValue(error.message)

        }
    }
)

