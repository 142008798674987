import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import _ from 'lodash';
import Cropper from 'react-easy-crop';
import '../../style/productCreate.css';

// @mui
import {
  Avatar,
  InputAdornment,
  Card,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  List,
  CardHeader,
  Button,
  Stack,
  ListItem,
  Switch,
  ListItemText,
  IconButton,
  Divider,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';

import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { createProduct } from '../../redux/actions/productActions';

// components
import Iconify from '../../components/iconify';
import ModalAttribute from '../../components/modal/attribute';
import getCroppedImg from '../../utils/cropImage';
// service
import { FileUpload } from '../../services/ImageUploader';


const NumberFormatCustom = React.forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            // eslint-disable-next-line react/prop-types
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale="2"
      maxLength={8}
    />
  );
});

const validationSchema = yup.object({
  // กรุณาเพิ่มรูปสินค้าอย่างน้อย 1 รูป
  // image: yup.string()
  //   .required('กรุณาเพิ่มรูปเมนู'),
  name: yup.object().shape({
    th: yup
      .string()
      .min(2, 'โปรดระบุชื่อเมนู (2-150 ตัวอักษร)')
      .max(150, 'ชื่อเมนูเกินจำนวนที่สามารถรองรับ')
      .required('โปรดระบุชื่อเมนู'),
    en: yup.string().min(2, 'โปรดระบุชื่อเมนู (2-150 ตัวอักษร)').max(150, 'ชื่อเมนูเกินจำนวนที่สามารถรองรับ'),
    // zh: yup.string()
    //   .min(2, 'โปรดระบุชื่อเมนู (2-100 ตัวอักษร)')
    //   .max(100, 'ชื่อเมนูเกินจำนวนที่สามารถรองรับ')
    //   .required('โปรดระบุชื่อเมนู'),
  }),
  description: yup.object().shape({
    th: yup.string().max(300, 'รายละเอียดสินค้าเกินจำนวนที่สามารถรองรับ'),
    en: yup.string().max(300, 'รายละเอียดสินค้าเกินจำนวนที่สามารถรองรับ'),
  }),
  category: yup.string().required('โปรดเลือกประเภทเมนู'),
  price: yup.number().required('โปรดระบุราคา'),
  discount: yup.number().test('check-discount', 'ส่วนลดต้องน้อยกว่าราคาสินค้า', function (value) {
    const currentPrice = Number(this.parent.price || 0);
    if (currentPrice > 0 && value > 0) {
      return value < this.parent.price;
    }
    return true;
  }),
});

export default function Create() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { accessToken, branch } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.product);

  const { masterCategories } = useSelector((state) => state.category);
  const { attributes } = useSelector((state) => state.attribute);
  const { options } = useSelector((state) => state.option);

  const [file, setFile] = useState();
  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const [totalPrice, setTotalPrice] = useState();
  // ! veevidev
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropImg, setCropImg] = useState();
  const [isSizeValid, setIsSizeValid] = useState(true);
  const [isFileValid, setIsFileValid] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const _croppedImage = await getCroppedImg(cropImg, croppedAreaPixels, 0);
      setImagePreviewUrl(URL.createObjectURL(_croppedImage));
      setPopupOpen(false);
      setFile(_croppedImage);
    } catch (e) {
      console.error(e);
    }
    enqueueSnackbar('เพิ่มรูปภาพสำเร็จ', {
      variant: 'success',
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  }, [croppedAreaPixels]);

  useEffect(() => {
    if (!isSizeValid) {
      setTimeout(() => {
        setIsSizeValid(true);
      }, 4000);
    }
  }, [isSizeValid]);

  useEffect(() => {
    if (!isFileValid) {
      setTimeout(() => {
        setIsFileValid(true);
      }, 4000);
    }
  }, [isFileValid]);

  const handleInputClick = (e) => {
    e.target.value = '';
  };

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    const img = new Image();
    const reader = new FileReader();
    reader.onloadend = () => {
      img.src = reader.result;
      img.onload = function () {
        const height = img.height;
        const width = img.width;
        const size = e.target.files[0].size;
        const checkFileSize = size <= 2097152;
        const checkRatio = height === width;
        const checkMinHeightWidth = height >= 360 && height >= 360;

        if (checkFileSize && checkMinHeightWidth && checkRatio) {
          enqueueSnackbar('เพิ่มรูปภาพสำเร็จ', {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          setImagePreviewUrl(reader.result);
          setFile(e.target.files[0]);
        } else {
          setCropImg(reader.result);
          setPopupOpen(true);
        }

      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  // ! end of veevidev
  const formik = useFormik({
    initialValues: {
      image: '',
      name: { th: '' },
      description: {},
      category: '',
      tags: [],
      attributes: [],
      price: '',
      discount: '',
      is_recommend: false,
      is_out_stock: false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let image = {};
        if (file) {
          const fileUpload = await FileUpload(accessToken, branch, 'P', file);
          image = { image_url: fileUpload.url };
        }

        const newAttributes = [];
        values.attributes.forEach((item) => {
          newAttributes.push(item._id);
        });

        const newTags = _.map(values.tags, '_id');

        const data = {
          name: values.name,
          description: values.description,
          category: values.category,
          attributes: newAttributes,
          tags: newTags,
          price: values.price,
          discount: values.discount,
          is_recommend: values.is_recommend,
          is_out_stock: values.is_out_stock,
          ...image,
        };

        dispatch(createProduct({ ...data }));
        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar('โปรดตรวจสอบอีกครั้ง', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          return
        }
        enqueueSnackbar('เพิ่มเมนูเรียบร้อย', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
        navigate('/product');
      } catch (error) {
        enqueueSnackbar('โปรดตรวจสอบอีกครั้ง', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
      }
    },
  });

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    if (formik.values.discount) {
      const total = parseFloat(formik.values.price) - parseFloat(formik.values.discount);
      setTotalPrice(total);
    }
  }, [formik.values.discount, formik.values.price]);

  const handleBack = () => {
    navigate('/product');
  };

  const [openAttribute, setOpenAttribute] = useState(false);
  const [attributeState, setAttributeState] = useState([]);
  const handleCloseAttribute = () => {
    setOpenAttribute(false);
    setAttributeState([]);
  };
  const handleOpenAttribute = (value) => {
    setAttributeState(value);
    setOpenAttribute(true);
  };

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | เพิ่มรายการเมนู </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">สร้างเมนูใหม่</Typography>
        </Stack>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <Grid container>
                  <Grid item xs={12} md={6} lg={12}>
                    <Paper
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 4,
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        sx={{
                          pb: 0,
                          mb: 4,
                          border: '1px dashed rgba(145, 158, 171, 0.32)',
                          borderRadius: '10px',
                          width: '170px',
                          height: '170px',
                          display: 'flex',
                          alignItems: 'center',
                          p: 5,
                          justifyContent: 'center',
                        }}
                        dir="ltr"
                      >
                        <Avatar
                          sx={{
                            width: '145px',
                            height: '145px',
                            fontSize: '14px',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#F7F9FA',
                            cursor: 'pointer',
                            '&:hover': {
                              opacity: 0.7,
                            },
                          }}
                          onClick={handleUploadClick}
                          alt="รูปภาพเมนู"
                          variant="square"
                          src={imagePreviewUrl || 'upload-icon-path'}
                          className={isSizeValid && isFileValid ? '' : 'invalidImg'}
                        >
                          {!imagePreviewUrl && (
                            <>
                              <UploadIcon sx={{ fontSize: '35px', color: '#B1BAC3' }} />
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                sx={{ display: 'block', textAlign: 'center' }}
                              >
                                Click to upload
                              </Typography>
                            </>
                          )}
                        </Avatar>
                      </Box>
                      <Typography variant="body2" fontSize={12} textAlign={'center'}>
                        รองรับ *.jpeg, *.jpg, *.png, *.gif <br />
                      </Typography>
                      <Typography variant="body2" fontSize={12} className={isSizeValid ? '' : 'invalid'}>
                        ขนาดรูปภาพขั้นต่ำคือ 360 x 360 พิกเซล
                      </Typography>
                      <Typography variant="body2" fontSize={12} className={isFileValid ? '' : 'invalid'}>
                        ขนาดไฟล์สูงสุด 2 MB
                      </Typography>
                    </Paper>

                    <Box sx={{ p: 0, pb: 0 }} dir="ltr">
                      {' '}
                      {/* Change is here */}
                      <input
                        type="file"
                        accept="image/*"
                        ref={inputRef}
                        onChange={handleFileChange}
                        onClick={handleInputClick}
                        style={{ display: 'none' }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            {/* รายละเอียดเมนู (ไทย) */}
            <Grid item xs={12} md={6} lg={8}>
              <Card>
                <CardHeader title={'รายละเอียดเมนู'} />
                <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                  <FormControl fullWidth>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                      (2-150 ตัวอักษร)
                    </Stack>
                    <TextField
                      id="name.th"
                      name="name.th"
                      label="ชื่อเมนู *"
                      type="text"
                      onChange={formik.handleChange}
                      error={formik.touched.name?.th && Boolean(formik.errors.name?.th)}
                      helperText={formik.touched.name?.th && formik.errors.name?.th}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                  <FormControl fullWidth>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                      (0-300 ตัวอักษร)
                    </Stack>
                    <TextField
                      id="description.th"
                      name="description.th"
                      label="รายละเอียด"
                      multiline
                      rows={3}
                      onChange={formik.handleChange}
                      error={formik.touched.description?.th && Boolean(formik.errors.description?.th)}
                      helperText={formik.touched.description?.th && formik.errors.description?.th}
                    />
                  </FormControl>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {' '}
            </Grid>
            {/* รายละเอียดเมนู (อังกฤษ) */}

            <Grid item xs={12} md={12} lg={8}>
              <Card>
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1a-header"
                    sx={{ pt: 0, pr: 2, pl: 2, pb: 0 }}
                  >
                    <CardHeader sx={{ p: 1 }} title={'รายละเอียดเมนู (อังกฤษ)'} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ pt: 0, pr: 1, pl: 1, pb: 3 }} dir="ltr">
                      <FormControl fullWidth>
                        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                          (2-150 ตัวอักษร)
                        </Stack>
                        <TextField
                          id="name.en"
                          name="name.en"
                          label="ชื่อเมนู (อังกฤษ)"
                          type="text"
                          onChange={formik.handleChange}
                          error={formik.touched.name?.en && Boolean(formik.errors.name?.en)}
                          helperText={formik.touched.name?.en && formik.errors.name?.en}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ pr: 1, pl: 1, pb: 1 }} dir="ltr">
                      <FormControl fullWidth>
                        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                          (0-300 ตัวอักษร)
                        </Stack>
                        <TextField
                          id="description.en"
                          name="description.en"
                          label="รายละเอียด (อังกฤษ)"
                          multiline
                          rows={3}
                          onChange={formik.handleChange}
                          error={formik.touched.description?.en && Boolean(formik.errors.description?.en)}
                          helperText={formik.touched.description?.en && formik.errors.description?.en}
                        />
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {' '}
            </Grid>
            {/* ประเภทเมนู */}
            <Grid item xs={12} md={12} lg={8}>
              <Card>
                {masterCategories.length <= 0 ? <CardHeader title="ประเภทเมนู" action={
                  <Button
                    fullWidth
                    variant="outlined"
                    size="medium"
                    startIcon={<AddCircleOutlineRoundedIcon />}
                    onClick={() => navigate('/category/create')}
                  >
                    เพิ่มประเภทเมนู
                  </Button>
                } /> : <CardHeader title="ประเภทเมนู" />}
                <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                  {masterCategories.length > 0 && (
                    <Autocomplete
                      id="category"
                      options={masterCategories}
                      getOptionLabel={(option) => option.name?.th || ''}
                      onChange={(e, value) => formik.setFieldValue('category', value?._id || '')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={formik.touched.category && Boolean(formik.errors.category)}
                          helperText={formik.touched.category && formik.errors.category}
                          label="ตัวเลือกเมนู *"
                          placeholder="ระบุตัวเลือกเมนู"
                        />
                      )}
                    />
                  )}
                </Box>
              </Card>
            </Grid>
            {/* แท็กเมนู */}
            {/* <Grid item xs={12} md={6} lg={6}>
              <Card>
                <CardHeader title="แท็กเมนู" />
                <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                  <Autocomplete
                    id="tagSelect"
                    multiple
                    options={tags}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                          // toggleOpen(true);
                          // setDialogValue({
                          //   title: newValue,
                          //   year: '',
                          // });
                        });
                      } else if (newValue && newValue.inputValue) {
                        // toggleOpen(true);
                        // setDialogValue({
                        //   title: newValue.inputValue,
                        //   year: '',
                        // });
                      } else {
                        formik.setFieldValue('tags', newValue || '');
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `เพิ่ม "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    freeSolo
                    clearOnBlur
                    handleHomeEndKeys
                    filterSelectedOptions
                    renderInput={(params) => <TextField {...params} label="แท็กเมนู" placeholder="ระบุแท็กเมนู" />}
                  />
                </Box>
              </Card>
            </Grid> */}
            <Grid item xs={12} md={6} lg={4}>
              {' '}
            </Grid>
            {/* ราคาเมนู */}
            <Grid item xs={12} md={12} lg={8}>
              <Card>
                <CardHeader title="ราคาเมนู" />
                <Box sx={{ p: 3, pb: 0 }} dir="ltr">
                  <TextField
                    fullWidth
                    placeholder="0.00"
                    label="ราคา *"
                    name="price"
                    id="price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      startAdornment: <InputAdornment position="start">฿</InputAdornment>,
                    }}
                  />
                </Box>

                <Box sx={{ p: 3, pb: (formik.values.discount || 0) === 0 ? 3 : 0 }} dir="ltr">
                  <TextField
                    placeholder="0.00"
                    fullWidth
                    label="ส่วนลด"
                    name="discount"
                    id="discount"
                    value={formik.values.discount}
                    onChange={formik.handleChange}
                    error={formik.touched.discount && Boolean(formik.errors.discount)}
                    helperText={formik.touched.discount && formik.errors.discount}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      startAdornment: <InputAdornment position="start">฿</InputAdornment>,
                    }}
                  />
                </Box>

                {formik.values.discount && (
                  <Box sx={{ p: 3, pt: 1, pb: 3 }} dir="ltr">
                    <Typography>
                      ราคาขาย:{' '}
                      {totalPrice || totalPrice === 0 ? (
                        <>
                          <NumericFormat
                            style={{
                              color: Number(formik.values.discount) !== 0 && formik.values.discount ? 'red' : 'black',
                            }}
                            displayType="text"
                            value={totalPrice}
                            thousandSeparator=","
                            decimalSeparator="."
                            decimalScale="2"
                            prefix="฿"
                          />
                          {Number(formik.values.discount) !== 0 && formik.values.discount ? (
                            <del>
                              <NumericFormat
                                style={{ marginLeft: '5px' }}
                                displayType="text"
                                value={formik.values.price}
                                thousandSeparator=","
                                decimalSeparator="."
                                decimalScale="2"
                                prefix="฿"
                              />
                            </del>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Box>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {' '}
            </Grid>
            {/* ตัวเลือกเมนู */}
            <Grid item xs={12} md={12} lg={8}>
              <Card>
                <CardHeader
                  action={
                    <Button
                      fullWidth
                      variant="outlined"
                      size="medium"
                      startIcon={<AddCircleOutlineRoundedIcon />}
                      onClick={() => handleOpenAttribute([])}
                    >
                      เพิ่มตัวเลือก
                    </Button>
                  }
                  title="ตัวเลือกสินค้า"
                />
                <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                  <Autocomplete
                    id="attributes"
                    limitTags={3}
                    multiple
                    options={attributes}
                    getOptionLabel={(option) => option.name?.th || ''}
                    onChange={(e, value) => formik.setFieldValue('attributes', value || '')}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    value={formik.values.attributes || []}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderInput={(params) => <TextField {...params} label="ตัวเลือกเมนู" placeholder="ตัวเลือกเมนู" />}
                  />
                  <List variant="primary" dense>
                    {formik.values.attributes.length > 0 &&
                      formik.values.attributes.map((attribute, index) => {
                        let showOption = '';
                        attribute.options.forEach((option) => {
                          const masterOption = options.find((o) => o._id === option._id);
                          if (!masterOption) return;
                          if (showOption !== '') showOption += ',';
                          showOption += masterOption?.name?.th;
                        });
                        return (
                          <Box key={index}>
                            {index > 0 && <Divider />}
                            <ListItem
                              secondaryAction={
                                <>
                                  <IconButton
                                    edge="end"
                                    aria-label="edit"
                                    onClick={() => handleOpenAttribute(attribute)}
                                  >
                                    <Iconify icon={'eva:edit-fill'} />
                                  </IconButton>
                                </>
                              }
                            >
                              <ListItemText primary={attribute.name?.th} secondary={showOption} />
                            </ListItem>
                          </Box>
                        );
                      })}
                  </List>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {' '}
            </Grid>
            {/* เมนูแนะนำ */}
            <Grid item xs={12} md={12} lg={8}>
              <Card sx={{ pb: 3 }}>
                <CardHeader
                  title="⭐ เมนูแนะนำ"
                  action={
                    <Switch
                      id="recommend"
                      inputProps={{ 'aria-label': 'controlled' }}
                      onChange={(e, value) => formik.setFieldValue('is_recommend', value || false)}
                    />
                  }
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {' '}
            </Grid>
            {/* สินค้าหมด */}
            <Grid item xs={12} md={12} lg={8}>
              <Card sx={{ pb: 3 }}>
                <CardHeader
                  title="สินค้าหมด"
                  action={
                    <Switch
                      id="out_stock"
                      inputProps={{ 'aria-label': 'controlled' }}
                      onChange={(e, value) => formik.setFieldValue('is_out_stock', value || false)}
                    />
                  }
                />
              </Card>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} spacing={1}>
                <Button variant="outlined" onClick={() => handleBack()}>
                  ยกเลิก
                </Button>

                <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                  บันทึก
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
        {openAttribute && (
          <ModalAttribute openModel={openAttribute} handleClose={() => handleCloseAttribute()} props={attributeState} />
        )}
      </Container>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        aria-labelledby="crop-dialog-title"
        aria-describedby="crop-dialog-description"
        PaperProps={{
          sx: {
            height: '80%',
            width: '90%',
            maxWidth: '800px',
          },
        }}
      >
        <DialogTitle id="crop-dialog-title">ครอปรูปภาพ</DialogTitle>
        <DialogContent dividers style={{ position: 'relative' }}>
          <Grid container style={{ height: 'calc(100% - 50px)', overflow: 'auto' }}>
            <Grid item xs={12} md={12} lg={12}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                <Cropper
                  image={cropImg}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setPopupOpen(false)} startIcon={<Iconify icon="eva:close-outline" />}>
            ยกเลิก
          </Button>
          <Button variant="contained" onClick={showCroppedImage} startIcon={<Iconify icon="eva:download-outline" />}>
            บันทึก
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
