import { useState } from 'react';
import { useDispatch } from 'react-redux';
// @mui
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Stack, IconButton, InputAdornment, TextField, Typography, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { alpha, useTheme } from '@mui/material/styles';
// import { useRouter } from 'src/routes/hooks';
import { bgGradient } from '../../../theme/css';
// hook
import { userLogin } from '../../../redux/actions/authActions';
// components
import Iconify from '../../../components/iconify';
import Logo from '../../../components/logo';
// ----------------------------------------------------------------------

const validationSchema = yup.object({
  email: yup
    .string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'ที่อยู่อีเมลผิด')
    .required('กรุณาระบุอีเมล'),
  password: yup.string().min(6, 'กรุณาระบุรหัสผ่านให้ถูกต้อง').required('กรุณาระบุรหัสผ่าน'),
});

export function LoginForm() {
  const theme = useTheme();

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(null); // Add this state

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const action = await dispatch(userLogin({ email: values.email, password: values.password }));
      if (userLogin.fulfilled.match(action)) {
        setSubmitting(false);
      } else {
        if (action.payload) {
          formik.setFieldValue('password', '');
          setLoginError('อีเมลหรือรหัสผ่านไม่ถูกต้อง');
          formik.setFieldTouched('password', false, false); // Set the touched state of password field to false
        }
        setSubmitting(false);
      }
    },
  });

  // return (
  //   <>
  //     <img
  //       src="../assets/illustrations/logo-ezorder.jpg"
  //       alt=""
  //       style={{
  //         maxWidth: '30%',
  //         height: 'auto',
  //         margin: '0 auto',
  //         marginBottom: '2em',
  //         border: '6px solid white',
  //         borderRadius: '12px',
  //       }}
  //     />
  //     <GlassBox>
  //       <form onSubmit={formik.handleSubmit}>
  //         <Typography variant="h3" color="white" gutterBottom sx={{ textAlign: 'center', mt: 2, mb: 3 }}>
  //           เข้าสู่ระบบ
  //         </Typography>{' '}
  //         <Divider sx={{ mb: 5, borderWidth: 2, borderColor: '#9BA4B5', mx: 7 }} />
  //         <Stack spacing={3}>
  //           <TextField
  //             fullWidth
  //             id="email"
  //             name="email"
  //             label="อีเมล"
  //             variant="standard"
  //             value={formik.values.email}
  //             onChange={formik.handleChange}
  //             error={formik.touched.email && Boolean(formik.errors.email || loginError)}
  //             helperText={formik.touched.email && (formik.errors.email || loginError)}
  //             InputProps={{
  //               style: { color: '#fff', fontSize: '1.1em' },
  //               inputProps: {
  //                 onInput: (e) => {
  //                   e.target.value = e.target.value.toLowerCase().trim();
  //                 },
  //                 onKeyPress: (e) => {
  //                   if (e.key === ' ') {
  //                     e.preventDefault();
  //                   }
  //                 },
  //               },
  //             }}
  //             InputLabelProps={{
  //               style: { color: '#fff', fontSize: '1.1em' },
  //             }}
  //           />
  //           <TextField
  //             fullWidth
  //             id="password"
  //             name="password"
  //             variant="standard"
  //             label="รหัสผ่าน"
  //             type={showPassword ? 'text' : 'password'}
  //             InputProps={{
  //               endAdornment: (
  //                 <InputAdornment position="end">
  //                   {formik.values.password && (
  //                     <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
  //                       <Iconify style={{ color: 'white' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
  //                     </IconButton>
  //                   )}
  //                 </InputAdornment>
  //               ),
  //               style: { color: '#fff', fontSize: '1.1em' },
  //             }}
  //             InputLabelProps={{
  //               style: { color: '#fff', fontSize: '1.1em' },
  //             }}
  //             value={formik.values.password}
  //             onChange={formik.handleChange}
  //             error={formik.touched.password && (Boolean(formik.errors.password) || formik.errors.password === null)}
  //             helperText={formik.touched.password && formik.errors.password}
  //           />
  //         </Stack>
  //         <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
  //           {' '}
  //         </Stack>
  //         <LoadingButton fullWidth size="large" loading={formik.isSubmitting} type="submit" variant="contained">
  //           เข้าสู่ระบบ
  //         </LoadingButton>
  //       </form>
  //       <Typography sx={{ mt: 7, textAlign: 'center' }} variant="body2">
  //         &copy; 2023 SPEEDY TECH CO., LTD. ALL RIGHTS RESERVED
  //       </Typography>
  //     </GlassBox>
  //   </>
  // );


  const renderForm = (
    <>
      <Stack spacing={3}>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="อีเมล"
              // variant="standard"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email || loginError)}
              helperText={formik.touched.email && (formik.errors.email || loginError)}
              InputProps={{
                inputProps: {
                  onInput: (e) => {
                    e.target.value = e.target.value.toLowerCase().trim();
                  },
                  onKeyPress: (e) => {
                    if (e.key === ' ') {
                      e.preventDefault();
                    }
                  },
                },
              }}
            />
            <TextField
              fullWidth
              id="password"
              name="password"
              // variant="standard"
              label="รหัสผ่าน"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formik.values.password && (
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify style={{ color: 'black' }} icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && (Boolean(formik.errors.password) || formik.errors.password === null)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
            {' '}
          </Stack>
          <LoadingButton fullWidth size="large" loading={formik.isSubmitting} type="submit" variant="contained" color="primary">
            เข้าสู่ระบบ
          </LoadingButton>
        </form>
      </Stack>
    </>
  );

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: '/assets/background/overlay_4.jpg',
        }),
        height: 1,
      }}
    >
      <Logo
        sx={{
          position: 'fixed',
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />

      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h4">เข้าสู่ระบบ</Typography>

          {/* <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
            Don’t have an account?
            <Link variant="subtitle2" sx={{ ml: 0.5 }}>
              Get started
            </Link>
          </Typography> */}

          {/* <Stack direction="row" spacing={2}>
            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
            >
              <Iconify icon="eva:google-fill" color="#DF3E30" />
            </Button>

            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
            >
              <Iconify icon="eva:facebook-fill" color="#1877F2" />
            </Button>

            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
            >
              <Iconify icon="eva:twitter-fill" color="#1C9CEA" />
            </Button>
          </Stack> */}

          <Divider sx={{ my: 3 }}>
            {/* <Typography variant="caption" sx={{ color: 'text.secondary' }} /> */}
          </Divider>

          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
}


