import { createSlice } from '@reduxjs/toolkit'
import { myShop, updateShop } from '../actions/shopActions'

const initialState = {
  loading: false,
  shopState: {},
  error: null,
}

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(myShop.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(myShop.fulfilled, (state, { payload }) => {
        state.shopState = payload.data
        state.success = true
        state.loading = false
      })
      .addCase(myShop.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(updateShop.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateShop.fulfilled, (state) => {
        state.success = true
        state.loading = false
      })
      .addCase(updateShop.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
  },
})

export default shopSlice.reducer