/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import _, { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// ----------------------------------------------------------------------
import { listCategory, deleteCategory, displayOrderCategory } from '../redux/actions/categoryActions';
import { listProduct, displayOrderProduct } from '../redux/actions/productActions';
import Reorderdata from '../components/reorder';

const TABLE_HEAD = [
  { id: 'name', label: 'ชื่อประเภท', alignRight: false },
  { id: 'name.en', label: 'ชื่อประเภท (อังกฤษ)', alignRight: false },
  { id: 'productCounts', label: 'จำนวนเมนูที่ผูก', alignRight: false },
  { id: 'status', label: 'สถานะ', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function getNameToOrderBy(object, property) {
  if (property === 'name') {
    return object?.name?.th;
  }
  if (property === 'name.en') {
    return object?.name?.en;
  }
  return object[property];
}

function descendingComparator(a, b, orderBy) {
  if (getNameToOrderBy(b, orderBy) < getNameToOrderBy(a, orderBy)) {
    return -1;
  }
  if (getNameToOrderBy(b, orderBy) > getNameToOrderBy(a, orderBy)) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (r) =>
        r.name.th.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (r.name.en && r.name.en?.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ListCategory() {
  const { category, auth } = useSelector((state) => state);
  const { categories } = category;
  // const { products } = product;
  const { branch } = auth;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [openReorderMenu, setOpenReorderMenu] = useState(false);
  const [openReorderCate, setOpenReorderCate] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedCate, setSelectedCate] = useState([]);
  const [isRecommendCategory, setIsRecommendCategory] = useState(false);

  const [orderBy, setOrderBy] = useState('name.th');
  const [filterName, setFilterName] = useState('');
  const [limit, setLimit] = useState(25);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmValue, setConfirmValue] = useState();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (branch) {
      setIsLoading(true);
      dispatch(listCategory())
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  }, [branch, dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = categories.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, value) => {
    const selectedIndex = selected.indexOf(value);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = categories.length === 0;
  const filteredCategory = applySortFilter(categories, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredCategory.length && !!filterName;

  const handleRedirect = (route) => {
    navigate(route);
  };

  const handleDelete = (value) => {
    setConfirmDelete(true);
    setConfirmValue([value]);
  };
  const handleConfirmDelete = () => {
    dispatch(deleteCategory({ categories: confirmValue || selected }));
    setConfirmDelete(false);
    setSelected([]);
  };

  const handleConfirmDeleteAll = () => {
    setConfirmDelete(true);
    setConfirmValue();
  };

  const submitDialog = async (data) => {
    const orderEvent = data.map((v, i) => {
      const info = {
        ref: v._id,
        index: i,
      };
      return info;
    });
    setIsLoading(true);
    await dispatch(displayOrderCategory({ orderby: orderEvent }));
    dispatch(listCategory())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  };

  const selectedRow = async (row) => {
    try {
      dispatch(listProduct()).then((data) => {
        const productLists = data.payload?.data;
        let selectedProduct = [];
        if (row.is_recommend_category) {
          setIsRecommendCategory(true);
          selectedProduct =
            productLists &&
            productLists
              .filter((p) => p.is_recommend === true)
              .sort((a, b) => {
                if (a.display_recommend_order < b.display_recommend_order) {
                  return -1;
                }
                if (a.display_recommend_order > b.display_recommend_order) {
                  return 1;
                }
                return 0;
              });
        } else {
          setIsRecommendCategory(false);
          selectedProduct =
            productLists &&
            productLists
              .filter((p) => p.category?._id === row._id)
              .sort((a, b) => {
                if (a.display_order < b.display_order) {
                  return -1;
                }
                if (a.display_order > b.display_order) {
                  return 1;
                }
                return 0;
              });
        }
        setSelectedCate(selectedProduct || []);
        setOpenReorderMenu(true);
      });
    } catch (error) {
      setIsRecommendCategory(false);
      // console.log(error);
    }
  };

  const submitDialogMenu = async (data) => {
    const orderEvent = data.map((v, i) => {
      const info = {
        ref: v._id,
        index: i,
      };
      return info;
    });
    setIsLoading(true);
    await dispatch(displayOrderProduct({ orderby: orderEvent, is_recommend: isRecommendCategory }))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  };

  const getCateName = (data) => {
    if (isRecommendCategory) {
      const category = categories.find((c) => c.is_recommend_category === true);
      return category?.name?.th || 'เมนูแนะนำ';
    }
    return data[0].category.name.th;
  };

  if (isLoading) {
    return (
      <>
        <Helmet>
          <title> EzOrder Backoffice | ประเภทสินค้า </title>
        </Helmet>
        <Container>
          <Stack
            className={'group_header_filter'}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              ประเภทสินค้า
            </Typography>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleRedirect('/category/create')}
            >
              เพิ่มประเภทใหม่
            </Button>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="center" style={{ height: 'calc(100vh - 50vh)' }}>
            <CircularProgress size={100} />
          </Stack>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | ประเภทสินค้า </title>
      </Helmet>

      <Container>
        <Stack
          className={'group_header_filter'}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            ประเภทสินค้า
          </Typography>
          <div style={{ flex: 1 }}> </div>
          {categories.length > 0 && (
            <Button
              variant="contained"
              startIcon={<Iconify icon="teenyicons:drag-outline" />}
              onClick={() => setOpenReorderCate(true)}
              style={{ marginLeft: '8px', marginRight: '8px' }}
            >
              จัดเรียงประเภทสินค้า
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => handleRedirect('/category/create')}
          >
            เพิ่มประเภทใหม่
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onDelete={() => handleConfirmDeleteAll()}
          />

          <Reorderdata
            title={'จัดเรียงประเภทสินค้า'}
            open={openReorderCate}
            itemsData={categories}
            onClose={(data) => setOpenReorderCate(data)}
            handleSubmit={(data) => submitDialog(data)}
          />

          {(selectedCate && selectedCate.length) > 0 && (
            <Reorderdata
              title={`จัดเรียงเมนู (${getCateName(selectedCate)})`}
              open={openReorderMenu}
              itemsData={selectedCate}
              onClose={(data) => setOpenReorderMenu(data)}
              handleSubmit={(data) => submitDialogMenu(data)}
            />
          )}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={categories.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredCategory?.slice(page * limit, page * limit + limit).map((row, index) => {
                    const { _id, name, productCount, is_enable, is_recommend_category } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;
                    const editLink = `/category/${_id}`;
                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2">
                            <Link underline="hover" to={editLink}>
                              {name.th}
                            </Link>
                          </Typography>
                        </TableCell>

                        <TableCell component="en" scope="row" padding="none">
                          <Typography variant="subtitle2">
                            <Link underline="hover" to={editLink}>
                              {name.en}
                            </Link>
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {productCount.length}
                          {productCount.length > 0 && (
                            <IconButton color="info" onClick={() => selectedRow(row)} component="label">
                              <Iconify icon={'teenyicons:drag-outline'} />
                            </IconButton>
                          )}
                        </TableCell>

                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Label color={(!is_enable && 'error') || 'success'}>
                              {(!is_enable && 'ปิดการแสดง') || 'แสดงปกติ'}
                            </Label>
                          </Stack>
                        </TableCell>

                        <TableCell align="right">
                          {!is_recommend_category && (
                            <IconButton color="error" onClick={() => handleDelete(_id)} component="label">
                              <Iconify icon={'eva:trash-2-outline'} sx={{ color: 'error.main' }} />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>{/* <TableCell colSpan={6} /> */}</TableRow>
                  )}
                </TableBody>{' '}
                {isNotFound ||
                  (emptyRows && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <img
                              src="/assets/images/empty_handle/category_notfound.png"
                              alt="product not found"
                              width={'17%'}
                              style={{ filter: 'grayscale(100%)', opacity: 0.6 }}
                            />
                            <Typography variant="h6" paragraph sx={{ pt: 6, mb: 1 }}>
                              ไม่พบรายการประเภท
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 0, opacity: 0.7 }}>
                              ยังไม่มีรายการประเภทที่เพิ่มเข้ามา
                            </Typography>
                            {isNotFound && (
                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            )}
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, { value: filteredCategory.length, label: 'All' }]}
            component="div"
            count={categories.length}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(!confirmDelete)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'แจ้งเตือนระบบ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">คุณต้องการที่จะลบประเภทสินค้านี้หรือไม่?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(!confirmDelete)}>ยกเลิก</Button>
          <Button onClick={() => handleConfirmDelete()} autoFocus>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
