import { createSlice } from '@reduxjs/toolkit'
import { listUser, createUser, detailUser, updateUser, deleteUser, listUserRole } from '../actions/userActions'

const initialState = {
  loading: false,
  users: [],
  user: {},
  userRoles: [],
  total: null,
  totalPage: null,
  error: null,
  success: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.users = []
      })
      .addCase(listUser.fulfilled, (state, { payload }) => {
        state.loading = false
        state.users = payload.data
        state.total = payload.total
        state.totalPage = payload.total_page
      })
      .addCase(listUser.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.users = []
      })
      .addCase(detailUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.user = {}
      })
      .addCase(detailUser.fulfilled, (state, { payload }) => {
        state.loading = false
        state.user = payload.data
      })
      .addCase(detailUser.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.user = {}
      })
      .addCase(createUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.loading = false
        state.users = payload.data
        state.success = true
      })
      .addCase(createUser.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.loading = false
        state.users = payload.data
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.loading = false
        state.users = payload.data
        state.success = false
      })
      .addCase(deleteUser.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.success = false
      })
      .addCase(listUserRole.pending, (state) => {
        state.loading = true
        state.error = null
        state.userRoles = []
      })
      .addCase(listUserRole.fulfilled, (state, { payload }) => {
        state.loading = false
        state.userRoles = payload.data
      })
      .addCase(listUserRole.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.userRoles = []
      })
  },
})

export default userSlice.reducer