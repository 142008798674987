import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

// @mui
import {
  Card,
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  CardHeader,
  Button,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// redux
import { updateCategory, detailCategory, listMasterCategory } from '../../redux/actions/categoryActions';
// components
// service

const validationSchema = yup.object({
  name: yup.object().shape({
    th: yup
      .string()
      .min(2, 'โปรดระบุชื่อประเภท (2-50 ตัวอักษร)')
      .max(50, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ')
      .required('โปรดระบุชื่อประเภท'),
    en: yup.string().min(2, 'โปรดระบุชื่อประเภท (2-50 ตัวอักษร)').max(50, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ'),
  }),
});

export default function Edit() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.auth);
  const { category, error, loading } = useSelector((state) => state.category);

  useEffect(() => {
    if (branch) dispatch(detailCategory({ categoryId: id }));
  }, [branch, dispatch]);

  const formik = useFormik({
    initialValues: {
      name: { th: '' },
      is_enable: false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const data = {
          name: values.name,
          is_enable: values.is_enable,
        };

        await dispatch(updateCategory({ categoryId: id, event: { ...data } }));
        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar(error, { variant: 'error' });
          return;
        }
        dispatch(listMasterCategory());
        enqueueSnackbar('แก้ไขข้อมูลเรียบร้อย', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setSubmitting(false);
        navigate('/category');
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (category) {
      formik.setFieldValue('name.th', category.name?.th || '');
      formik.setFieldValue('name.en', category.name?.en || '');
      formik.setFieldValue('is_enable', category.is_enable);
      if (category.name?.en) setExpanded('panel1');
    }
  }, [category]);

  const [expanded, setExpanded] = useState();

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleBack = () => {
    navigate('/category');
  };

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | แก้ไขประเภท </title>
      </Helmet>

      <Container>
        {!loading && category && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                แก้ไขประเภท
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    defaultChecked={category.is_enable}
                    onChange={(e, value) => formik.setFieldValue('is_enable', value || false)}
                  />
                }
                label="แสดงสินค้า"
              />
            </Stack>

            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Card>
                    <CardHeader title={'รายละเอียดประเภท'} />
                    <Box sx={{ p: 3, pb: 3 }} dir="ltr">
                      <FormControl fullWidth>
                        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                          (2-50 ตัวอักษร)
                        </Stack>
                        <TextField
                          id="name.th"
                          name="name.th"
                          label="ชื่อประเภท *"
                          type="text"
                          value={formik.values.name?.th}
                          onChange={formik.handleChange}
                          error={formik.touched.name?.th && Boolean(formik.errors.name?.th)}
                          helperText={formik.touched.name?.th && formik.errors.name?.th}
                        />
                      </FormControl>
                    </Box>
                  </Card>
                </Grid>

                {/* รายละเอียดประเภท (อังกฤษ) */}
                <Grid item xs={12} md={12} lg={12}>
                  <Card>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                      <AccordionSummary
                        aria-controls="panelEnLang-content"
                        expandIcon={<ExpandMoreIcon />}
                        id="panelEnLang"
                        sx={{ pt: 0, pr: 2, pl: 2, pb: 0 }}
                      >
                        <CardHeader sx={{ p: 1 }} title={'รายละเอียดประเภท (อังกฤษ)'} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ pt: 0, pr: 1, pl: 1, pb: 3 }} dir="ltr">
                          <FormControl fullWidth>
                            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={0}>
                              (2-50 ตัวอักษร)
                            </Stack>
                            <TextField
                              id="name.en"
                              name="name.en"
                              label="ชื่อประเภท (อังกฤษ)"
                              type="text"
                              value={formik.values.name?.en}
                              onChange={formik.handleChange}
                              error={formik.touched.name?.en && Boolean(formik.errors.name?.en)}
                              helperText={formik.touched.name?.en && formik.errors.name?.en}
                            />
                          </FormControl>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Card>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} spacing={1}>
                    <Button variant="outlined" onClick={() => handleBack()}>
                      ยกเลิก
                    </Button>

                    <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                      บันทึก
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Container>
    </>
  );
}
