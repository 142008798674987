/* eslint-disable camelcase */
import _, { filter } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import * as yup from 'yup';
// @mui
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {
  Grid,
  InputAdornment,
  Box,
  FormControl,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// ----------------------------------------------------------------------
import { createOption, updateOption } from '../../redux/actions/optionActions';
import Iconify from '../iconify';

// ----------------------------------------------------------------------
const validationSchema = yup.object({
  name: yup.object().shape({
    th: yup
      .string()
      .min(2, 'โปรดระบุชื่อประเภท (2-50 ตัวอักษร)')
      .max(50, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ')
      .required('โปรดระบุชื่อประเภท'),
    en: yup.string().min(2, 'โปรดระบุชื่อประเภท (2-50 ตัวอักษร)').max(50, 'ชื่อประเภทเกินจำนวนที่สามารถรองรับ'),
  }),
  price: yup.number().min(0, 'โปรดระบุราคาเป็นจำนวนเต็มบวก').required('โปรดระบุราคา'),
});

const NumberFormatCustom = React.forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            // eslint-disable-next-line react/prop-types
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale="2"
    />
  );
});

export default function ModalOption({ openModel, handleClose, props = {} }) {
  const [showPrice, setShowPrice] = useState(false);
  const { option, auth } = useSelector((state) => state);
  const { error } = option;

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: { th: '' },
      price: 0,
      is_enable: true,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const data = {
          name: values.name,
          price: values.price,
        };
        let ref = null;
        if (props?._id) {
          dispatch(updateOption({ optionId: props?._id, event: { ...data } }));
        } else {
          const create = await dispatch(createOption({ ...data }));
          ref = create.payload.ref;
        }

        // if ((error) && Object.keys(error).length === 0 && error.constructor === Object) {
        //     enqueueSnackbar('เกิดข้อผิดพลาด!', { variant: 'error' });
        // }
        // enqueueSnackbar('บันทึกข้อมูลเรียบร้อย!', { variant: 'success', anchorOrigin: { vertical: "top", horizontal: "right" } });
        setSubmitting(false);
        handleClose(ref);
      } catch (error) {
        // enqueueSnackbar('This is a success message!', { variant: 'error' });
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (props?.name) {
      formik.setFieldValue('name.th', props.name?.th || '');
      formik.setFieldValue('name.en', props.name?.en || '');
      formik.setFieldValue('price', props.price);
      if (props.name?.en) setExpanded('panel1');
      setShowPrice(props.price > 0);
    }
  }, [props]);

  const [expanded, setExpanded] = useState();
  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleShowPrice = () => {
    formik.setFieldValue('price', formik.values.price > 0 ? 0 : 10);
    setShowPrice(!(formik.values.price > 0));
  };

  return (
    <>
      <Dialog open={openModel} onClose={handleClose} maxWidth={'xs'} fullWidth>
        <DialogTitle>{props?.name?.th ? 'แก้ไขช้อยส์' : 'เพิ่มช้อยส์'}</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    pt: 1,
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      id="name.th"
                      name="name.th"
                      label="ชื่อช้อยส์ *"
                      type="text"
                      value={formik.values.name?.th}
                      onChange={formik.handleChange}
                      error={formik.touched.name?.th && Boolean(formik.errors.name?.th)}
                      helperText={formik.touched.name?.th && formik.errors.name?.th}
                    />
                  </FormControl>
                </Box>
                <Accordion disableGutters expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1a-header"
                    sx={{ pt: 0, pr: 0, pl: 0, pb: 1 }}
                  >
                    ชื่อช้อยส์ (อังกฤษ)
                  </AccordionSummary>
                  <AccordionDetails sx={{ pt: 0, pr: 0, pl: 0, pb: 1 }}>
                    <Box>
                      <FormControl fullWidth>
                        <TextField
                          id="name.en"
                          name="name.en"
                          label="ชื่อช้อยส์ (อังกฤษ)"
                          type="text"
                          value={formik.values.name?.en}
                          onChange={formik.handleChange}
                          error={formik.touched.name?.en && Boolean(formik.errors.name?.en)}
                          helperText={formik.touched.name?.en && formik.errors.name?.en}
                        />
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Box dir="ltr">
                  <FormControlLabel
                    control={<Switch name="is_price" checked={showPrice} onChange={() => handleShowPrice()} />}
                    label="ระบุราคาเพิ่มหรือไม่ ?"
                  />
                </Box>

                {showPrice > 0 && (
                  <Box
                    sx={{
                      pt: 2,
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        fullWidth
                        placeholder="0.00"
                        label="ราคา *"
                        name="price"
                        id="price"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        helperText={formik.touched.price && formik.errors.price}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          startAdornment: <InputAdornment position="start">฿</InputAdornment>,
                        }}
                      />
                    </FormControl>
                  </Box>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()}>ยกเลิก</Button>
            <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
              บันทึก
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
