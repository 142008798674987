import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

// @mui
import { Paper, Container, Typography, Box, Grid, TextField, FormControl, CardHeader, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';
// redux
import { updateShop, myShop } from '../../redux/actions/shopActions';

const validationSchema = yup.object({
  name: yup.object().shape({
    th: yup
      .string()
      .min(2, 'โปรดระบุชื่อร้านค้า (2-100 ตัวอักษร)')
      .max(100, 'ชื่อร้านค้าเกินจำนวนที่สามารถรองรับ')
      .required('โปรดระบุชื่อร้านค้า'),
    en: yup.string().min(2, 'โปรดระบุชื่อร้านค้า (2-100 ตัวอักษร)').max(100, 'ชื่อร้านค้าเกินจำนวนที่สามารถรองรับ'),
  }),
  address: yup.string().max(300, 'รายละเอียดที่อยู่เกินจำนวนที่สามารถรองรับได้'),
  phone_number: yup.string().min(9, 'โปรดกรอกข้อมูลให้ถูกต้อง').max(10, 'เบอร์ติดต่อเกินจำนวนที่สามารถรองรับได้'),
});

export default function ShopPage() {
  const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.auth);
  const { shopState, error, loading } = useSelector((state) => state.shop);

  useEffect(() => {
    if (branch) dispatch(myShop({}));
  }, [branch, dispatch]);

  const formik = useFormik({
    initialValues: {
      name: { th: '' },
      address: '',
      phone_number: null,
      social_configs: {
        facebook_url: '',
        ig_url: '',
        website_url: '',
      },
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const data = {
          name: values.name,
          address: values.address,
          social_configs: values.social_configs,
          phone_number: values.phone_number,
        };

        await dispatch(updateShop({ id: shopState._id, event: { ...data } }));
        if (error && Object.keys(error).length === 0 && error.constructor === Object) {
          enqueueSnackbar(error, { variant: 'error' });
          return;
        }
        enqueueSnackbar('แก้ไขข้อมูลเรียบร้อย', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        // setSubmitting(false)
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (shopState) {
      formik.setFieldValue('name.th', shopState.name?.th || '');
      formik.setFieldValue('name.en', shopState.name?.en || '');
      formik.setFieldValue('address', shopState.address || '');
      formik.setFieldValue('phone_number', shopState.phone_number || '');
      formik.setFieldValue('social_configs.facebook_url', shopState.social_configs?.facebook_url || '');
      formik.setFieldValue('social_configs.ig_url', shopState.social_configs?.ig_url || '');
      formik.setFieldValue('social_configs.website_url', shopState.social_configs?.website_url || '');
    }
  }, [shopState]);

  return (
    <>
      {!loading && (
        <>
          <Grid container spacing={2} >
            <Grid item xs={12} md={12}>
              <Paper elevation="1">
                <form onSubmit={formik.handleSubmit}>
                  <CardHeader title={'ข้อมูลร้าน'} />
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 2,
                      p: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'grid',
                        gap: 2,
                        gridTemplateColumns: 'repeat(2, 1fr)',
                      }}
                    >
                      <FormControl fullWidth>
                        <TextField
                          id="name.th"
                          name="name.th"
                          label="ชื่อร้านค้า *"
                          type="text"
                          value={formik.values.name?.th}
                          onChange={formik.handleChange}
                          error={formik.touched.name?.th && Boolean(formik.errors.name?.th)}
                          helperText={formik.touched.name?.th && formik.errors.name?.th}
                        />
                      </FormControl>

                      <FormControl fullWidth>
                        <TextField
                          id="name.en"
                          name="name.en"
                          label="ชื่อร้านค้า (อังกฤษ)"
                          type="text"
                          value={formik.values.name?.en}
                          onChange={formik.handleChange}
                          error={formik.touched.name?.en && Boolean(formik.errors.name?.en)}
                          helperText={formik.touched.name?.en && formik.errors.name?.en}
                        />
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: 'grid',
                      }}
                    >
                      <FormControl fullWidth>
                        <TextField
                          id="address"
                          name="address"
                          label="ที่อยู่"
                          multiline
                          rows={3}
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          error={formik.touched.address && Boolean(formik.errors.address)}
                          helperText={formik.touched.address && formik.errors.address}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <PhoneIcon sx={{ color: 'action.active', mr: 1, my: 2 }} />
                      <TextField
                        id="phone_number"
                        name="phone_number"
                        label="เบอร์ติดต่อ"
                        type="text"
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                        helperText={formik.touched.phone_number && formik.errors.phone_number}
                        fullWidth
                      />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <FacebookIcon sx={{ color: 'action.active', mr: 1, my: 2 }} />
                      <TextField
                        id="social_configs.facebook_url"
                        name="social_configs.facebook_url"
                        label="เฟซบุ๊ก"
                        type="text"
                        value={formik.values.social_configs?.facebook_url}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.social_configs?.facebook_url &&
                          Boolean(formik.errors.social_configs?.facebook_url)
                        }
                        helperText={
                          formik.touched.social_configs?.facebook_url && formik.errors.social_configs?.facebook_url
                        }
                        fullWidth
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <InstagramIcon sx={{ color: 'action.active', mr: 1, my: 2 }} />
                      <TextField
                        id="social_configs.ig_url"
                        name="social_configs.ig_url"
                        label="อินสตาแกรม"
                        type="text"
                        value={formik.values.social_configs?.ig_url}
                        onChange={formik.handleChange}
                        error={formik.touched.social_configs?.ig_url && Boolean(formik.errors.social_configs?.ig_url)}
                        helperText={formik.touched.social_configs?.ig_url && formik.errors.social_configs?.ig_url}
                        fullWidth
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <LanguageIcon sx={{ color: 'action.active', mr: 1, my: 2 }} />
                      <TextField
                        id="social_configs.website_url"
                        name="social_configs.website_url"
                        label="เว็บไซต์"
                        type="text"
                        value={formik.values.social_configs?.website_url}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.social_configs?.website_url &&
                          Boolean(formik.errors.social_configs?.website_url)
                        }
                        helperText={
                          formik.touched.social_configs?.website_url && formik.errors.social_configs?.website_url
                        }
                        fullWidth
                      />
                    </Box>
                    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" mb={5} spacing={1}>
                      <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
                        บันทึก
                      </LoadingButton>
                    </Stack>
                  </Box>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
