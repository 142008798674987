import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// sections
import { LoginForm } from '../sections/auth/login/LoginForm';
// ----------------------------------------------------------------------

export default function LoginPage() {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, [navigate, isLoggedIn]);

  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | เข้าสู่ระบบ </title>
      </Helmet>

      <LoginForm />
     
    </>
  );
}
