import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @mui
import { Box, Collapse, List, ListItemText } from '@mui/material';

//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import Iconify from '../iconify/Iconify';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item,i) => (
          <NavItem key={item.title} index={i} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};
// รอทำหลาย สาขา
function NavItem({ item,index }) {
  const { branch ,userInfo } = useSelector((state) => state.auth);
  const { title, path, icon, info } = item;
  const newPath = path.replace(/#branchId/g, branch);

  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    if (item.title === 'รายงาน') {
      event.preventDefault(); // prevent the default action (navigation)
    }
    setOpen(!open);
  };
  // console.log(userInfo.permissions.filter((v) => v.resource === "") , path)
  // const permissions = [
  //       {
  //           "resource": "products"
  //       },
  //       {
  //           "resource": "attributes"
  //       },
  //       {
  //           "resource": "branches"
  //       },
  //       {
  //           "resource": "categories"
  //       },
  //       {
  //           "resource": "generates"
  //       },
  //       {
  //           "resource": "shops"
  //       },
  //       {
  //           "resource": "tags"
  //       },
  //       {
  //           "resource": "tables"
  //       },
  //       {
  //           "resource": "zones"
  //       },
  //       {
  //           "resource": "users"
  //       },
  //       {
  //           "resource": "dashboards"
  //       },
  //       {
  //           "resource": "packages"
  //       },
  //       {
  //           "resource": "reports"
  //       }
  // ]
  if(userInfo?.permissions){
    if(userInfo.permissions.filter((v) => v.resource === item.resource.find((pre) => pre === v.resource)).length > 0){
    return (
      <>
        <StyledNavItem
          component={RouterLink}
          to={newPath}
          onClick={item.title === 'รายงาน' ? handleOpen : null}
          sx={{
            '&.active': {
              color: 'text.primary',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
  
          <ListItemText disableTypography primary={title} />
  
          {info && info}
  
          {item.title === 'รายงาน' && (
            <Iconify
              icon={'eva:arrow-ios-downward-outline'}
              style={{
                transform: open ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.3s ease-in-out',
                marginRight: 10,
              }}
            />
          )}
        </StyledNavItem>
  
        {item.title === 'รายงาน' && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <StyledNavItem
                component={RouterLink}
                to={'/report/summary-sale'}
                sx={{
                  '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                  },
                }}
              >
                <StyledNavItemIcon> </StyledNavItemIcon>
                <ListItemText disableTypography primary="รายงานยอดขาย" />
              </StyledNavItem>
              <StyledNavItem
                component={RouterLink}
                to={'/report/summary-sale-product'}
                sx={{
                  '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                  },
                }}
              >
                <StyledNavItemIcon> </StyledNavItemIcon>
                <ListItemText disableTypography primary="รายงานยอดขายตามสินค้า" />
              </StyledNavItem>
  
              <StyledNavItem
                component={RouterLink}
                to={'/report/summary-sale-category'}
                sx={{
                  '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                  },
                }}
              >
                <StyledNavItemIcon> </StyledNavItemIcon>
                <ListItemText disableTypography primary="รายงานยอดขายตามประเภทสินค้า" />
              </StyledNavItem>
              {/* <StyledNavItem
                component={RouterLink}
                to={'/report/bill'}
                sx={{
                  '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                  },
                }}
              >
                <StyledNavItemIcon> </StyledNavItemIcon>
                <ListItemText disableTypography primary="รายงานยอดขายรายบิล" />
              </StyledNavItem> */}
            </List>
          </Collapse>
        )}
      </>
    );
    }
  }
  
  
}
