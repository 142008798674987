/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import _, { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { useSnackbar } from 'notistack';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableFooter,
  Paper
} from '@mui/material';
import dayjs from 'dayjs';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListFilterTool } from '../../sections/@dashboard/user';
// ----------------------------------------------------------------------
import { summarySaleWithCategory, exportSummarySaleWithCategory } from '../../redux/actions/reportActions';
import { fCurrency } from '../../utils/formatNumber'

const TABLE_HEAD = [
  { id: 'category_name', label: 'ชื่อประเภทสินค้า', alignRight: false },
  { id: 'agv_unit_price', label: 'ยอดขายเฉลี่ยต่อหน่วย', alignRight: true },
  { id: 'unit_sold', label: 'จำนวนสินค้าที่ขาย', alignRight: true },
  { id: 'total_sales', label: 'ยอดขายรวม', alignRight: true },


];

export default function SummarySaleWithProductPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name.th');
  const [filterName, setFilterName] = useState('');

  const [dateFrom, setDateFrom] = useState(dayjs().startOf('month'));
  const [dateTo, setDateTo] = useState(new Date());

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const { branch } = useSelector((state) => state.auth);
  const { results, totalRows } = useSelector((state) => state.report);

  useEffect(() => {
    if (branch) dispatch(summarySaleWithCategory({ page, limit, dateFrom, dateTo, search: "" }));
  }, [page, limit, dateFrom, dateTo]);

  const handleDateStartChange = (date) => {
    setDateFrom(date);
  };

  const handleDateEndChange = (date) => {
    setDateTo(date);
  };

  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setLimit(parseInt(event.target.value, 10));
  };


  // const filteredProduct = applySortFilter(results, getComparator(order, orderBy), filterName);
  const emptyRows = results.length === 0;
  const totalBill = results.length > 0 ? _.sumBy(results, "unit_sold") : 0;
  const totalSales = results.length > 0 ? _.sumBy(results, "total_sales") : 0;
  const totalAvg = results.length > 0 ? _.sumBy(results, "agv_unit_price") : 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleExport = () => {
    dispatch(exportSummarySaleWithCategory({ dateFrom, dateTo })).then((res) => {
      const { data } = res.payload
      if (data.length === 0) {
        enqueueSnackbar('ไม่สามารถส่งออกข้อมูลได้ เนื่องจากไม่พบข้อมูลที่จะนำออก', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return
      }
      const wb = XLSX.utils.book_new();
      const headers = TABLE_HEAD.map(x => x.label)
      const title = "รายงานยอดขายตามประเภทสินค้า"

      const rows = data.map(row => ({
        category_name: row.category_name,
        unit_sold: row.unit_sold,
        total_sales: row.total_sales,
        agv_unit_price: row.agv_unit_price
      }));

      const ws = XLSX.utils.json_to_sheet(rows, { origin: 'A2', skipHeader: true });

      // adding heading to the first row of the created sheet.
      // sheet already have contents from above statement.
      XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
      XLSX.utils.book_append_sheet(wb, ws, title);
      XLSX.writeFile(wb, `${title}.xlsx`);
    })
  };


  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | รายงานยอดขายตามประเภทสินค้า </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            รายงานยอดขายตามประเภทสินค้า
          </Typography>
        </Stack>

        <Card>
          <UserListFilterTool
            numSelected={0}
            filterName={filterName}
            onFilterName={handleFilterByName}
            dateFrom={dateFrom}
            onDateFromChange={handleDateStartChange}
            onDateToChange={handleDateEndChange}
            onExportExcel={handleExport}
            displayType={'report'}
            filterType={'daily'}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={results.length}
                  numSelected={0}
                  onRequestSort={handleRequestSort}
                  displayType={'report'}
                />

                <TableBody>
                  {results.map((row, index) => {
                    const {
                      category_name,
                      unit_sold,
                      total_sales,
                      agv_unit_price
                    } = row;

                    return (

                      <TableRow hover key={index} tabIndex={-1} >
                        <TableCell align="left">
                          <Typography variant="subtitle2" >
                            {category_name}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Typography variant="subtitle2" >
                            {fCurrency(agv_unit_price)}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Typography variant="subtitle2" >
                            {unit_sold}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Typography variant="subtitle2" >
                            {fCurrency(total_sales)}
                          </Typography>
                        </TableCell>

                       
                      </TableRow>



                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>{/* <TableCell colSpan={6} /> */}</TableRow>
                  )}
                </TableBody>

                {!emptyRows && <TableFooter>
                  <TableRow>
                    <TableCell>
                      {' '}
                      <Typography variant="subtitle1">รวม</Typography>
                    </TableCell>
         

                    <TableCell align="right">
                      <Typography variant="subtitle1">{fCurrency(totalAvg)}</Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Typography variant="subtitle1">{totalBill}</Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Typography variant="subtitle1">{fCurrency(totalSales)}</Typography>
                    </TableCell>

                  </TableRow>
                </TableFooter>
                }

                {(emptyRows && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <img
                            src="/assets/not_found.png"
                            alt="not found"
                            width={'17%'}
                            style={{ filter: 'grayscale(100%)', opacity: 0.6 }}
                          />
                          <Typography variant="h6" paragraph sx={{ pt: 6, mb: 1 }}>
                            ไม่พบรายการ
                          </Typography>
                          <Typography variant="body1" sx={{ mt: 0, opacity: 0.7 }}>
                            ยังไม่มีรายการที่เพิ่มเข้ามา
                          </Typography>

                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, { value: totalRows, label: "All" }]}
            component="div"
            count={totalRows}
            rowsPerPage={limit}
            page={page === 1 ? 0 : Number(page) - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container >
    </>
  );
}
