import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, Button, InputAdornment, Select, MenuItem, TextField, Chip, FormControl, InputLabel, Drawer, List, ListItemButton, ListItemText, ListItemIcon, ListSubheader, Box, Divider, Autocomplete, Badge } from '@mui/material';
// component

import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar/Scrollbar';


// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDelete: PropTypes.func,
};

export default function UserListToolbar({ numSelected, filterName, onFilterName, onDelete, onFilterCategory, Categories, filterCategory, SubmitSearch }) {
  const [openFilter, setOpenFilter] = useState(false)
  let isPlaceholderHidden = ''
  if (filterCategory?.length) {
    isPlaceholderHidden = filterCategory.length > 0;
  }

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          {' '}
          <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder="ค้นหา..."
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
        </>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={onDelete}>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) :
        !!Categories ?
          <>
            <Tooltip title="Filter list" onClick={() => setOpenFilter(!openFilter)}>

              <IconButton>
                <Badge badgeContent={filterCategory.length} variant="dot" color="error">
                  <Iconify icon="ic:round-filter-list" />
                </Badge>
              </IconButton>

            </Tooltip>
            <Drawer
              open={openFilter}
              anchor={'right'}
              onClose={() => setOpenFilter(false)}
              PaperProps={{
                sx: {
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: 290,
                  bgcolor: 'background.default',
                },
              }}
            >
              <Box sx={{ flex: 1 }}>
                <List
                  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      ค้นหา
                    </ListSubheader>
                  }
                >
                  <Divider />
                  <Box padding={2}>
                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: 14, marginBottom: 10 }}>ประเภทสินค้า</InputLabel>
                    <Autocomplete
                      multiple
                      id="size-small-outlined"
                      value={filterCategory}
                      options={Categories}
                      getOptionLabel={(option) => option?.name?.th}
                      renderInput={(params) => <TextField {...params} variant="standard" placeholder={isPlaceholderHidden ? '' : '-กรุณาเลือกประเภทสินค้า-'} />}
                      onChange={(event, newValue) => onFilterCategory(event, newValue)}
                    />
                    {/* <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label"  style={{fontSize : 14}}>ประเภทสินค้า</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={filterCategory}
                    onChange={(e) => onFilterCategory(e)}
                    label="-กรุณาเลือกประเภทสินค้า-"
                    
                  >
                    <MenuItem value={'a'} style={{fontSize : 14}} disabled>- กรุณาเลือกประเภทสินค้า -</MenuItem>
                    {
                      Categories.map((cat,i) => {
                        return (
                          <MenuItem value={cat._id} style={{fontSize : 14}}>{cat.name.th}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl> */}
                  </Box>
                  {/* {
                Categories.map((cat,i) => {
                  return <>
                  
                    <ListItemButton  onClick={(e) => onFilterCategory(e , cat)} key={cat._id} value={cat._id}>
                      <ListItemText secondary={cat.name.th} />
                    </ListItemButton>
                  </>
                })
              } */}
                </List>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                <Button onClick={(event) => {
                  onFilterCategory(event, [])
                  SubmitSearch([])
                  setOpenFilter(false)
                }} color="primary">
                  ล้างตัวกรอง
                </Button>
                <Button onClick={() => {
                  SubmitSearch()
                  setOpenFilter(false)
                }
                } variant="contained" color="primary">
                  ตกลง
                </Button>
              </Box>
            </Drawer>
          </>



          : null
      }
    </StyledRoot>
  );
}
