/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,

} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// ----------------------------------------------------------------------
import { listMasterCategory } from '../redux/actions/categoryActions';
import { listProduct, deleteProduct, displayOrderProduct } from '../redux/actions/productActions';
import Reorderdata from '../components/reorder';

const TABLE_HEAD = [
  { id: 'name', label: 'ชื่อสินค้า', alignRight: false },
  { id: 'name.en', label: 'ชื่อสินค้า (อังกฤษ)', alignRight: false },
  { id: 'category', label: 'ประเภทสินค้า', alignRight: false },
  { id: 'price', label: 'ราคา', alignRight: false },
  { id: 'status', label: 'สถานะ', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------
function getNameToOrderBy(object, property) {
  if (property === "name") {
    return object?.name?.th
  }
  if (property === "name.en") {
    return object?.name?.en
  }
  if (property === "category") {
    return object?.category?.name?.th
  }
  return object[property]
}

function descendingComparator(a, b, orderBy) {
  if (getNameToOrderBy(b, orderBy) < getNameToOrderBy(a, orderBy)) {
    return -1;
  }
  if (getNameToOrderBy(b, orderBy) > getNameToOrderBy(a, orderBy)) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, category) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (r) =>
        r.name.th.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (r.name.en && r.name.en?.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }
  const filterOther = category.map((item) => item._id)
  if (category.length > 0) {

    const data = array.filter((product, i) => filterOther.includes(product.category._id) || (category.filter((item) => item.is_recommend_category).length > 0 && product.is_recommend)).sort((a, b) => {
      if (a.category._id < b.category._id) {
        return -1;
      }
      if (a.category._id > b.category._id) {
        return 1;
      }
      return 0;
    })
    return data
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ListProduct() {
  const { product, auth  } = useSelector((state) => state);
  const { products, loading } = product;
  const { userInfo, branch } = auth;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const asdasd = useSelector((state) => state);

  const { masterCategories, categories } = useSelector((state) => state.category);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name.th');
  const [filterName, setFilterName] = useState('');
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [limit, setLimit] = useState(25);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmValue, setConfirmValue] = useState();
  const [openReorder, setOpenReorder] = useState(false);
  const emptyRows = products.length === 0;
  const filteredProduct = applySortFilter(products, getComparator(order, orderBy), filterName, filterCategory);
  const isNotFound = !filteredProduct.length && !!filterName && !!filterCategory;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (branch) {
      setIsLoading(true);
      dispatch(listMasterCategory())
      dispatch(listProduct()).then(() => setIsLoading(false)).catch(() => setIsLoading(false));
    }
  }, [branch, dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = products.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, value) => {
    const selectedIndex = selected.indexOf(value);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  useEffect(() => {
    if (filterData.length !== 0) {
      setLimit(parseInt(filteredProduct.length, 10));
    }
  }, [filterData, filteredProduct])
  const handleRedirect = (route) => {
    if (masterCategories.length <= 0) {
      enqueueSnackbar('กรุณาสร้างประเภทเมนูก่อนสร้างเมนูสินค้า', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      })
      setTimeout(() => {
        navigate("/category/create")
      }, 3000);
    } else {
      navigate(route)
    }
  };

  const handleDelete = (value) => {
    setConfirmDelete(true);
    setConfirmValue([value]);
  };
  const handleConfirmDelete = () => {
    dispatch(deleteProduct({ products: confirmValue || selected }));
    setConfirmDelete(false);
    setSelected([]);
  };

  const handleConfirmDeleteAll = () => {
    setConfirmDelete(true);
    setConfirmValue();
  };

  const SubmitSearch = (data) => {
    if (data?.length === 0) {
      setPage(0);
      setFilterCategory([]);

    } else {
      setPage(0);
      setFilterCategory(filterData);
    }

  }
  const SubmitDialog = async (data) => {
    const orderEvent = data.map((v, i) => {
      const info = {
        "ref": v._id,
        "index": i
      }
      return info
    })
    setIsLoading(true)
    await dispatch(displayOrderProduct({ orderby: orderEvent }))
    dispatch(listProduct()).then(() => setIsLoading(false)).catch(() => setIsLoading(false));
  }

  const handleFilterByCategory = (event, data) => {
    setPage(0);
    setFilterData(data.map((cat, i) => cat));
    if (data.length === 0) {
      SubmitSearch()
      setLimit(parseInt(25, 10));
    }
    // filteredProduct = applySortFilter(filteredProduct.filter((product) => product.category._id === event.target.value ), getComparator(order, orderBy), filterName);
    // console.log(filteredProduct)
    // filteredProduct.filter((product) => product.category._id === event.target.value )

  };


  return (
    <>
      <Helmet>
        <title> EzOrder Backoffice | รายการอาหาร </title>
      </Helmet>

      <Container>
        <Stack className={'group_header_filter'} direction="row" alignItems="center" justifyContent="space-between" mb={5} >
          <Typography variant="h4" gutterBottom>
            รายการอาหาร
          </Typography>
          <div style={{ flex: 1 }}> </div>
          {products.length > 0 && <Button
            variant="contained"
            startIcon={<Iconify icon="teenyicons:drag-outline" />}
            onClick={() => setOpenReorder(true)}
          >
            จัดเรียงรายการ
          </Button>}
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => handleRedirect('/product/create')}
            style={{ marginLeft: '8px', marginRight: '8px' }}
          >
            เพิ่มเมนูใหม่
          </Button>

        </Stack>

        {isLoading ?
          <Stack direction="row" alignItems="center" justifyContent="center" style={{ height: 'calc(100vh - 50vh)' }}>
            <CircularProgress size={100} />
          </Stack>
          :
          <Card>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => handleConfirmDeleteAll()}
              onFilterCategory={(e, data) => handleFilterByCategory(e, data)}
              Categories={masterCategories}
              filterCategory={filterData}
              SubmitSearch={e => SubmitSearch(e)}
            />
            <Scrollbar>
              <Reorderdata title={'จัดเรียงรายการอาหาร'} itemsData={products} open={openReorder} onClose={(data) => setOpenReorder(data)} handleSubmit={(data) => SubmitDialog(data)} />

              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={products.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />

                  <TableBody >
                    {filteredProduct.slice(page * limit, page * limit + limit).map((row, index) => {
                      const {
                        _id,
                        name,
                        price,
                        discount,
                        category,
                        is_recommend,
                        image_url,
                        is_out_stock,
                        is_enable,
                      } = row;
                      let salePrice = price;
                      if (discount) {
                        salePrice = parseFloat(price) - parseFloat(discount);
                      }
                      const selectedUser = selected.indexOf(_id) !== -1;
                      const editLink = `/product/${_id}`;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                          </TableCell>

                          <TableCell scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                alt={name}
                                src={image_url}
                                variant="square"
                                sx={{ width: 56, height: 56 }}
                              >
                                <Iconify icon="ic:outline-broken-image" />
                              </Avatar>

                              <Typography variant="subtitle2" >
                                <Link underline="hover" to={editLink}>
                                  {name.th}
                                </Link>
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell scope="row" padding="none">
                            <Typography variant="subtitle2">
                              <Link underline="hover" to={editLink}>
                                {name?.en}
                              </Link>
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            {`${is_recommend ? '⭐ เมนูแนะนำ,' : ''}`} {category?.name?.th}
                          </TableCell>

                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap sx={{ color: 'red' }}>
                              {salePrice}
                            </Typography>
                            {discount && (
                              <Typography
                                component="span"
                                variant="subtitle2"
                                sx={{
                                  color: 'text.disabled',
                                  textDecoration: 'line-through',
                                }}
                              >
                                {price}
                              </Typography>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              {is_out_stock && <Label color={'error'}>หมดสต๊อก</Label>}
                              <Label color={(!is_enable && 'error') || 'success'}>
                                {(!is_enable && 'ปิดการแสดง') || 'แสดงปกติ'}
                              </Label>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton color="error" onClick={() => handleDelete(_id)} component="label">
                              <Iconify icon={'eva:trash-2-outline'} sx={{ color: 'error.main' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>{/* <TableCell colSpan={6} /> */}</TableRow>
                    )}
                  </TableBody>

                  {isNotFound ||
                    (emptyRows && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {' '}
                              <img
                                src="/assets/images/empty_handle/product_notfound.png"
                                alt="product not found"
                                width={'17%'}
                                style={{ filter: 'grayscale(100%)', opacity: 0.6 }}
                              />
                              <Typography variant="h6" paragraph sx={{ pt: 6, mb: 1 }}>
                                ไม่พบรายการอาหาร
                              </Typography>
                              <Typography variant="body1" sx={{ mt: 0, opacity: 0.7 }}>
                                ยังไม่มีรายการอาหารที่เพิ่มเข้ามา
                              </Typography>
                              {isNotFound && (
                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                              )}
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  {/* {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100, { value: filteredProduct.length, label: "All" }]}
              component="div"
              count={filteredProduct.length}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        }
      </Container>

      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(!confirmDelete)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'แจ้งเตือนระบบ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">คุณต้องการที่จะลบเมนูสินค้านี้หรือไม่?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(!confirmDelete)}>ยกเลิก</Button>
          <Button onClick={() => handleConfirmDelete()} autoFocus>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
