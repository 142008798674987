/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import '../../services/qrcodesetting.css';
import {
  Grid,
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------

import { createManyTable, listTable } from '../../redux/actions/tableActions';

// ----------------------------------------------------------------------
const validationSchema = yup.object({
  numberOfTables: yup
    .number()
    .min(1, 'โปรดระบุจำนวนโต๊ะ (1-50 โต๊ะ)')
    .max(50, 'จำนวนโต๊ะไม่สามารถรองรับ')
    .required('โปรดระบุจำนวนโต๊ะ (1-50 โต๊ะ)'),
  runningNumStart: yup
    .number()
    .min(1, 'โปรดระบุเลขลำดับเริ่มต้นไม่ต่ำกว่า 0'),
  tables: yup.array().of(
    yup.object().shape({
      name: yup.object().shape({
        th: yup
          .string()
          .required('จำเป็นต้องระบุชื่อโต๊ะ (ภาษาไทย)')
          .min(2, 'ชื่อโต๊ะ (1-10 ตัวอักษร)')
          .max(10, 'ชื่อโต๊ะเกินจำนวนที่รองรับ'),
        en: yup.string().max(10, 'ชื่อโต๊ะเกินจำนวนที่รองรับ'),
      }),
    })
  ),
});


export default function ManyTable({ openModel, handleClose, totalTables }) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [formStep, setFormStep] = useState(0);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      numberOfTables: 0,
      tableTh: '',
      tableEn: '',
      runningNumStart: 1,
      tables: Array.from({ length: 0 }, () => ({
        name: { th: '', en: '' },
      })),
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (formStep === 0) {
          formik.validateForm();
          formik.setFieldTouched('numberOfTables', true, false);
          // handleMockTable()
          if (!formik.errors.numberOfTables) {
            setFormStep(formStep + 1);
            setPendingNumberOfTables(formik.values.numberOfTables);
            setSubmitting(false);
          } else {
            setSubmitting(false);
          }
        } else if (formStep === 1) {
          const tables = values.tables.map((table) => table);
          await dispatch(createManyTable({ tables }))
          dispatch(listTable());
          enqueueSnackbar('บันทึกข้อมูลเรียบร้อย!', {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          setSubmitting(false);
          handleClose();
        }
      } catch (error) {
        enqueueSnackbar('เกิดข้อผิดพลาด!', { variant: 'error' });
        setSubmitting(false);
      }
    },
  });
  const [pendingNumberOfTables, setPendingNumberOfTables] = useState(0);

  useEffect(() => {
    if (pendingNumberOfTables > 0 && formik.values.numberOfTables) {
      const newTables = []
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < formik.values.numberOfTables; index++) {
        newTables.push({ name: { th: `${formik.values?.tableTh || "โต๊ะ"} ${formik.values.runningNumStart + index}`, en: `${formik.values?.tableEn || "Table"} ${formik.values.runningNumStart + index}` } })
      }
      formik.setFieldValue('tables', newTables);
    }
  }, [formik.values.tableTh, formik.values.tableEn, formik.values.runningNumStart, pendingNumberOfTables, formik.values.numberOfTables]);

  // const handleMockTable = () => {
  //   try {
  //     const newTables = Array.from({ length: Number(formik.values.numberOfTables) || 0 }, (e, i) => ({
  //       name: { th: `${ formik.values?.tableTh === "" ? "โต๊ะ" : formik.values?.tableTh } ${ formik.values.runningNumStart + i }`, en: `${ formik.values?.tableEn === "" ? "Table" : formik.values?.tableEn } ${ formik.values.runningNumStart + i }` },
  //     }));
  //     console.log(newTables)
  //     formik.setFieldValue('tables', newTables);
  //   } catch (error) {
  //     console.log(error)
  //   }

  // }

  // const handleSetTable = async () => {
  //   if (formik.values.numberOfTables > 50) {
  //     return;
  //   }

  //   await formik.validateForm();
  //   formik.setFieldTouched('numberOfTables', true, false);

  //   if (!formik.errors.numberOfTables) {
  //     setFormStep(formStep + 1);
  //   }

  //   setPendingNumberOfTables(formik.values.numberOfTables);
  // };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={openModel}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{'สร้างหลายโต๊ะ'}</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={2} >
              <Grid item xs={12} sm={6}>
                <Box >
                  {' '}
                  <TextField
                    fullWidth
                    id="numberOfTables"
                    name="numberOfTables"
                    label="จำนวนที่ต้องการสร้าง *"
                    type="number"
                    variant="outlined"
                    value={formik.values.numberOfTables}
                    onChange={(event) => {
                      const { value } = event.target;
                      if (value.length <= 2) {
                        formik.handleChange(event);
                      }
                    }}
                    error={formik.touched.numberOfTables && Boolean(formik.errors.numberOfTables)}
                    helperText={formik.touched.numberOfTables && formik.errors.numberOfTables}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box >
                  {' '}
                  <TextField
                    fullWidth
                    id="runningNumStart"
                    name="runningNumStart"
                    label="ลำดับเลขเริ่มต้น"
                    type="number"
                    variant="outlined"
                    value={formik.values.runningNumStart}
                    onChange={(event) => formik.handleChange(event)}
                    error={formik.touched.runningNumStart && Boolean(formik.errors.runningNumStart)}
                    helperText={formik.touched.runningNumStart && formik.errors.runningNumStart}
                  />
                </Box>
              </Grid>


              <Grid item xs={12} sm={6}>
                <Box >
                  {' '}
                  <TextField
                    fullWidth
                    id="tableTh"
                    name="tableTh"
                    label="ชื่อโต๊ะภาษาไทย"
                    placeholder="โต๊ะ,A,Table"
                    variant="outlined"
                    value={formik.values.tableTh}
                    onChange={(event) => formik.handleChange(event)}
                    error={formik.touched.tableTh && Boolean(formik.errors.tableTh)}
                    helperText={formik.touched.tableTh && formik.errors.tableTh}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  {' '}
                  <TextField
                    fullWidth
                    id="tableEn"
                    name="tableEn"
                    label="ชื่อโต๊ะภาษาอังกฤษ"
                    placeholder="โต๊ะ,A,Table"
                    variant="outlined"
                    value={formik.values.tableEn}
                    onChange={(event) => formik.handleChange(event)}
                    error={formik.touched.tableEn && Boolean(formik.errors.tableEn)}
                    helperText={formik.touched.tableEn && formik.errors.tableEn}
                  />
                </Box>
              </Grid>

              {formik.values.tables.map((table, index) => (
                <Grid item xs={12} key={index}>
                  <Box
                    sx={{
                      pt: 1,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        {' '}
                        <Box>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              id={`tables.${index}.name.th`}
                              name={`tables.${index}.name.th`}
                              label={`ชื่อโต๊ะ ${index + 1} * `}
                              type="text"
                              characterLimit={20}
                              variant="outlined"
                              value={formik.values.tables[index].name.th}
                              onChange={formik.handleChange}
                              inputProps={{ maxLength: 10 }}
                              error={
                                formik.touched.tables?.[index]?.name?.th &&
                                Boolean(formik.errors.tables?.[index]?.name?.th)
                              }
                              helperText={
                                formik.touched.tables?.[index]?.name?.th && formik.errors.tables?.[index]?.name?.th
                              }
                            />{' '}
                            <div className="helplimit">{formik.values.tables[index].name.th.length}/10</div>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {' '}
                        <Box>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              id={`tables.${index}.name.en`}
                              name={`tables.${index}.name.en`}
                              label={`ชื่อโต๊ะ ${index + 1} (อังกฤษ)`}
                              type="text"
                              variant="outlined"
                              value={formik.values.tables[index].name.en}
                              onChange={formik.handleChange}
                              inputProps={{ maxLength: 10 }}
                              error={
                                formik.touched.tables?.[index]?.name?.en &&
                                Boolean(formik.errors.tables?.[index]?.name?.en)
                              }
                              helperText={
                                formik.touched.tables?.[index]?.name?.en && formik.errors.tables?.[index]?.name?.en
                              }
                            />{' '}
                            <div className="helplimit">{formik.values.tables[index].name.en.length}/10</div>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()}>ยกเลิก</Button>
            <LoadingButton
              loading={formik.isSubmitting}
              type="submit"
              variant="contained"
            // onClick={(event) => {
            //   if (formStep === 0) {
            //     event.preventDefault();
            //     handleSetTable();
            //   }
            // }}
            >
              {formStep === 0 ? 'ถัดไป' : 'บันทึก'}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
