import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';

const MapWithForm = ({ formik, zoom, isLoaded }) => {
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0, zoom: 10 });
  const [draggable, setDdaggable] = useState(true)

  useEffect(() => {
    if (window.google?.maps?.Geocoder) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat: formik.values.location_address.lat, lng: formik.values.location_address.lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          const addressComponents = results[0].address_components;
          const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${formik.values.location_address.lat},${formik.values.location_address.lng}&zoom=${zoom || 19}`
          formik.setFieldValue(`location_address`, {
            google_mal_url: googleMapsURL,
            name: results[0].formatted_address,
            lat: formik.values.location_address.lat,
            lng: formik.values.location_address.lng,
            province: getAddressComponent(addressComponents, 'administrative_area_level_1'),
            district: getAddressComponent(addressComponents, 'administrative_area_level_2'),
            subdistrict: getAddressComponent(addressComponents, 'sublocality_level_1') || getAddressComponent(addressComponents, 'locality'),
            radius: formik.values.location_address?.radius, // Static radius value of 100 meters
          });

        } else {
          // console.log("Geocoding failed: " + status);
        }
      });
      setMarkerPosition({ lat: formik.values?.location_address?.lat || 0, lng: formik.values?.location_address?.lng || 0, zoom: zoom || 17 });
    }
  }, [formik.values.location_address?.lat, formik.values.location_address?.lng, formik.values.location_address?.radius]);

  function getAddressComponent(addressComponents, type) {
    return addressComponents.find(component => component.types.includes(type))?.long_name || '';
  }
  const onMarkerDragEnd = (e) => {
    const newPos = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setMarkerPosition(newPos);

    // Initialize the Geocoder
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: newPos }, (results, status) => {
      if (status === "OK" && results[0]) {
        const addressComponents = results[0].address_components;
        const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${newPos.lat},${newPos.lng}&zoom=${zoom}`
        formik.setFieldValue(`location_address`, {
          google_mal_url: googleMapsURL,
          name: results[0].formatted_address,
          lat: newPos.lat,
          lng: newPos.lng,
          province: getAddressComponent(addressComponents, 'administrative_area_level_1'),
          district: getAddressComponent(addressComponents, 'administrative_area_level_2'),
          subdistrict: getAddressComponent(addressComponents, 'sublocality_level_1') || getAddressComponent(addressComponents, 'locality'),
          radius: formik.values.location_address?.radius // Static radius value of 100 meters
        });

      } else {
        // console.log("Geocoding failed: " + status);
      }
    });
  };
  
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '400px' }}
        center={markerPosition}
        zoom={zoom || 0}
      >
        <Marker
          position={markerPosition}
          draggable={draggable}
          onDragEnd={onMarkerDragEnd}
        />
        <Circle
          // Center the circle on the marker's position
          center={markerPosition}
          // Radius in meters (100 meters as per your requirement)
          radius={formik.values.location_address?.radius}
          // Optional: Style the circle
          options={{
            fillColor: 'green',
            fillOpacity: 0.2,
            strokeColor: 'green',
            strokeOpacity: 0.5,
            strokeWeight: 2,
          }}
        />
      </GoogleMap>
    </div>
  );
};

export default MapWithForm;
